import type { IdentityModel, PublicProfileModel, UserModel, UserProfileModel } from '@vatom/models'
import { IdentityModelSchema } from '@vatom/models'
import { getConfig } from '@vatom/sdk/react'
import { z } from 'zod'

import { vatomAxiosInstance } from '../vatomAxiosInstance'

export async function fetchIdentities() {
  try {
    const config = getConfig()
    const { data } = await vatomAxiosInstance.get<IdentityModel[]>(
      `${config.authentication.discoveryUrl}/me/identities`
    )

    return data
    // TODO: review, do this needs to use safeParse?
    // return z.array(IdentityModelSchema).parse(response?.data)
  } catch (error) {
    console.error('UserService.fetchIdentities', error)
    throw error
  }
}

export async function fetchMe() {
  try {
    const config = getConfig()
    const { data } = await vatomAxiosInstance.get<UserModel>(`${config.api.oidc}/me`)

    //TODO: add trackUser
    // if (self.user && self.user?.sub) {
    //     const userId = self.user?.sub
    //     if (self.user?.email) {
    //       Sentry.configureScope(scope => {
    //         scope.setTag('platform', Platform.OS)
    //         scope.setTag('stage', STAGE)
    //         scope.setUser({
    //           id: userId,
    //           email: self.user?.email
    //         })
    //       })
    //     }

    //     Heap.identify(userId)

    return data
  } catch (error) {
    console.error('UserService.fetchMe', error)
    throw error
  }
}

export async function fetchUserPoints({
  userId,
  campaignId
}: {
  userId: string
  campaignId: string
}) {
  try {
    const config = getConfig()
    const { data } = await vatomAxiosInstance.get(
      `${config.api.points}/u/${userId}/campaign/${campaignId}/points`
    )
    return data
  } catch (error) {
    console.error('UserService.fetchUserPoints', error)
    throw error
  }
}

export async function fetchUserBusinessInfo({
  businessId
}: {
  businessId: string
}): Promise<unknown> {
  try {
    const config = getConfig()
    const { data } = await vatomAxiosInstance.get(
      `${config.api.oidc}/api/me/info?business-id=${businessId}`
    )
    return data
  } catch (error) {
    console.error('UserService.fetchUserBusinessInfo', error)
    throw error
  }
}

export async function fetchUserCampaignInfo({
  businessId,
  campaignId
}: {
  businessId: string
  campaignId: string
}): Promise<unknown> {
  try {
    const config = getConfig()
    const response = await vatomAxiosInstance.get(
      `${config.api.oidc}/api/me/info?business-id=${businessId}&campaign-id=${campaignId}`
    )
    return response.data
  } catch (error) {
    console.error('UserService.fetchUserCampaignInfo', error)
    throw error
  }
}

export async function fetchPublicProfile(userId: string) {
  try {
    if (!userId) {
      throw new Error(`PublicProfile missing userId`)
    }
    const config = getConfig()
    const { data } = await vatomAxiosInstance.get<PublicProfileModel>(
      `${config.api.users}/${userId}`
    )
    if (!data.id) {
      throw new Error('PublicProfile empty response')
    }
    return data
  } catch (error) {
    console.error('UserService.fetchPublicProfile', error)
    throw error
  }
}

export async function fetchTermsBusiness({ businessId }: { businessId: string }) {
  try {
    const config = getConfig()
    const { data } = await vatomAxiosInstance.get(
      `${config.api.oidc}/api/me/terms?business-id=${businessId}`
    )
    return data
  } catch (error) {
    console.error('UserService.fetchBusinessTerms', error)
    throw error
  }
}

export async function fetchFormBusiness({
  businessId,
  campaignId
}: {
  businessId?: string
  campaignId?: string
}) {
  try {
    const config = getConfig()
    const formQ = new URLSearchParams({
      flow: 'popup',
      'business-id': businessId ?? '',
      'campaign-id': campaignId ?? ''
    })

    const { data } = await vatomAxiosInstance.get(
      `${config.api.oidc}/api/me/info/form?${formQ.toString()}`
    )

    return data || []
  } catch (error) {
    console.error('UserService.fetchBusinessForm', error)
    throw error
  }
}

type ContactType = 'phoneNumber' | 'user' | 'email'

type RelationshipContact = {
  type: ContactType
  value: string
  key?: string
}

type DeviceContactResponse = {
  id: string
  error?: string
}

export async function fetchContacts(contacts: RelationshipContact[]) {
  try {
    const config = getConfig()

    const { data } = await vatomAxiosInstance.post<DeviceContactResponse[]>(
      `${config.api.oidc}/contacts`,
      JSON.stringify(contacts)
    )

    return data || []
  } catch (error) {
    console.error('UserService.fetchContacts', error)
    throw error
  }
}

export async function fetchFollowing() {
  try {
    const config = getConfig()
    const { data } = await vatomAxiosInstance.get<RelationshipContact[]>(
      `${config.api.oidc}/me/rels/following`
    )

    return data || []
  } catch (error) {
    console.error('UserService.fetchContacts', error)
    throw error
  }
}

type FollowersResponse = {
  ey: string
  type: ContactType
  value: string
  user: {
    name: string
    picture?: string
    preferred_username?: string
  }
}

export async function fetchUserFollowers(userId: string) {
  try {
    const config = getConfig()
    const { data } = await vatomAxiosInstance.get<FollowersResponse[]>(
      `${config.api.oidc}/users/${userId}/rels/followers`
    )
    return data
  } catch (error) {
    console.error('UserService.fetchUserFollowers', error)
    throw error
  }
}

type FollowingResponse = {
  key: string
  type: ContactType
  value: string
  user: {
    name: string
    picture?: string
    preferred_username?: string
  }
}

export async function fetchUserFollowing(userId: string) {
  try {
    const config = getConfig()
    const { data } = await vatomAxiosInstance.get<FollowingResponse[]>(
      `${config.api.oidc}/users/${userId}/rels/following`
    )
    return data
  } catch (error) {
    console.error('UserService.fetchUserFollowing', error)
    throw error
  }
}

// UPDATES

type UpdateCampaignInfo = {
  payload: Record<string, unknown>
  businessId: string
  campaignId: string
}
export async function updateCampaignInfo(update: UpdateCampaignInfo): Promise<void> {
  try {
    const config = getConfig()
    await vatomAxiosInstance.patch(`${config.api.oidc}/api/me/info`, update)
  } catch (error) {
    console.error('UserService.updateCampaignInfo', error)
    throw error
  }
}

export async function updateUser(update: Partial<UserProfileModel>): Promise<void> {
  try {
    const config = getConfig()
    await vatomAxiosInstance.patch(`${config.api.oidc}/me`, update)
  } catch (error) {
    console.error('UserService.updateUser', error)
    throw error
  }
}
