/**
 * Welcome to the main entry point of the app. In this file, we'll
 * be kicking off our app.
 *
 * Most of this file is boilerplate and you shouldn't need to modify
 * it very often. But take some time to look through and understand
 * what is going on here.
 *
 * The app navigation resides in ./app/navigators, so head over there
 * if you're interested in adding screens and navigators.
 */
import './utils/ignore-warnings'
import './redirect'

import React, { useEffect } from 'react'
import type { AppStateStatus } from 'react-native'
import { AppState, Linking, Platform } from 'react-native'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { enableFreeze, enableScreens } from 'react-native-screens'
import { focusManager } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { useSessionStore } from '@vatom/sdk/auth'
import { Sentry } from '@vatom/sdk/core'
import {
  prefetchConfig,
  SDKPersistOptions,
  SDKQueryClient,
  useInitialQsStore
} from '@vatom/sdk/react'
import { LoaderView, theme } from '@vatom/wombo' // expo
import { LinearGradient } from 'expo-linear-gradient'
import * as SplashScreen from 'expo-splash-screen'
import { Center, NativeBaseProvider } from 'native-base'

import { OidcSession } from './components/OidcSession'
import { AppStack } from './navigators/AppNavigator/AppStack'
import { ErrorBoundary } from './screens/ErrorBoundary'
import { initializeNotificationService } from './services/notificationService'
import { initFonts } from './theme/fonts'
import logger from './logger'
import { AppNavigator } from './navigators'
// This puts screens in a native ViewController or Activity. If you want fully native
// stack navigation, use `createNativeStackNavigator` in place of `createStackNavigator`:
// https://github.com/kmagiera/react-native-screens#using-native-stack-navigator
export const NAVIGATION_PERSISTENCE_KEY = 'NAVIGATION_STATE'

enableScreens(true)
enableFreeze(true)

const config = {
  dependencies: {
    'linear-gradient': LinearGradient
  }
}

// Keep the splash screen visible while we fetch resources
SplashScreen.preventAutoHideAsync()

export interface AppProps {}

const prefetchConfigPromise = prefetchConfig()

function onAppStateChange(status: AppStateStatus) {
  if (Platform.OS !== 'web') {
    focusManager.setFocused(status === 'active')
  }
}

declare global {
  interface Window {
    load8thwall: () => void
  }
}

const setInitialUrl = (initialUrl?: string | null) => {
  if (!initialUrl) {
    return
  }
  const url = new URL(initialUrl)
  const ignoreAuthPath = ['/callback', '/logout-callback', '/connect']
  if (!ignoreAuthPath.includes(url.pathname)) {
    useInitialQsStore.setState({ initialUrl: url.href })
  }
}

/**
 * This is the root component of our app.
 */
export const App = () => {
  const hydrated = useSessionStore(s => s.hydrated)

  useEffect(() => {
    const subscription = AppState.addEventListener('change', onAppStateChange)
    return () => subscription.remove()
  }, [])

  // Kick off initial async loading actions, like loading fonts and RootStore
  useEffect(() => {
    const bootstrapApp = async () => {
      try {
        Sentry.init()
        try {
          await initFonts()
          await initializeNotificationService()
        } catch (error) {
          logger.error('[App.render] useEffect initFonts', error)
        }

        const initialUrl = await Linking.getInitialURL()
        setInitialUrl(initialUrl)

        await prefetchConfigPromise

        setTimeout(async () => {
          await SplashScreen.hideAsync()
        }, 250)
        // DEBUG ONLY: Test crashes
        // Sentry.instance.nativeCrash()
      } catch (error) {
        logger.error('[App.base] bootstrapApp:', error)
      }
    }
    bootstrapApp()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Before we show the app, we have to wait for our state to be ready.
  // In the meantime, don't render anything. This will be the background
  // color set in native by rootView's background color.
  // In iOS: application:didFinishLaunchingWithOptions:
  // In Android: https://stackoverflow.com/a/45838109/204044
  // You can replace with your own loading component if you wish.
  if (!hydrated) {
    return (
      <NativeBaseProvider config={config} theme={theme}>
        <Center>
          <LoaderView />
        </Center>
      </NativeBaseProvider>
    )
  }

  // otherwise, we're ready to render the app
  return (
    <PersistQueryClientProvider client={SDKQueryClient} persistOptions={SDKPersistOptions}>
      <OidcSession />
      {Platform.OS === 'web' && __DEV__ ? <ReactQueryDevtools initialIsOpen={false} /> : null}
      <SafeAreaProvider>
        <ErrorBoundary catchErrors={'never'}>
          <NativeBaseProvider config={config} theme={theme}>
            <AppNavigator>
              <AppStack />
            </AppNavigator>
          </NativeBaseProvider>
        </ErrorBoundary>
      </SafeAreaProvider>
    </PersistQueryClientProvider>
  )
}
