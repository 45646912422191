import { useCallback, useEffect, useMemo, useRef } from 'react'
import { Linking, Platform } from 'react-native'
import { useAppState } from '@react-native-community/hooks'
import type { UseMutationOptions, UseQueryOptions } from '@tanstack/react-query'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  type Session,
  destroySessions,
  sessionSource,
  setSession,
  useSessionStore
} from '@vatom/sdk/auth'
import { Sentry } from '@vatom/sdk/core'
import {
  CookieManager,
  getConfig,
  SDKQueryClient,
  SDKQueryClientPersister,
  useBusinessSearch,
  useConfigState,
  useInitialQsStore,
  usersKeys
} from '@vatom/sdk/react'
import { setAuthBlockVAxiosInstance, setAuthorizationAxiosInstance } from '@vatom/sdk/services'
import { useWalletIsEmbedded } from '@vatom/wallet-sdk'
import axios from 'axios'
import * as AuthSession from 'expo-auth-session'
import jwtDecode, { type JwtPayload } from 'jwt-decode'
import { pathToRegexp } from 'path-to-regexp'

import { getExpireAt } from './helpers'

const isWeb = Platform.OS === 'web'

const WEB_LOGIN_LOCAL_STORAGE_KEY = 'LOCAL_STORAGE-WEB_LOGIN' // ex LOGIN_CODE_VERIFIER

export const webCodeVerifierLocalStorage = {
  get: () => {
    const codeVerifier = localStorage.getItem(WEB_LOGIN_LOCAL_STORAGE_KEY)
    return codeVerifier ?? null
  },
  set: (codeVerifier: string) => {
    localStorage.setItem(WEB_LOGIN_LOCAL_STORAGE_KEY, codeVerifier)
  },
  remove: () => {
    localStorage.removeItem(WEB_LOGIN_LOCAL_STORAGE_KEY)
  }
}

type PromptAsync = ReturnType<typeof AuthSession.useAuthRequest>['2']

function useAuthDiscovery({ config }: { config: ReturnType<typeof useConfigState>['data'] }) {
  const { clientId, scopes, discoveryUrl, redirectUri: oRedirectUri } = config.authentication

  const discovery = AuthSession.useAutoDiscovery(discoveryUrl)
  const redirectUri = isWeb ? `${window.location.origin}/callback` : oRedirectUri

  const { initialUrl: _initialUrl } = useInitialQsStore.getState()

  const initialUrl = useMemo(() => (_initialUrl ? new URL(_initialUrl) : null), [_initialUrl])

  const { businessIdOrName, urlParams } = useInitialRouteResolver({ initialUrl, config })

  const businessSearch = useBusinessSearch({
    business: urlParams.context && !businessIdOrName ? 'ym3t3mPi0g' : businessIdOrName,
    context: urlParams.context,
    join: urlParams.context && !initialUrl?.pathname.includes('acquire') ? true : false
  })

  const business = useMemo(() => {
    return businessSearch?.data && businessSearch?.data?.length > 0
      ? businessSearch?.data[0]
      : undefined
  }, [businessSearch])

  const businessId = useMemo(() => business?.id, [business])

  const extraParams = useMemo(() => {
    return businessId
      ? {
          ...(urlParams.context
            ? { join: 'true', context: urlParams.context, 'business-id': businessId }
            : {
                'business-id': businessId
              })
        }
      : undefined
  }, [businessId, urlParams.context])

  const [request, result, promptAsync] = AuthSession.useAuthRequest(
    {
      clientId,
      redirectUri,
      // id_token will return a JWT token
      responseType: AuthSession.ResponseType.Code,
      prompt: AuthSession.Prompt.Consent,
      extraParams,
      scopes
    },
    discovery
  )
  return {
    discovery,
    request,
    result,
    promptAsync,
    authConfig: config.authentication,
    initialUrl
  }
}

// export async function authRefreshAsync({
//   discovery,
//   authConfig
// }: {
//   discovery: AuthSession.DiscoveryDocument
//   authConfig: ReturnType<typeof useConfigState>['data']['authentication']
// }) {
//   try {
//     const refreshResponse = await AuthSession.refreshAsync(
//       {
//         clientId: authConfig.clientId
//       },
//       discovery
//     )
//     // Update session
//     const expiresAt = getExpireAt(refreshResponse.expiresIn)
//     const decodedToken = jwtDecode<JwtPayload>(refreshResponse.accessToken)

//     setSession('vatom', {
//       source: 'vatom',
//       type: 'jwt',
//       value: {
//         accessToken: refreshResponse.accessToken,
//         idToken: refreshResponse.idToken,
//         expiresAt: decodedToken?.exp ?? expiresAt,
//         refreshToken: refreshResponse.refreshToken ?? ''
//       }
//     })

//     return refreshResponse.accessToken
//   } catch (error) {
//     // Logout
//     logoutClearAll()

//     throw new Error(`Unable to refresh token`)
//   }
// }

type RefreshTokenResponse = {
  access_token: string
  id_token: string
  refresh_token: string
  expires_in: number
  scope: string
}

export async function authRefreshAsync({ refreshToken }: { refreshToken: string }) {
  try {
    const config = getConfig()
    const clientId = config.authentication.clientId
    const baseURL = config.api.oidc
    const { data } = await axios.request<RefreshTokenResponse>({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      method: 'post',
      url: '/token',
      baseURL,
      data: new URLSearchParams({
        grant_type: 'refresh_token',
        client_id: clientId,
        refresh_token: refreshToken
      })
    })

    // Update session
    const expiresAt = getExpireAt(data.expires_in)
    const decodedToken = jwtDecode<JwtPayload>(data.access_token)

    setSession('vatom', {
      source: 'vatom',
      type: 'jwt',
      value: {
        accessToken: data.access_token,
        idToken: data.id_token,
        expiresAt: decodedToken?.exp ?? expiresAt,
        refreshToken: data.refresh_token ?? '',
        userId: decodedToken.sub
      }
    })

    return data.access_token
  } catch (error) {
    // Logout
    logoutClearAll()

    throw new Error(`Unable to refresh token`)
  }
}

type CreateSessionAuthToken = {
  expiresIn?: number
  idToken?: string
  accessToken: string
  refreshToken: string
}

type CreateSessionDeps = {
  authConfig: ReturnType<typeof useConfigState>['data']['authentication']
}
async function createSessions(
  authToken: CreateSessionAuthToken,
  { authConfig }: CreateSessionDeps
) {
  const expiresAt = getExpireAt(authToken.expiresIn)
  const decodedToken = jwtDecode<JwtPayload>(authToken.accessToken)

  setSession(sessionSource.vatom, {
    source: 'vatom',
    type: 'jwt',
    value: {
      accessToken: authToken.accessToken,
      idToken: authToken.idToken,
      expiresAt: decodedToken?.exp ?? expiresAt,
      refreshToken: authToken.refreshToken ?? '',
      userId: decodedToken.sub
    }
  })

  setAuthorizationAxiosInstance({
    accessToken: authToken.accessToken,
    refreshToken: authToken.refreshToken ?? ''
  })

  // NOTE: ex performTokenExchange
  await authBlockV(authToken.accessToken, { authConfig })

  SDKQueryClient.prefetchQuery(usersKeys.me._ctx.identities)
  SDKQueryClient.prefetchQuery(usersKeys.me)

  return authToken.accessToken
}

async function authorizeCode(
  code: AuthCode,
  {
    discovery,
    request,
    authConfig
  }: {
    discovery: AuthSession.DiscoveryDocument | null
    request: AuthSession.AuthRequest | null
    authConfig: ReturnType<typeof useConfigState>['data']['authentication']
  }
) {
  try {
    if (!code) {
      throw new Error(`authorizeCode: missing code`)
    }
    if (!discovery) {
      throw new Error(`authorizeCode: missing discovery`)
    }
    if (!request) {
      throw new Error(`authorizeCode: missing request`)
    }
    if (!authConfig) {
      throw new Error(`authorizeCode: missing authConfig`)
    }

    // NOTE: for web retrieve code verifier from storage
    const codeVerifier = isWeb ? webCodeVerifierLocalStorage.get() : request.codeVerifier

    if (!codeVerifier) {
      throw new Error(`authorizeCode: missing codeVerifier`)
    }

    const redirectUri = isWeb ? `${window.location.origin}/callback` : authConfig.redirectUri

    const authorizedState = await AuthSession.exchangeCodeAsync(
      {
        clientId: authConfig.clientId,
        code,
        redirectUri,
        extraParams: {
          code_verifier: codeVerifier
        }
      },
      discovery
    )
    // Remove code verifier
    isWeb && webCodeVerifierLocalStorage.remove()

    // TODO:
    // if (user?.deferred_deeplink) {}

    return await createSessions(
      {
        accessToken: authorizedState.accessToken,
        refreshToken: authorizedState.refreshToken ?? '',
        expiresIn: authorizedState.expiresIn,
        idToken: authorizedState.idToken
      },
      { authConfig }
    )
  } catch (error) {
    console.error('authorizeCode:', error)
    throw error
  }
}

async function authBlockV(
  accessToken: string,
  {
    authConfig
  }: {
    authConfig: ReturnType<typeof useConfigState>['data']['authentication']
  }
) {
  try {
    const { data } = await axios.post(
      '/token',
      new URLSearchParams({
        grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
        client_id: authConfig.clientId,
        resource: 'https://api.blockv.io',
        subject_token_type: 'urn:ietf:params:oauth:token-type:access_token',
        subject_token: accessToken!
      }),
      { baseURL: getConfig().api.oidc }
    )

    const dCode: any = jwtDecode(data.refresh_token)
    console.log('LOG: authBlockV > dCode:', dCode)
    // const userId = dCode.user_id ?? dCode.sub
    //
    const expiresAt = getExpireAt(data.expires_in)

    setSession('blockV', {
      source: 'blockV',
      type: 'jwt',
      value: {
        accessToken: data.access_token,
        expiresAt,
        refreshToken: data.refresh_token ?? '',
        userId: dCode.sub
      }
    })

    setAuthBlockVAxiosInstance({
      accessToken: data.access_token,
      refreshToken: data.refresh_token
    })
  } catch (error) {
    console.error('authBlockV:', error)
  }
}

export async function loginWithOtp(
  { identityType, identityValue, otp }: OTPParams,
  { authConfig }: { authConfig: ReturnType<typeof useConfigState>['data']['authentication'] }
) {
  //
  const { data } = await axios.post(
    '/token',
    new URLSearchParams({
      grant_type: 'urn:vatominc:params:oauth:grant-type:otp',
      client_id: authConfig.clientId,
      scope: 'profile offline_access',
      identity_type: identityType,
      identity_value: decodeURIComponent(identityValue),
      otp
    }),
    { baseURL: authConfig.discoveryUrl }
  )
  return data as {
    access_token: string
    refresh_token: string
    expires_in?: number
    id_token?: string
  }
}

type OTPParams = {
  identityType: string
  identityValue: string
  otp: string
}
async function signInOTP(
  otpParams: OTPParams,
  { authConfig }: { authConfig: ReturnType<typeof useConfigState>['data']['authentication'] }
) {
  try {
    const data = await loginWithOtp(otpParams, { authConfig })
    console.log('LOG: signInOTP > data:', data)

    if (!data.access_token) {
      return Promise.reject('missing access token in the response')
    }

    // clear search params
    useInitialQsStore.setState(state => {
      if (state.initialUrl) {
        const url = new URL(state.initialUrl)

        return { initialUrl: url.origin + url.pathname, qsForExperienceSDK: '' }
      }
      return { initialUrl: null, qsForExperienceSDK: '' }
    })

    return await createSessions(
      {
        accessToken: data.access_token,
        refreshToken: data.refresh_token ?? '',
        expiresIn: data.expires_in,
        idToken: data.id_token
      },
      { authConfig }
    )
  } catch (error) {
    console.error('auth.signInOTP:', error)
    throw error
  } finally {
    // clear search params
    useInitialQsStore.setState(state => {
      if (state.initialUrl) {
        const url = new URL(state.initialUrl)
        return { initialUrl: url.origin + url.pathname, qsForExperienceSDK: '' }
      }
      return { initialUrl: null, qsForExperienceSDK: '' }
    })
  }
}

async function signInWeb({
  discovery,
  request
}: {
  discovery: AuthSession.DiscoveryDocument | null
  request: AuthSession.AuthRequest | null
}) {
  //
  try {
    if (!discovery || !request?.codeVerifier) {
      throw new Error(`signInWeb: missing arg`)
    }

    const url = await request.makeAuthUrlAsync(discovery)
    // Save verifier code to use on /callback
    webCodeVerifierLocalStorage.set(request.codeVerifier)
    // Replace url
    window.location.replace(url)
  } catch (error) {
    console.error('auth.signInWeb:', error)
    throw error
  }
}

async function signInNative({
  discovery,
  request,
  promptAsync,
  authConfig
}: {
  discovery: AuthSession.DiscoveryDocument | null
  request: AuthSession.AuthRequest | null
  promptAsync: PromptAsync
  authConfig: ReturnType<typeof useConfigState>['data']['authentication']
}) {
  try {
    //
    const response = await promptAsync({
      createTask: false,
      showInRecents: true,
      windowFeatures: {
        createTask: false,
        preferEphemeralSession: true
      }
    })

    if (response.type === 'cancel') {
      throw new Error(`Sign in cancelled`)
    }

    if (discovery && response.type === 'success') {
      if (!('code' in response.params)) {
        throw new Error(`Code not found on sign in response`)
      }
      return await authorizeCode(AuthCode(response.params['code']), {
        discovery,
        authConfig,
        request
      })
    } else {
      AuthSession.dismiss()
      throw new Error(`Sign in failed`)
    }
  } catch (error) {
    console.error('auth.signInNative:', error)
    throw error
  }
}

// TODO:  ???? Review DeepLinkManager > /apps/wallet/src/app/components/DeepLinkManager.ts
async function _deepLinkResolver(link: URL) {
  try {
    console.log('LOG: > deepLinkResolver > link:', link, typeof link)
    // If deep link is /connect (sign-in) ignore
    // if (link.pathname?.indexOf(AppRoutes.connect) !== -1) {
    //   return
    // }
    const goTo = Platform.OS === 'ios' ? `${link.hostname}:/${link.pathname}` : link.href
    await Linking.openURL(goTo)
    console.log('LOG: > deepLinkResolver > goTo:', goTo)
    // TODO: clear stored link?
  } catch (error) {
    console.log('LOG: > deepLinkResolver > error:', error)
  }
}

async function signInWalletSDK({
  authConfig
}: {
  authConfig: ReturnType<typeof useConfigState>['data']['authentication']
}) {
  //
  try {
    // const data = await walletSDK.sendRequest('get-access-token')
    // console.log('LOG: signInWalletSDK > data:', data)
    // if (!data.access_token) {
    //   return Promise.reject('missing access token in the response')
    // }
    // await createSessions(
    //   {
    //     accessToken: data.access_token,
    //     refreshToken: data.refresh_token ?? '',
    //     expiresIn: data.expires_in,
    //     idToken: data.id_token
    //   },
    //   { authConfig }
    // )
    // await walletSDK.emit('login-success')
    // return data.access_token
  } catch (error) {
    // await walletSDK.emit('login-failure', error)

    console.error('auth.signInWalletSDK:', error)
    throw error
  }
}

type SignInMutationContext = {
  shouldSignIn: boolean
  otpParams: ReturnType<typeof useShouldSkipConnect>['otpParams']
}
type SignInMutationOptions<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = SignInMutationContext
> = Pick<
  UseMutationOptions<TData, TError, TVariables, TContext>,
  'onError' | 'onSuccess' | 'onSettled'
>

export function useSignIn(options: SignInMutationOptions = {}) {
  const _init = useRef(false)
  const { data: config } = useConfigState()

  const { discovery, request, promptAsync, authConfig } = useAuthDiscovery({ config })

  const { shouldSignIn, otpParams, isEmbedded } = useShouldSkipConnect()

  const mutationFn = useCallback(async () => {
    if (isEmbedded) {
      return await signInWalletSDK({ authConfig })
    }
    if (otpParams) {
      return await signInOTP(otpParams, { authConfig })
    }
    if (isWeb) {
      return await signInWeb({ discovery, request })
    }
    return await signInNative({ discovery, promptAsync, authConfig, request })
  }, [authConfig, discovery, isEmbedded, otpParams, promptAsync, request])

  const signInMutation = useMutation({
    mutationFn,
    onMutate: () => {
      return {
        shouldSignIn,
        otpParams
      }
    },
    onSuccess: async data => {
      console.log('useSignIn.onSuccess:', data)
    },
    onError: (error, variables, context) => {
      // TODO: how to clear deep link url in store if the user cancel the sign in. Is a possible state where the user is unable to go to the root wallet?
      console.log('useSignIn.onError:', error)
      options?.onError && options.onError(error, variables, context)
    }
  })

  // shouldSignIn
  useEffect(() => {
    if (_init.current === false) {
      if (discovery && request && shouldSignIn === true) {
        _init.current = true
        signInMutation.mutate()
      }
    }
  }, [discovery, request, shouldSignIn, signInMutation])

  return { signInMutation, shouldSignIn }
}

export function useInitialRouteResolver({
  initialUrl,
  config
}: {
  initialUrl: URL | null
  config: ReturnType<typeof useConfigState>['data']
}) {
  const businessIdOrName = useMemo(() => {
    if (config.isBusinessLocked) {
      return config.businessId
    }

    if (!initialUrl) {
      return undefined
    }

    if (initialUrl.pathname) {
      const regexBusinessId = pathToRegexp('/b/:businessId', undefined, {
        end: false
      })
      const businessIdInPath = regexBusinessId.exec(initialUrl.pathname)?.pop()
      if (businessIdInPath) {
        return businessIdInPath
      }
    }

    return undefined
  }, [config.businessId, config.isBusinessLocked, initialUrl])

  /**
   * @deprecated
   */
  const claimId = useMemo(() => {
    if (!initialUrl) {
      return undefined
    }

    if (initialUrl.pathname) {
      const regexClaimIdBusiness = pathToRegexp('/b/:businessId/claim/:claimId', undefined, {
        end: false
      })
      const regexClaimId = pathToRegexp('/claim/:claimId', undefined, {
        end: false
      })
      const claimId = regexClaimId.exec(initialUrl.pathname)?.pop()
      if (claimId) {
        return claimId
      }
      const claimIdBusiness = regexClaimIdBusiness.exec(initialUrl.pathname)?.pop()
      if (claimIdBusiness) {
        return claimIdBusiness
      }
      return undefined
    }

    return undefined
  }, [initialUrl])

  const urlParams = useMemo(() => {
    return {
      context: initialUrl?.searchParams.get('context') ?? undefined,
      login: initialUrl?.searchParams.get('login') ?? undefined,
      idenType: initialUrl?.searchParams.get('iden-type') ?? undefined,
      idenValue: initialUrl?.searchParams.get('iden-value') ?? undefined,
      uid: initialUrl?.searchParams.get('uid') ?? undefined,
      otp: initialUrl?.searchParams.get('otp') ?? undefined
    }
  }, [initialUrl])

  const otpParams = useMemo(() => {
    if (!urlParams.idenType || !urlParams.idenValue || !urlParams.otp) {
      return undefined
    }
    return {
      identityType: urlParams.idenType,
      identityValue: decodeURIComponent(urlParams.idenValue),
      otp: urlParams.otp
    }
  }, [urlParams.idenType, urlParams.idenValue, urlParams.otp])

  return {
    businessIdOrName,
    // claimId,
    urlParams,
    otpParams
  }
}

export function useShouldSkipConnect() {
  const isEmbedded = useWalletIsEmbedded()
  const { data: config, isLoading: isLoadingConfig } = useConfigState()

  const appState = useAppState()

  const { initialUrl } = useInitialQsStore.getState()

  // TODO: maybe claimId is needed at some point?
  const { urlParams, businessIdOrName, otpParams } = useInitialRouteResolver({
    initialUrl: initialUrl ? new URL(initialUrl) : null,
    config
  })

  const skipConnectLoginParam =
    Boolean(businessIdOrName && urlParams.login !== '0' && urlParams.login !== 'false') ||
    urlParams.login === undefined

  const shouldSignIn = useMemo(() => {
    return (
      Boolean(otpParams) ||
      isEmbedded ||
      Boolean(
        businessIdOrName &&
          appState === 'active' &&
          // !isEmbedded &&
          skipConnectLoginParam &&
          !isLoadingConfig &&
          config
      )
    )
  }, [
    appState,
    businessIdOrName,
    config,
    isEmbedded,
    isLoadingConfig,
    otpParams,
    skipConnectLoginParam
  ])

  return { shouldSignIn, otpParams, isEmbedded }
}

type AuthCode = string & { __brand: 'AuthCode' }
export const AuthCode = (code: string): AuthCode => code as AuthCode

type UseAuthCodeQueryKey = string[]
type UseAuthCodeOptions = Omit<
  UseQueryOptions<unknown, unknown, boolean, UseAuthCodeQueryKey>,
  'enabled' | 'staleTime' | 'refetchOnWindowFocus' | 'retry'
>
export function useAuthCode(code: string, options: UseAuthCodeOptions = {}) {
  const { data: config, isLoading: isLoadingConfig } = useConfigState()

  const { discovery, request, authConfig } = useAuthDiscovery({ config })

  const isEnabled =
    !!discovery &&
    !!code &&
    !!request?.codeVerifier &&
    !!authConfig &&
    !!request &&
    !isLoadingConfig

  return useQuery({
    queryKey: ['doAuthorize', code],
    queryFn: async () => {
      return await authorizeCode(AuthCode(code), {
        discovery,
        authConfig: authConfig,
        request
      })
    },
    ...options,
    enabled: isEnabled,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: () => {
      console.log('LOG: > useAuthCode > onSuccess')
    },
    onError: error => {
      console.log('LOG: > useAuthCode > onError: go to connect ', error)
    }
  })
}

function logoutClearAll() {
  // Clear user
  SDKQueryClient.setQueryData(usersKeys.me._ctx.identities.queryKey, null)
  SDKQueryClient.setQueryData(usersKeys.me.queryKey, null)
  // Clear all sessions
  destroySessions()

  SDKQueryClient.clear()
  SDKQueryClientPersister.removeClient()
  AuthSession.dismiss()

  Sentry.setUser(null)
  console.log('auth.logoutClearAll')
}

export async function doSignOut({
  currentSession,
  discovery,
  authConfig
}: {
  currentSession?: Session
  discovery: AuthSession.DiscoveryDocument | null
  authConfig: ReturnType<typeof useConfigState>['data']['authentication']
}) {
  try {
    //
    if (!currentSession) {
      throw new Error('No session found')
    }
    if (!discovery) {
      throw new Error('Missing discovery')
    }
    const { accessToken } = currentSession.value
    const { clientId } = authConfig

    await AuthSession.revokeAsync(
      { clientId, token: accessToken, tokenTypeHint: AuthSession.TokenTypeHint.AccessToken },
      discovery
    )

    if (isWeb) {
      const cookies = document.cookie.split(';')

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i]
        const eqPos = cookie.indexOf('=')
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
      }
    } else {
      // https://stackoverflow.com/questions/47207914/sfauthenticationsession-aswebauthenticationsession-and-logging-out
      await CookieManager.clearAll()
      // Remove session cookies (ANDROID ONLY)
      // Session cookies are cookies with no expires set. Android typically does not
      // remove these, it is up to the developer to decide when to remove them.
      // The return value is true if any session cookies were removed.
      // iOS handles removal of session cookies automatically on app open.
      await CookieManager.removeSessionCookies()
    }
  } catch (error) {
    console.error('auth.doSignOut:', error)
  } finally {
    logoutClearAll()
  }
}

/**
 * useQuery for logout
 * @returns void
 */
export function useSignOut() {
  const config = getConfig()
  const discovery = AuthSession.useAutoDiscovery(config.authentication.discoveryUrl)

  const { sessions } = useSessionStore()
  const vatomSession = sessions.get('vatom')

  return useQuery({
    queryKey: ['logout'],
    queryFn: async () => {
      await SDKQueryClient.cancelQueries()
      return await doSignOut({
        currentSession: vatomSession,
        discovery,
        authConfig: config.authentication
      })
    },
    enabled: !!discovery
  })
}

export function useSignOutMutation() {
  const config = getConfig()
  const discovery = AuthSession.useAutoDiscovery(config.authentication.discoveryUrl)

  const { sessions } = useSessionStore()
  const vatomSession = sessions.get('vatom')

  return useMutation({
    mutationFn: async () => {
      await SDKQueryClient.cancelQueries()
      return await doSignOut({
        currentSession: vatomSession,
        discovery,
        authConfig: config.authentication
      })
    }
  })
}
