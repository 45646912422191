import type { TypedUseQueryOptions } from '@lukemorales/query-key-factory'
import { useQuery } from '@tanstack/react-query'

import { relationshipsKeys } from './keys'
import type {
  DeviceContactResponse,
  FollowersResponse,
  FollowingResponse,
  RelationshipContact
} from './types'

type ContactsOptions<T = DeviceContactResponse[]> = TypedUseQueryOptions<
  typeof relationshipsKeys.contacts,
  T
>

export const useRelGetContacts = <T = DeviceContactResponse[]>(
  contacts: RelationshipContact[],
  options?: Pick<ContactsOptions<T>, 'select'>
) => {
  return useQuery({
    ...options,
    ...relationshipsKeys.contacts(contacts),
    enabled: !!contacts.length
  })
}

type UserFollowingOptions<T = FollowingResponse[]> = TypedUseQueryOptions<
  typeof relationshipsKeys.userFollowing,
  T
>

export const useGetUserFollowing = <T = FollowingResponse[]>(
  userId: string,
  options?: UserFollowingOptions<T>
) => {
  const result = useQuery({
    ...options,
    ...relationshipsKeys.userFollowing(userId),
    enabled: !!userId
  })
  return result
}

type UserFollowersOptions<T = FollowingResponse[]> = TypedUseQueryOptions<
  typeof relationshipsKeys.userFollowers,
  T
>
export const useGetUserFollowers = <T = FollowersResponse[]>(
  userId: string,
  options?: UserFollowersOptions<T>
) => {
  const result = useQuery({
    ...options,
    ...relationshipsKeys.userFollowers(userId),
    enabled: !!userId
  })
  return result
}

type FollowingOptions<T = RelationshipContact[]> = TypedUseQueryOptions<
  typeof relationshipsKeys.following,
  T
>
export const useRelGetFollowing = <T = RelationshipContact[]>(options?: FollowingOptions<T>) => {
  const result = useQuery({
    ...options,
    ...relationshipsKeys.following
  })
  return result
}
