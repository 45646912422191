import AsyncStorage from '@react-native-async-storage/async-storage'
import { setAuthBlockVAxiosInstance, setAuthorizationAxiosInstance } from '@vatom/sdk/services'
import { create } from 'zustand'
import type { StorageValue } from 'zustand/middleware'
import { persist } from 'zustand/middleware'

const STORE_NAME = 'session-storage'
const STORE_VERSION = 1.1 // TODO: get package version?

export const sessionType = {
  jwt: 'jwt',
  userId: 'userId'
} as const

type SessionType = keyof typeof sessionType

export const sessionSource = {
  vatom: 'vatom',
  blockV: 'blockV' // ex vatom_network
} as const

type SessionSource = keyof typeof sessionSource

type SessionJWTValue = {
  accessToken: string
  expiresAt: number
  idToken?: string
  refreshToken: string
  userId?: string
}

export type Session = {
  source: SessionSource
  type: SessionType
  value: SessionJWTValue
}

type SessionStore = {
  hydrated: boolean
  sessions: Map<SessionSource, Session>
}

function getSession(source: SessionSource): Session | undefined {
  return useSessionStore.getState().sessions.get(source)
}

function setSession(source: SessionSource, session: Session): void {
  try {
    useSessionStore.setState(prev => ({
      sessions: new Map(prev.sessions).set(source, session)
    }))
  } catch (error) {
    console.error('Unable to setSession:', error)
    throw error
  }
}

function removeSession(source: SessionSource): void {
  try {
    //
    if (!useSessionStore.getState().sessions.has(source)) {
      throw `session not found`
    }
    useSessionStore.setState(prev => {
      prev.sessions.delete(source)
      return {
        sessions: new Map(prev.sessions)
      }
    })
  } catch (error) {
    console.error('Unable to removeSession:', error)
    throw error
  }
}

function destroySessions() {
  try {
    useSessionStore.setState({ sessions: new Map() })
  } catch (error) {
    console.error('Unable to destroySessions:', error)
    throw error
  }
}

const storage = {
  getItem: async (name: string) => {
    const str = await AsyncStorage.getItem(name)
    if (!str) return null
    const existingValue = JSON.parse(str)
    return {
      ...existingValue,
      state: {
        ...existingValue.state,
        sessions: new Map(existingValue.state.sessions)
      }
    }
  },
  setItem: async (name: string, newValue: StorageValue<SessionStore>) => {
    // functions cannot be JSON encoded
    const str = JSON.stringify({
      ...newValue,
      state: {
        ...newValue.state,
        sessions: Array.from(newValue.state.sessions.entries())
      }
    })
    await AsyncStorage.setItem(name, str)
  },
  removeItem: async (name: string) => await AsyncStorage.removeItem(name)
}

export const useSessionStore = create(
  persist<SessionStore>(
    () => ({
      hydrated: false,
      sessions: new Map<SessionSource, Session>()
    }),
    {
      name: STORE_NAME,
      version: STORE_VERSION,
      storage,
      onRehydrateStorage: () => state => {
        console.log('rehydrated', state)
        const vatomSession = state?.sessions.get('vatom')?.value
        if (vatomSession) {
          const { accessToken, refreshToken } = vatomSession
          setAuthorizationAxiosInstance({ accessToken, refreshToken })
        }
        const blockVSession = state?.sessions.get('blockV')?.value
        if (blockVSession) {
          const { accessToken, refreshToken } = blockVSession
          setAuthBlockVAxiosInstance({ accessToken, refreshToken })
        }
        useSessionStore.setState(() => ({ hydrated: true }))
      }
    }
  )
)

export { destroySessions, getSession, removeSession, setSession }
