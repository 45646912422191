/* eslint-disable react-native-a11y/has-valid-accessibility-ignores-invert-colors */
import React from 'react'
import type { ImageProps } from 'react-native'
import { Dimensions, Image } from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import type { IBoxProps, IIconProps, ITextProps, IToastProps } from 'native-base'
import { Avatar, Box, HStack, Icon, IconButton, Text, Toast as T } from 'native-base'

import { IconsSVG } from './icons'

export const Status = {
  warning: 'warning',
  success: 'success',
  info: 'info',
  error: 'error'
} as const
export type StatusType = keyof typeof Status

const StatusIcons = {
  [Status.warning]: IconsSVG.Warning,
  [Status.success]: IconsSVG.Success,
  [Status.info]: IconsSVG.Info,
  [Status.error]: IconsSVG.Error
}

export interface ToastProps extends IToastProps {
  title?: string
  status?: StatusType
  avatar?: string
  duration?: number
  withMargin?: boolean
  TitleComponent?: React.FC
  closeIconProps?: IIconProps
  containerProps?: IBoxProps
  textProps?: ITextProps
  secondIcon?: string
  icon?: string
  iconProps?: ImageProps
  secondIconProps?: ImageProps
}

const TOAST_INSET = 50

export const Toast = ({
  title,
  status,
  duration,
  placement,
  avatar,
  withMargin = false,
  TitleComponent,
  icon,
  secondIcon,
  ...rest
}: ToastProps) => {
  const offsetTopWeb = placement === 'top' || placement === undefined ? TOAST_INSET : 0
  const toastWidth = Dimensions.get('window').width - (withMargin ? 32 : 0)
  const StatusIcon = status && StatusIcons[status]

  const toast = () => (
    <Box
      shadow={1}
      backgroundColor={'#11141E'}
      p={4}
      rounded={4}
      w={toastWidth}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      _web={
        offsetTopWeb
          ? {
              top: -offsetTopWeb // Reset the top prop from the library for web
            }
          : undefined
      }
      {...rest.containerProps}
    >
      <HStack alignItems={'center'}>
        {!icon && !secondIcon && StatusIcon ? (
          <Box mr={2}>
            <StatusIcon width={16} height={16} />
          </Box>
        ) : null}
        {icon ? (
          <Box mr={2}>
            {/* base64 image string */}
            <Image
              source={{ uri: icon }}
              alt="icon"
              style={{ width: 16, height: 16 }}
              {...rest.iconProps}
            />
          </Box>
        ) : null}
        {secondIcon ? (
          <Box mr={2}>
            <Image
              source={{ uri: secondIcon }}
              alt="icon"
              style={{ width: 16, height: 16 }}
              {...rest.secondIconProps}
            />
          </Box>
        ) : null}
        {avatar ? <Avatar source={{ uri: avatar }} size={6} mr={2} /> : null}
      </HStack>
      <Box flexGrow={1} flexShrink={0} flexBasis={'1'}>
        {title ? (
          <Text
            fontWeight="semibold"
            color={'#D9D9DB'}
            noOfLines={2}
            ellipsizeMode="tail"
            {...rest.textProps}
          >
            {title}
          </Text>
        ) : null}
        {TitleComponent ? <TitleComponent /> : null}
      </Box>
      <Box ml={2}>
        <IconButton
          onPress={() => T.closeAll()}
          size={5}
          w={5}
          hitSlop={20}
          icon={
            <Icon
              as={AntDesign}
              name="close"
              color={rest.color ?? '#B3B4B7'}
              {...rest.closeIconProps}
            />
          }
        />
      </Box>
    </Box>
  )

  T.show({
    duration,
    placement: placement ?? 'top',
    render: toast,
    ...rest
  })
}
