import type { AppConfig, VatomToken } from '@vatom/models'
import type { AnyToken, NftFilterState } from '@vatom/sdk/react'
import {
  getVatomCategory,
  isAlchemyNft,
  isBlockVNft,
  isVatomNft,
  nftFilterBy
} from '@vatom/sdk/react'

type WalletFilterOptions = NftFilterState

export function walletFilters(token: AnyToken, { filterBy, filterAddress }: WalletFilterOptions) {
  if (filterBy === nftFilterBy.all) {
    return true
  }
  if (filterBy === nftFilterBy.wallet && filterAddress) {
    return isAlchemyNft(token) && token.address.toLowerCase() === filterAddress.toLowerCase()
  }
  if (filterBy !== nftFilterBy.wallet) {
    return isVatomNft(token) && getVatomCategory(token, { isTitle: true }) === filterBy
  }
  return true
}

type BusinessFilterOptions = {
  config: AppConfig
}
export function businessFilters(
  token: AnyToken,
  businessId: string,
  { config }: BusinessFilterOptions
): boolean {
  // - remove alchemy tokens
  // - black list ?
  // - check for studioInfo businessId
  // - joyAppHack
  // - isCustomDomainRoot
  if (isAlchemyNft(token)) {
    return false
  }
  if ((isVatomNft(token) || isBlockVNft(token)) && token.studioInfo?.businessId === businessId) {
    return true
  }

  const isJoyAppHack = businessId === 'Cox1qh6ggb' || businessId === 'QkxH4IbOIl'
  if (isJoyAppHack && isBlockVNft(token)) {
    return token['vAtom::vAtomType'].publisher_fqdn === 'mx.joyapp'
  }

  const isCustomDomainRoot = config.isBusinessLocked && config.businessId === businessId
  if (
    (isVatomNft(token) || isBlockVNft(token)) &&
    isCustomDomainRoot &&
    token.studioInfo?.businessId
  ) {
    return config.subBusinesses.includes(token.studioInfo.businessId)
  }

  return false
}

export function businessSort(
  a: AnyToken,
  b: AnyToken,
  { pinnedIds }: { pinnedIds?: string[] }
): number {
  //
  if (isAlchemyNft(a) || isAlchemyNft(b)) {
    // NOTE: doesn't matter this are filtered previously
    return 1
  }

  if (
    pinnedIds &&
    a.studioInfo?.objectDefinitionId &&
    pinnedIds.includes(a.studioInfo?.objectDefinitionId)
  ) {
    return -1
  }

  // Honda hack to pin coupon first
  const hondaViewIds = ['GJmxWCIKSo', '8WjdTi4m4z']
  const aIsHondaHack =
    a.studioInfo?.businessId === 'jEnLk2u9l3' &&
    hondaViewIds.includes(
      (a as unknown as VatomToken)?.viewPlacements?.find(v => v?.placeholder?.id === 'card-v1')
        ?.view?.id ?? ''
    )
  const bIsHondaHack =
    b.studioInfo?.businessId === 'jEnLk2u9l3' &&
    hondaViewIds.includes(
      (b as unknown as VatomToken)?.viewPlacements.find(v => v?.placeholder?.id === 'card-v1')?.view
        ?.id ?? ''
    )

  if (aIsHondaHack) {
    return -1
  }
  if (bIsHondaHack) {
    return 1
  }

  const aTarget = new Date(a.createdAt)
  const bTarget = new Date(b.createdAt)
  try {
    if (aTarget.getTime() < bTarget.getTime()) {
      return 1
    }
    if (aTarget.getTime() > bTarget.getTime()) {
      return -1
    }

    return 0
  } catch (error) {
    console.error('Error sorting', error)
    return 0
  }
}

function tokenBlackListFilter(token: AnyToken, config: AppConfig): boolean {
  if (!config?.features?.inventory?.tv_blacklist) {
    return true
  }
  if (isBlockVNft(token)) {
    return !config?.features?.inventory?.tv_blacklist.includes(token.properties.template_variation)
  }
  return true
}
