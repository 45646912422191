import type { VatomToken, VatomTokenId } from '@vatom/models/vatom'
import { getConfig } from '@vatom/sdk/react'

import { vatomAxiosInstance } from '../vatomAxiosInstance'

type PatchVatomTokenPayload = Partial<{
  openedAt: string | null
}>

export async function patchVatomToken(tokenId: VatomTokenId, payload: PatchVatomTokenPayload) {
  const config = getConfig()
  const { data } = await vatomAxiosInstance.patch<Partial<VatomToken>>(
    `${config.api.network}/vatoms/${tokenId}`,
    payload
  )
  return data
}

export async function deleteVatomToken(tokenId: VatomTokenId) {
  const config = getConfig()
  await vatomAxiosInstance.delete(`${config.api.network}/vatoms/${tokenId}`)
}

type KnownActionPayload =
  | {
      behaviorId: 'transferable-v1'
      actionId: 'transfer-v1'
    }
  | {
      behaviorId: 'cloneable-v1'
      actionId: 'clone-v1'
    }
  | {
      behaviorId: 'map-ready-v1'
      actionId: 'drop-v1' | 'pickup-v1'
    }
  | {
      behaviorId: string
      actionId: string
    }

type ExecuteActionPayload = KnownActionPayload & {
  parameters?: unknown
}

async function executeAction(tokenId: VatomTokenId, payload: ExecuteActionPayload) {
  const config = getConfig()
  const { data } = await vatomAxiosInstance.post<Partial<VatomToken['state']>>(
    `${config.api.network}/vatoms/${tokenId}/execute-action`,
    payload
  )
  return data
}
export const _dangerouslyExecuteAction = executeAction

type RecipientParams = {
  recipient: {
    type: 'id' | 'email' | 'phoneNumber'
    value: string
  }
}

type TransferParams =
  | {
      recipientUserId: string
    }
  | RecipientParams

export async function transferVatomToken(tokenId: VatomTokenId, parameters: TransferParams) {
  return executeAction(tokenId, {
    behaviorId: 'transferable-v1',
    actionId: 'transfer-v1',
    parameters
  })
}
export async function acquireVatomToken(tokenId: VatomTokenId) {
  return executeAction(tokenId, {
    behaviorId: 'acquirable-v1',
    actionId: 'acquire-v1'
  })
}

type CloneParams =
  | {
      cloneOwnerUserId: string
    }
  | RecipientParams

export async function cloneVatomToken(tokenId: VatomTokenId, parameters: CloneParams) {
  return executeAction(tokenId, {
    behaviorId: 'cloneable-v1',
    actionId: 'clone-v1',
    parameters
    // parameters.cloneOwner
  })
}

export async function pickupVatomToken(tokenId: VatomTokenId) {
  return executeAction(tokenId, {
    behaviorId: 'map-ready-v1',
    actionId: 'pickup-v1'
  })
}

type DropParams = {
  location: {
    lat: number
    lon: number
  }
}

export async function dropVatomToken(tokenId: VatomTokenId, parameters: DropParams) {
  return executeAction(tokenId, {
    behaviorId: 'map-ready-v1',
    actionId: 'drop-v1',
    parameters
  })
}

export async function fetchById(tokenId: string) {
  const config = getConfig()
  const { data } = await vatomAxiosInstance.get<VatomToken>(
    `${config.api.network}/vatoms/${tokenId}`
  )
  return data
}
