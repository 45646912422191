import { AuthHelpers, setSession } from '@vatom/sdk/auth'
import axios, { type CreateAxiosDefaults } from 'axios'
import type { JwtPayload } from 'jwt-decode'
import jwtDecode from 'jwt-decode'

import { createAuthInterceptor } from './axios-helper'

const COMMON_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}
const axiosConfig = {
  headers: COMMON_HEADERS
} satisfies CreateAxiosDefaults

const blockVApiUrl = 'https://api.vi.vatom.network' // config.server
const appId = 'd9fd1482-3625-4746-854f-726b9032d4ff' // config.appId

export const blockVAxiosInstance = axios.create(axiosConfig)

type RefreshTokenResponse = {
  request_id: string
  payload: {
    access_token: {
      token: string
      token_type: string
      expires_in: number
    }
  }
}

export async function authRefreshAsync({ refreshToken }: { refreshToken: string }) {
  const { data } = await axios.post<RefreshTokenResponse>(`${blockVApiUrl}/v1/access_token`, '', {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
      'App-Id': appId
    }
  })
  console.log('LOG: BlockV AXIOS > authRefreshAsync > data:', data)
  const accessToken = data.payload.access_token.token
  const decodedToken = jwtDecode<JwtPayload>(accessToken)
  console.log('LOG: > authRefreshAsync > decodedToken:', decodedToken)
  const expiresAt = AuthHelpers.getExpireAt(data.payload.access_token.expires_in)

  setSession('blockV', {
    source: 'blockV',
    type: 'jwt',
    value: {
      accessToken,
      expiresAt: decodedToken?.exp ?? expiresAt,
      refreshToken: refreshToken ?? '',
      userId: decodedToken.sub
    }
  })

  return accessToken
}

async function refreshAuthToken({
  failedRequest,
  refreshToken
}: {
  failedRequest: any
  refreshToken: string
}) {
  const accessToken = await authRefreshAsync({ refreshToken })
  console.log('LOG: refreshAuthToken > accessToken:', accessToken)
  // Set auth headers
  failedRequest.config.headers['Authorization'] = `Bearer ${accessToken}`
  blockVAxiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`

  return Promise.resolve()
}

export function setAuthBlockVAxiosInstance({
  accessToken,
  refreshToken
}: {
  accessToken: string
  refreshToken: string
}) {
  console.log('LOG: setAuthBlockVAxiosInstance > accessToken:', accessToken)
  // set access token to headers
  blockVAxiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
  blockVAxiosInstance.defaults.headers.common['App-Id'] = appId

  createAuthInterceptor({
    axiosInstance: blockVAxiosInstance,
    refreshAuth: async (error: any) =>
      await refreshAuthToken({ failedRequest: error, refreshToken })
  })
}
