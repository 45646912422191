import { createContext, useCallback, useContext } from 'react'
import type { SharedValue } from 'react-native-reanimated'
import { Easing, useAnimatedScrollHandler, useSharedValue } from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useFocusEffect, useRoute } from '@react-navigation/native'
import { throttle } from 'lodash-es'

import { defaultHeaderMinHeight } from '../components/ScreenHeaderWrapper'
import { TabBarHeight } from '../screens/Home/components/TabBar'

type LayoutScrollHandler = {
  lastContentOffset: SharedValue<number>
  isScrolling: SharedValue<boolean>
  headerHeight: SharedValue<number>
  footerHeight: SharedValue<number>
  shouldHideElements: SharedValue<boolean>
  previousRoute: SharedValue<ReturnType<typeof useRoute> | null>
}

const LayoutScrollHandlerContext = createContext<LayoutScrollHandler | null>(null)
export const LayoutScrollHandlerProvider = ({ children }: React.PropsWithChildren) => {
  const insets = useSafeAreaInsets()
  const defaultFooterHeight = TabBarHeight + insets.bottom
  const lastContentOffset = useSharedValue(0)
  const isScrolling = useSharedValue(false)
  const headerHeight = useSharedValue(defaultHeaderMinHeight)
  const footerHeight = useSharedValue(defaultFooterHeight)
  const shouldHideElements = useSharedValue(false)
  const previousRoute = useSharedValue(null)

  return (
    <LayoutScrollHandlerContext.Provider
      value={{
        lastContentOffset,
        isScrolling,
        headerHeight,
        footerHeight,
        shouldHideElements,
        previousRoute
      }}
    >
      {children}
    </LayoutScrollHandlerContext.Provider>
  )
}

export const useLayoutScrollHandler = () => {
  const duration = 350
  const easing = Easing.inOut(Easing.ease)
  const scrollEventThrottle = 32

  const {
    lastContentOffset,
    isScrolling,
    headerHeight,
    footerHeight,
    shouldHideElements,
    previousRoute
  } = useContext(LayoutScrollHandlerContext) as LayoutScrollHandler

  const route = useRoute()
  const detectRouteChange = useCallback(() => {
    if (previousRoute.value?.name !== route.name) {
      // Not hide element when route change
      lastContentOffset.value = 0
      shouldHideElements.value = false
    }
    previousRoute.value = route
  }, [previousRoute, lastContentOffset, route, shouldHideElements])
  useFocusEffect(detectRouteChange)

  const scrollHandler = useAnimatedScrollHandler({
    onScroll: event => {
      const offsetY = event.contentOffset.y
      // Scroll Up
      if (lastContentOffset.value > offsetY && isScrolling.value) {
        shouldHideElements.value = false
      }
      // Scroll Down
      else if (lastContentOffset.value < offsetY && isScrolling.value) {
        shouldHideElements.value = true
      }
      lastContentOffset.value = offsetY
    },
    onBeginDrag: e => {
      isScrolling.value = true
    },
    onEndDrag: e => {
      isScrolling.value = false
    }
    // onMomentumBegin: e => {},
    // onMomentumEnd: e => {}
  })

  const onScrollWebView = throttle(({ x, y }: { x: number; y: number }) => {
    const offsetY = y
    if (!offsetY || offsetY < 0) {
      shouldHideElements.value = false
      lastContentOffset.value = 0
      return
    }
    // Scroll Up
    if (lastContentOffset.value > offsetY) {
      shouldHideElements.value = false
    }
    // Scroll Down
    else if (lastContentOffset.value < offsetY) {
      shouldHideElements.value = true
    }
    lastContentOffset.value = offsetY
  }, scrollEventThrottle)

  const setHeaderHeight = (height: number) => {
    if (height !== 0) {
      headerHeight.value = height
    }
  }

  const getHeaderHeight = useCallback(() => headerHeight.value, [headerHeight.value])

  const setFooterHeight = (height: number) => {
    if (height !== 0) {
      footerHeight.value = height
    }
  }

  const getFooterHeight = useCallback(() => footerHeight.value, [footerHeight.value])

  return {
    lastContentOffset,
    shouldHideElements,
    scrollHandler,
    onScrollWebView,
    scrollEventThrottle,
    setHeaderHeight,
    getHeaderHeight,
    headerHeight,
    setFooterHeight,
    getFooterHeight,
    footerHeight,
    defaultAnimationDuration: duration,
    defaultAnimationEasing: easing
  }
}

export default LayoutScrollHandlerProvider
