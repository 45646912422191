import type { VatomPayload, VatomToken } from './VatomModel'
import {
  CardViewV1,
  DynamicImageV1,
  ImageV1,
  isDynamicImageV1,
  isImageV1,
  StatePointers
} from './VatomModel'

// ex displayImage
export function getFeaturedImage(
  vatom: VatomPayload,
  { defaultImage } = {
    defaultImage: 'https://fakeimg.pl/100x100/'
  }
) {
  //
  const placement = vatom.viewPlacements.find(v => v.placeholder.id === 'icon-v1')
  if (!placement) {
    return defaultImage
  }

  if (isImageV1(placement.view)) {
    return ImageV1(placement.view).config.image.ref ?? defaultImage
  }

  if (isDynamicImageV1(placement.view)) {
    const state = getDynamicImageV1State(vatom)
    return state?.image.ref ?? defaultImage
  }
  return defaultImage
}

export function getDynamicImageV1State(vatom: VatomPayload) {
  const { view } =
    vatom.viewPlacements.find(
      v => v.placeholder.id === 'icon-v1' && v.view.id === 'dynamic-image-v1'
    ) ?? {}

  if (!view) {
    return undefined
  }

  const { config } = DynamicImageV1(view) ?? {}
  if (!config?.states) {
    return undefined
  }

  const defaultState = config.states.find(s => s.name === 'Default')

  const ruleStates = config.states.filter(
    s => Object.keys(s.rule).length > 0 && s.name !== 'Default'
  )

  if (ruleStates?.length === 0) {
    return defaultState
  }

  // Find rule states. use statePointer to resolve the rules and select the correct state
  // E.g.
  // "rule": {
  //     "statePointer": "/cloneable-v1/cloningScore",
  //     "isEqualTo": 0.75
  // }

  if (!('statePointer' in ruleStates[0].rule)) {
    console.warn('DynamicImageV1: NO statePointer in rule')
    return defaultState
  }

  const _pointer = ruleStates[0].rule.statePointer as string
  if (!_pointer.startsWith('/')) {
    console.warn('DynamicImageV1: statePointer should start with "/" ')
    return defaultState
  }

  // TODO: Remove test code, this should be fix on the server or the model
  const pointer = _pointer.replace('cloningScore', 'cloneScore')
  // try to find in state
  const pointers = StatePointers(pointer.split('/').filter(a => a))

  let pointedState: any = vatom.state
  for (const point of pointers) {
    if (!pointedState) {
      break
    }
    if (!pointedState[point]) {
      pointedState = undefined
      break
    }
    pointedState = pointedState[point]
  }

  if (typeof pointedState === 'undefined') {
    return defaultState
  }
  const foundRuleMatch = ruleStates.find(s => s.rule.isEqualTo === pointedState)
  if (!foundRuleMatch) {
    console.warn(
      `DynamicImageV1: find state for rule issue, pointer: ${pointer.toString()}. found state:`,
      pointedState,
      ruleStates
    )
    return defaultState
  }

  return foundRuleMatch
}

/**
 * Return all the view placements except for icon-v1
 * @param vatom
 * @returns
 */
export function getViewPlacements(token: VatomToken) {
  // Filter viewPlacements to remove icon-v1
  return token.viewPlacements.filter(p => p.placeholder.id !== 'icon-v1')
}

export function getViewPlacementBackgroundV1(token: VatomToken) {
  return token.viewPlacements.find(p => p.placeholder.id === 'background-v1')
}

export function getViewPlacementCardV1(token: VatomToken) {
  const placement = token.viewPlacements.find(p => p.placeholder.id === 'card-v1')
  console.log('LOG: > getViewPlacementCardV1 > placement:', placement)
  if (!placement) {
    return undefined
  }
  return CardViewV1(placement.view)
}

export function getMetadata(token: VatomToken) {
  const meta = token.state['meta-v1']
  return {
    name: meta?.displayName ?? '',
    description: meta?.description ?? '',
    image: getFeaturedImage(token),
    category: meta?.category ?? ''
  }
}

export function getPosition(token: VatomToken) {
  const map = token.state['map-ready-v1']
  if (!map || !map.location) {
    return null
  }
  const { lon, lat } = map.location
  return {
    coordinates: [lon, lat]
  }
}

export type KnownActionTypes = 'Drop' | 'Pickup' | 'Transfer' | 'Delete' | 'Clone' | 'custom'

export function getActionsList(token: VatomToken) {
  const stateKeys = Object.keys(token.state)
  const result: Array<KnownActionTypes> = ['Delete']

  if (stateKeys.includes('map-ready-v1')) {
    const map = token.state['map-ready-v1']
    if (map?.location) {
      result.push('Pickup')
    } else {
      result.push('Drop')
    }
  }

  if (stateKeys.includes('transferable-v1')) {
    result.push('Transfer')
  }
  if (stateKeys.includes('cloneable-v1')) {
    result.push('Clone')
  }

  return result
}

type SendPayload = {
  type: 'id' | 'email' | 'phoneNumber'
  value: string
}

export type SendPayloadKnownDataKeys = 'phoneNumber' | 'email'

export function createSendPayload(
  data: string | Record<SendPayloadKnownDataKeys, string | undefined>,
  userId?: string
) {
  const payload = {} as SendPayload

  if (typeof data === 'string') {
    if (data.indexOf('@') !== -1) {
      payload.type = 'email'
      payload.value = data
    } else if (data.indexOf('+') === 0) {
      payload.type = 'phoneNumber'
      payload.value = data
    } else {
      payload.type = 'id'
      payload.value = data
    }
  } else {
    if (data.phoneNumber) {
      payload.type = 'phoneNumber'
      payload.value = data.phoneNumber
    } else if (data.email) {
      payload.type = 'email'
      payload.value = data.email
    } else if (userId) {
      payload.type = 'id'
      payload.value = userId
    } else {
      throw new Error(
        `The user object supplied didn't have any identifying fields. It must have either a userID, an email, or a phoneNumber.`
      )
    }
  }
  return payload
}

// async performAction(action: KnownActionTypes, payload?: any, extra?: any): Promise<any> {
//       const region = getParent(self, 2) as InventoryRegionType
//       console.log('performAction', !!region, action, payload, extra)
//       console.log('inventoryApi', region.inventoryApi)
//       try {
//         if (action === 'Transfer') {
//           const { type, value } = await createRecipientTransferPayload(
//             payload,
//             action,
//             extra?.userProfile?.id
//           )
//           await vatomAxiosInstance.post(`${region.inventoryApi}/vatoms/${self.id}/execute-action`, {
//             behaviorId: 'transferable-v1',
//             actionId: 'transfer-v1',
//             parameters: {
//               recipient: {
//                 type,
//                 value
//               }
//             }
//             // parameters: {
//             //   recipientUserId: extra.userProfile.id
//             // }
//           })
//         }

//         if (action === 'Pickup') {
//           await vatomAxiosInstance.post(`${region.inventoryApi}/vatoms/${self.id}/execute-action`, {
//             behaviorId: 'map-ready-v1',
//             actionId: 'pickup-v1'
//           })
//         }

//         if (action === 'Drop') {
//           /** payload
//          {
//         'geo.pos': { Lat: coords.latitude, Lon: coords.longitude }
//             }
//          */
//           await vatomAxiosInstance.post(`${region.inventoryApi}/vatoms/${self.id}/execute-action`, {
//             behaviorId: 'map-ready-v1',
//             actionId: 'drop-v1',
//             parameters: {
//               location: {
//                 lat: payload['geo.pos'].Lat,
//                 // lng: payload['geo.pos'].Lon,
//                 lon: payload['geo.pos'].Lon
//               }
//             }
//           })
//         }

//         if (action === 'Delete') {
//           await vatomAxiosInstance.delete(`${region.inventoryApi}/vatoms/${self.id}`)
//         }

//         if (action === 'Clone') {
//           const { type, value } = await createRecipientTransferPayload(
//             payload,
//             action,
//             extra?.userProfile?.id
//           )
//           // 0 -> 0.25 when cloneScore is 25
//           const currentScore = self.state['cloneable-v1']?.cloneScore ?? 0

//           const newCloneScore = currentScore + (self.state['cloneable-v1']?.perCloneGain ?? 0) / 100
//           self.state['cloneable-v1']?.setCloneScore(newCloneScore)

//           await vatomAxiosInstance.post(`${region.inventoryApi}/vatoms/${self.id}/execute-action`, {
//             behaviorId: 'cloneable-v1',
//             actionId: 'clone-v1',
//             parameters: {
//               recipient: { type, value }
//             }
//             // parameters: {
//             //   cloneOwnerUserId: extra.userProfile.id
//             // }
//           })
//         }
//         if (action === 'custom') {
//           return await vatomAxiosInstance.post(
//             `${region.inventoryApi}/vatoms/${self.id}/execute-action`,
//             payload
//           )
//         } else {
//           console.warn('VatomModel.performAction: Unhandled action!', action, payload)
//         }
//       } catch (error) {
//         console.error('Error performing action', error)
//         // @ts-ignore
//         // if (error?.response?.data) {
//         //   // TODO: handle errors gracefully for all actions
//         //   // @ts-ignore
//         //   const errorMessage = error.response.data?.error?.message ?? 'Vatom must be cloneable'
//         //   const _e = new Error(errorMessage)
//         //   _e.name = 'Clone Vatom'
//         //   throw _e
//         // }
//         throw error
//       } finally {
//         region.reload()
//       }
//     }
