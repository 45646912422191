import type { AnalyticsPayload } from '../types'

import { postMessage } from './postMessage'

export const sendAnalyticsEvent = (payload: AnalyticsPayload) => {
  const { ReactNativeWebView } = window
  postMessage({ type: 'SEND_ANALYTICS', payload: payload }, ReactNativeWebView)
}

export const Analytics = {
  track: sendAnalyticsEvent
}

function runOnWeb() {
  // Click tracking

  // interface ElementInfo {
  //   tag: string
  //   text: string
  //   id: string
  //   classes: string[]
  //   href: string
  // }

  // // Attach a click listener to the document to capture all click events
  // document.addEventListener('click', (event: MouseEvent) => {
  //   const clickedElement = event.target as HTMLElement

  //   // Collect information about the clicked element
  //   const elementInfo: ElementInfo = {
  //     tag: clickedElement.tagName, // Element type (e.g., BUTTON, DIV)
  //     text: clickedElement.innerText || '', // Text content of the element
  //     id: clickedElement.id || '', // ID of the element, if available
  //     classes: Array.from(clickedElement.classList), // Classes applied to the element
  //     href: clickedElement instanceof HTMLAnchorElement ? clickedElement.href : '' // URL if it's a link
  //   }

  //   // Call the analytics function to log the click event
  //   sendAnalyticsEvent({
  //     type: 'CLICK',
  //     payload: elementInfo
  //   })
  // })

  /// Pageview tracking

  // Listen for popstate events
  window.addEventListener('popstate', () => {
    fireAnalyticsEvent(window.location.href, 'popstate')
  })

  // Override pushState and replaceState
  ;(function (history) {
    const originalPushState = history.pushState
    const originalReplaceState = history.replaceState

    // Override pushState
    history.pushState = function (
      state: any, // state can be any type
      title: string, // title is a string (usually not used by browsers)
      url?: string | null // url is optional, can be null or a string
    ): void {
      const result = originalPushState.call(history, state, title, url)
      fireAnalyticsEvent(url || window.location.href, 'pushState')
      return result
    }

    // Override replaceState
    history.replaceState = function (state: any, title: string, url?: string | null): void {
      const result = originalReplaceState.call(history, state, title, url)
      fireAnalyticsEvent(url || window.location.href, 'replaceState')
      return result
    }
  })(window.history)

  // Analytics event function
  function fireAnalyticsEvent(url: string, eventType: string) {
    // Logic to fire analytics event
    sendAnalyticsEvent({
      type: 'SCREEN_VIEW',
      payload: {
        url,
        eventType
      }
    })
  }
}
// initiate
if (window && typeof document !== 'undefined') {
  runOnWeb()
}
