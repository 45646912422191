import React, { useCallback, useEffect, useMemo } from 'react'
import Animated, { useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { useIsFocused } from '@react-navigation/native'
import { theme } from '@vatom/wombo'
import { Box } from 'native-base'

import { useBusinessTheme } from '../../hooks/useBusinessTheme'
import { useIsDesktop } from '../../hooks/useIsDesktop'
import { useLayoutScrollHandler } from '../../hooks/useLayoutScrollHandler'
import { useHeaderDesktopContext } from '../../screens/Home/partials/DesktopHeader/useHeaderDesktop'

const AnimatedBox = Animated.createAnimatedComponent(Box)

export const defaultHeaderMinHeight = 60

type ScreenHeaderWrapperProps = {
  rightComponent?: React.ReactNode
  centerComponent?: React.ReactNode
  leftComponent?: React.ReactNode
  containerProps?: React.ComponentProps<typeof Box>
  leftContainerProps?: React.ComponentProps<typeof Box>
  centerContainerProps?: React.ComponentProps<typeof Box>
  rightContainerProps?: React.ComponentProps<typeof Box>
  showInDesktop?: boolean
}

function ScreenHeaderWrapper({
  rightComponent,
  centerComponent,
  leftComponent,
  containerProps,
  leftContainerProps,
  centerContainerProps,
  rightContainerProps,
  showInDesktop = false
}: ScreenHeaderWrapperProps) {
  const isDesktop = useIsDesktop()
  const { setHeaderPortalLeft, setHeaderPortalCenter, setHeaderPortalRight, cleanUp } =
    useHeaderDesktopContext()
  const { isDark } = useBusinessTheme()
  const isFocused = useIsFocused()
  const {
    setHeaderHeight,
    defaultAnimationDuration,
    defaultAnimationEasing,
    headerHeight,
    shouldHideElements
  } = useLayoutScrollHandler()

  const mountHeader = useCallback(() => {
    if (!isDesktop) {
      return
    }
    leftComponent && setHeaderPortalLeft(leftComponent)
    centerComponent && setHeaderPortalCenter(centerComponent)
    rightComponent && setHeaderPortalRight(rightComponent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop, leftComponent, centerComponent, rightComponent])

  useEffect(() => {
    isFocused && mountHeader()
  }, [isFocused, mountHeader, leftComponent, centerComponent, rightComponent])

  useEffect(() => {
    if (isDesktop) {
      if (isFocused) {
        mountHeader()
      } else {
        // cleanUp()
      }
    }
  }, [isFocused, isDesktop, mountHeader])

  const headerAnimatedStyle = useAnimatedStyle(() => {
    const headerOffset = -headerHeight.value
    const offset = shouldHideElements.value ? headerOffset : 0
    return {
      position: 'absolute',
      top: 0,
      zIndex: 2,
      transform: [
        {
          translateY: withTiming(offset, {
            duration: defaultAnimationDuration,
            easing: defaultAnimationEasing
          })
        }
      ]
    }
  })

  const defaultBackgroundStyle = useMemo(() => {
    if (!containerProps?.backgroundColor && !containerProps?.bgColor) {
      return { backgroundColor: isDark ? theme.colors.grayCool[900] : theme.colors.white }
    }
    return {}
  }, [containerProps?.backgroundColor, containerProps?.bgColor, isDark])

  if (isDesktop && !showInDesktop) {
    return null
  }

  if (!rightComponent && !centerComponent && !leftComponent) {
    return null
  }

  return (
    <AnimatedBox
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      minHeight={defaultHeaderMinHeight}
      width={'100%'}
      safeAreaTop
      {...containerProps}
      style={[defaultBackgroundStyle, headerAnimatedStyle]}
      onLayout={layout => setHeaderHeight(layout.nativeEvent.layout.height)}
    >
      {
        <Box
          alignSelf={'stretch'}
          alignItems={'center'}
          flex={1}
          minWidth={8}
          minHeight={10}
          justifyContent={'flex-start'}
          flexDirection={'row'}
          {...leftContainerProps}
        >
          {leftComponent}
        </Box>
      }
      {centerComponent && (
        <Box
          alignSelf={'stretch'}
          justifyContent={'center'}
          alignItems={'center'}
          flex={1}
          minWidth={8}
          minHeight={10}
          flexDirection={'row'}
          {...centerContainerProps}
        >
          {centerComponent}
        </Box>
      )}

      <Box
        alignSelf={'stretch'}
        alignItems={'center'}
        justifyContent={'flex-end'}
        flex={1}
        minWidth={8}
        minHeight={10}
        flexDirection={'row'}
        {...rightContainerProps}
      >
        {rightComponent}
      </Box>
    </AnimatedBox>
  )
}

export { ScreenHeaderWrapper }
export type { ScreenHeaderWrapperProps }
