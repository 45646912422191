import React, { lazy, Suspense, useRef, useState } from 'react'
import { usePreventScroll } from 'react-aria'
import { Modal, Platform } from 'react-native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { useMutation } from '@tanstack/react-query'
import type { UserModel } from '@vatom/models'
import {
  getSession,
  loginWithOtp,
  useInitialRouteResolver,
  useIsAuth,
  useSignOutMutation
} from '@vatom/sdk/auth'
import {
  // SpaceMonitor,
  useConfig,
  useDebugMode,
  useInitialQsStore,
  // useMatrixFullStateSync,
  useUser
} from '@vatom/sdk/react'
import { i18n, translate } from '@vatom/utils'
import type { AppStackParamList } from '@vatom/wallet-routes'
import { AppRoutes } from '@vatom/wallet-routes'
import { convertToHexAlpha, Text as TextTranslate, theme } from '@vatom/wombo'
import axios from 'axios'
import { BlurView } from 'expo-blur'
// import { LoaderView } from '@vatom/wombo'
import * as Linking from 'expo-linking'
// import { useLocales } from 'expo-localization'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { Box, Pressable, Text, VStack } from 'native-base'
import { useThrottledCallback } from 'use-debounce'

import { Title } from '../../components/Title'
import { useMaxWidth } from '../../components/WebWrapper'
// import { useBroadcastSession } from '../../hooks/useBroadcastSession'
// import { useBrowserCompatibilityWarning } from '../../hooks/useBrowserCompatibilityWarning'
import { useBusinessTheme } from '../../hooks/useBusinessTheme'
// import { useForceUpdate } from '../../hooks/useForceUpdate'
import { LayoutScrollHandlerProvider } from '../../hooks/useLayoutScrollHandler'
// import { useNavigationPending } from '../../hooks/useNavigationPending'
import { removeBodyScroll } from '../../hooks/useRemoveBodyScroll.web'
import logger from '../../logger'
import ProcessButton from '../../screens/ActionSheets/partials/ProcessButton'
import { Connect } from '../../screens/Connect/Connect'
import { LoginCallback } from '../../screens/Connect/LoginCallback'
// import Home from '../../screens/Home'

// import { useSDKHandlers } from '../../utils/WalletSdk/hooks'
//
const CustomFavicon = lazy(() => import('../../components/Favicon'))
const Home = lazy(() => import('../../screens/Home'))
// const ModalReedem = lazy(() => import('../../components/ModalRedeem/ModalReedem'))
const RewardsCard = lazy(() => import('../../components/RewardsCard'))
const Acquire = lazy(() => import('../../screens/Acquire'))
const AcquirePubVariation = lazy(() => import('../../screens/AcquirePubVariation'))
const NftFiltersModal = lazy(() => import('../../screens/ActionSheets/NftFiltersModal'))
const BackupToCloud = lazy(() => import('../../screens/BackupToCloud'))
const BlockchainNetwork = lazy(() => import('../../screens/BlockChainNetworks/BlockChainNetworks'))
const BlockChainNetworkAdd = lazy(
  () => import('../../screens/BlockChainNetworks/BlockChainNetworksAdd')
)
const BlockChainNetworkEdit = lazy(
  () => import('../../screens/BlockChainNetworks/BlockChianNetworksEdit')
)
const BlockChainWallets = lazy(() => import('../../screens/BlockChainWallets/BlockChainWallets'))
const CameraScreen = lazy(() => import('../../screens/Camera'))
const ChangeBackupPassword = lazy(() => import('../../screens/ChangeBackupPassword'))
// const CoinNavigation = lazy(() => import('../../screens/CoinNavigation'))
const AddQuestion = lazy(
  () =>
    import(
      '../../screens/Communities/components/AddModals/modal-components/modal-questions-components/AddQuestion'
    )
)
const Questions = lazy(
  () =>
    import(
      '../../screens/Communities/components/AddModals/modal-components/modal-questions-components/Questions'
    )
)
const AddSketch = lazy(
  () =>
    import(
      '../../screens/Communities/components/AddModals/modal-components/modal-sketches-components/AddSketch'
    )
)
const Sketches = lazy(
  () =>
    import(
      '../../screens/Communities/components/AddModals/modal-components/modal-sketches-components/Sketches'
    )
)
const RoomNewMessage = lazy(() => import('../../screens/Communities/screens/AddMessageModal'))
const RoomMembers = lazy(() => import('../../screens/Communities/screens/Members'))
const RoomReply = lazy(() => import('../../screens/Communities/screens/Reply'))
const RoomView = lazy(() => import('../../screens/Communities/screens/Room'))
const RoomThread = lazy(() => import('../../screens/Communities/screens/Thread'))
const SyncContainer = lazy(() => import('../../screens/Communities/SyncContainer'))
const DeleteFromCloud = lazy(() => import('../../screens/DeleteFromCloud'))
const DmScreen = lazy(() => import('../../screens/DmScreen/DmScreen'))
const DropNFT = lazy(() => import('../../screens/DropNFT'))
const EditAddressName = lazy(() => import('../../screens/EditAddressName'))
const EditProfile = lazy(() => import('../../screens/EditProfile'))
const FindToken = lazy(() => import('../../screens/FindToken/FindToken'))
const FollowList = lazy(() => import('../../screens/FollowList'))
const FungibleTokenConfirmation = lazy(() => import('../../screens/FungibleTokens/confirmation'))
const FungibleTokenDetail = lazy(() => import('../../screens/FungibleTokens/detail'))
const FungibleTokenRecipient = lazy(() => import('../../screens/FungibleTokens/recipient'))
const FungibleTokenTransfer = lazy(() => import('../../screens/FungibleTokens/transfer'))
const ImportWalletRecover = lazy(() => import('../../screens/ImportWallet/screen/recover'))
const ImportWalletStart = lazy(() => import('../../screens/ImportWallet/screen/start'))
const InvitePeople = lazy(() => import('../../screens/InvitePeople'))
const LogoutCallback = lazy(() => import('../../screens/LogoutCallback'))
const ManageAccounts = lazy(() => import('../../screens/ManageAccounts'))
const MapAR = lazy(() => import('../../screens/MapAR'))
const MintNFT = lazy(() => import('../../screens/MintNFT'))
const MintSuccess = lazy(() => import('../../screens/MintSuccess'))
const NewWalletBackup = lazy(() => import('../../screens/NewWallet/screens/backup'))
const NewWalletConfirm = lazy(() => import('../../screens/NewWallet/screens/confirm'))
const NewWalletStart = lazy(() => import('../../screens/NewWallet/screens/start'))
const NFTDetail = lazy(() => import('../../screens/NFTDetail/index.web'))
const ProfileOtherUser = lazy(() => import('../../screens/ProfileOtherUser'))
const ProfileUser = lazy(() => import('../../screens/ProfileUser'))
const CloudBackupList = lazy(() => import('../../screens/RestoreFromCloud/screens/list'))
const RestoreFromCloud = lazy(() => import('../../screens/RestoreFromCloud/screens/password'))
const SellNFT = lazy(() => import('../../screens/SellNFT'))
const SendConfirmation = lazy(() => import('../../screens/SendConfirmation/index.web'))
const SendNFT = lazy(() => import('../../screens/SendNFT'))
const SendNFTConfirmation = lazy(() => import('../../screens/SendNFTConfirmation'))
const SendNFTShare = lazy(() => import('../../screens/SendNFTShare'))
const SendToChainConfirmation = lazy(
  () => import('../../screens/SendToChainConfirmation/SendToChainConfirmation')
)
const Settings = lazy(() => import('../../screens/Settings/Settings'))
const SettingsMetamask = lazy(() => import('../../screens/SettingsMetamask'))
const ShowPrivateKey = lazy(() => import('../../screens/ShowPrivateKey'))
const Space = lazy(() => import('../../screens/Space'))
const TokenAR = lazy(() => import('../../screens/TokenAR'))
const WalletAddressManagement = lazy(() => import('../../screens/WalletAddressManagement'))
const DebugPanel = lazy(() => import('../../utils/DebugPanel'))

const Stack = createNativeStackNavigator<AppStackParamList>()

const isWeb = Platform.OS === 'web'

const modalListeners = {
  focus: () => {
    isWeb && removeBodyScroll().add()
  },
  blur: () => {
    isWeb && removeBodyScroll().remove()
  },
  beforeRemove: () => {
    isWeb && removeBodyScroll().remove()
  }
}

export const AppStack = () => {
  const renderCount = useRef(0)
  const { isDark } = useBusinessTheme()
  const showPanel = useDebugMode()

  renderCount.current++
  // always put the login screen first on the stack even when restoring initialState
  const isAuthed = useIsAuth()

  console.info('[AppStack] renderCount:', renderCount)
  console.log('Appstack isAuthed', isAuthed)
  return (
    <Suspense fallback={null}>
      <LayoutScrollHandlerProvider>
        {/* <Subscriptions />
        <SpaceMonitor />*/}
        <CustomFavicon />
        <IdentityCheckOTP />

        <Suspense fallback={null}>
          <Stack.Navigator
            screenOptions={{
              headerShown: false,
              gestureEnabled: false
            }}
          >
            {!isAuthed && (
              <>
                <Stack.Screen name={AppRoutes.connect} component={Connect} />
                {/* <Stack.Screen name={AppRoutes.businessConnect} component={Connect} /> */}
                {/* LoginCallback */}
                <Stack.Screen name={AppRoutes.LoginCallback} component={LoginCallback} />
              </>
            )}
            {isAuthed && (
              <>
                <Stack.Screen
                  name={AppRoutes.home}
                  component={Home}
                  // component={WalletNavigator}
                  options={{ gestureEnabled: false }}
                />
                <Stack.Screen
                  name={AppRoutes.BusinessProxy}
                  component={Home}
                  getId={({ params }) => {
                    return params?.business
                  }}
                />
                {/* NFT DETAILS  */}
                <Stack.Group
                  screenOptions={{
                    gestureDirection: 'horizontal',
                    animation: 'slide_from_bottom'
                  }}
                >
                  <Stack.Screen
                    name={AppRoutes.NFTDetail}
                    component={NFTDetail}
                    listeners={modalListeners}
                  />
                  <Stack.Screen
                    name={AppRoutes.NFTDetail_Business}
                    component={NFTDetail}
                    getId={({ params }) => `${params.business}-${params.tokenId}`}
                    listeners={modalListeners}
                  />
                  <Stack.Screen name={AppRoutes.SendNFT} component={SendNFT} />
                  <Stack.Screen
                    name={AppRoutes.SendNFT_Business}
                    component={SendNFT}
                    getId={({ params }) => `${params.business}-${params.tokenId}`}
                  />
                  <Stack.Screen name={AppRoutes.SendNFTShare} component={SendNFTShare} />
                  <Stack.Screen
                    name={AppRoutes.SendNFTShare_Business}
                    component={SendNFTShare}
                    getId={({ params }) => `${params.business}-${params.tokenId}`}
                  />
                  <Stack.Screen name={AppRoutes.DropNFT} component={DropNFT} />
                  <Stack.Screen
                    name={AppRoutes.DropNFT_Business}
                    component={DropNFT}
                    getId={({ params }) => `${params.business}-${params.tokenId}`}
                  />
                  <Stack.Screen name={AppRoutes.SellNFT} component={SellNFT} />
                  <Stack.Screen
                    name={AppRoutes.SellNFT_Business}
                    component={SellNFT}
                    getId={({ params }) => `${params.business}-${params.tokenId}`}
                  />
                  <Stack.Screen name={AppRoutes.MintNFT} component={MintNFT} />
                  <Stack.Screen
                    name={AppRoutes.MintNFT_Business}
                    component={MintNFT}
                    getId={({ params }) => `${params.business}-${params.tokenId}`}
                  />
                </Stack.Group>
                <Stack.Screen name={AppRoutes.FindToken} component={FindToken} />
                <Stack.Screen name={AppRoutes.FindToken_Business} component={FindToken} />
                <Stack.Screen
                  name={AppRoutes.sendConfirmation}
                  component={SendConfirmation}
                  options={{
                    presentation: 'transparentModal'
                  }}
                />
                <Stack.Screen name={AppRoutes.editProfile} component={EditProfile} />
                <Stack.Screen name={AppRoutes.manageAccounts} component={ManageAccounts} />
                <Stack.Screen name={AppRoutes.settingsMetamask} component={SettingsMetamask} />
                <Stack.Screen name={AppRoutes.invitePeople} component={InvitePeople} />
                <Stack.Screen name={AppRoutes.MintSuccess} component={MintSuccess} />
                <Stack.Screen name={AppRoutes.BackupToCloud} component={BackupToCloud} />
                <Stack.Screen name={AppRoutes.RestoreFromCloud} component={RestoreFromCloud} />
                <Stack.Screen name={AppRoutes.DeleteFromCloud} component={DeleteFromCloud} />
                <Stack.Screen
                  name={AppRoutes.ChangeBackupPassword}
                  component={ChangeBackupPassword}
                />
                <Stack.Screen name={AppRoutes.CloudBackupList} component={CloudBackupList} />
                <Stack.Screen name={AppRoutes.NewWalletStart} component={NewWalletStart} />
                <Stack.Screen name={AppRoutes.NewWalletBackup} component={NewWalletBackup} />
                <Stack.Screen name={AppRoutes.NewWalletConfirm} component={NewWalletConfirm} />
                <Stack.Screen name={AppRoutes.ImportWalletStart} component={ImportWalletStart} />
                <Stack.Screen
                  name={AppRoutes.ImportWalletRecover}
                  component={ImportWalletRecover}
                />
                <Stack.Screen
                  name={AppRoutes.WalletAddressManagement}
                  component={WalletAddressManagement}
                />
                <Stack.Screen name={AppRoutes.ShowPrivateKey} component={ShowPrivateKey} />
                <Stack.Screen name={AppRoutes.EditAddressName} component={EditAddressName} />
                <Stack.Screen
                  name={AppRoutes.sendToChainConfirmation}
                  component={SendToChainConfirmation}
                />
                <Stack.Group
                  screenOptions={{
                    presentation: 'modal'
                  }}
                >
                  <Stack.Screen
                    name={AppRoutes.MapAR}
                    component={MapAR}
                    options={{}}
                    listeners={modalListeners}
                  />
                  <Stack.Screen
                    name={AppRoutes.TokenAR}
                    component={TokenAR}
                    options={{}}
                    listeners={modalListeners}
                  />
                  <Stack.Screen
                    name={AppRoutes.CameraScreen}
                    component={CameraScreen}
                    options={{}}
                    listeners={modalListeners}
                  />
                </Stack.Group>
                <Stack.Screen
                  name={AppRoutes.profileUser}
                  component={ProfileUser}
                  // options={{
                  //   contentStyle: {
                  //     backgroundColor: isDark
                  //       ? theme.colors.grayCool[900]
                  //       : theme.colors.grayCool[50],
                  //     height: '100%'
                  //   }
                  // }}
                />
                <Stack.Screen name={AppRoutes.Metaverse} component={Space} />
                <Stack.Screen name={AppRoutes.followList} component={FollowList} />
                <Stack.Screen name={AppRoutes.profileOtherUser} component={ProfileOtherUser} />
                <Stack.Screen
                  name={AppRoutes.profileOtherUser_Business}
                  component={ProfileOtherUser}
                />
                <Stack.Screen
                  name={AppRoutes.NftFilters}
                  component={NftFiltersModal}
                  options={{
                    presentation: 'transparentModal',
                    animation: 'fade'
                  }}
                  listeners={modalListeners}
                />
              </>
            )}
            {/* PUBLIC SCREENS */}
            <Stack.Screen name={AppRoutes.LogoutCallback} component={LogoutCallback} />
            <Stack.Group navigationKey={isAuthed ? 'user' : 'guest'}>
              <Stack.Screen name={AppRoutes.Room} component={RoomView} />
              <Stack.Screen name={AppRoutes.RoomMembers} component={RoomMembers} />
              <Stack.Screen name={AppRoutes.RoomThread} component={RoomThread} />
              <Stack.Screen name={AppRoutes.CommunitiesRoom} component={RoomView} />
              <Stack.Screen name={AppRoutes.CommunitiesRoomMembers} component={RoomMembers} />
              <Stack.Screen name={AppRoutes.CommunitiesRoomThread} component={RoomThread} />
              <Stack.Group
                navigationKey={isAuthed ? 'user' : 'guest'}
                screenOptions={{ presentation: 'fullScreenModal' }}
              >
                <Stack.Screen name={AppRoutes.RoomNewMessage} component={RoomNewMessage} />
                <Stack.Screen
                  name={AppRoutes.CommunitiesRoomNewMessage}
                  component={RoomNewMessage}
                />

                <Stack.Screen name={AppRoutes.RoomReply} component={RoomReply} />
                <Stack.Screen name={AppRoutes.CommunitiesRoomReply} component={RoomReply} />

                <Stack.Screen name={AppRoutes.RoomNewQuestion} component={AddQuestion} />
                <Stack.Screen name={AppRoutes.CommunitiesRoomNewQuestion} component={AddQuestion} />

                <Stack.Screen name={AppRoutes.RoomSketchList} component={Sketches} />
                <Stack.Screen name={AppRoutes.CommunitiesRoomSketchList} component={Sketches} />

                <Stack.Screen name={AppRoutes.RoomNewSketch} component={AddSketch} />
                <Stack.Screen name={AppRoutes.CommunitiesRoomNewSketch} component={AddSketch} />

                <Stack.Screen name={AppRoutes.RoomQuestionList} component={Questions} />
                <Stack.Screen name={AppRoutes.CommunitiesRoomQuestionList} component={Questions} />
              </Stack.Group>
              <Stack.Screen name={AppRoutes.AcquirePubVariation} component={AcquirePubVariation} />
              <Stack.Screen name={AppRoutes.AcquirePubVariation1} component={AcquirePubVariation} />
              <Stack.Screen name={AppRoutes.AcquirePubVariation2} component={AcquirePubVariation} />
              <Stack.Screen name={AppRoutes.Acquire} component={Acquire} />
              <Stack.Screen name={AppRoutes.Acquire_Business} component={Acquire} />
            </Stack.Group>

            {/** 🔥 Your screens go here */}
            <Stack.Screen
              name={AppRoutes.DmScreen}
              component={DmScreen}
              options={{
                gestureEnabled: true
              }}
            />
            <Stack.Screen name={AppRoutes.Settings} component={Settings} options={{}} />
            <Stack.Screen
              name={AppRoutes.BlockChainWallets}
              component={BlockChainWallets}
              options={{}}
            />
            <Stack.Screen
              name={AppRoutes.BlockChainNetwork}
              component={BlockchainNetwork}
              options={{}}
            />
            <Stack.Screen
              name={AppRoutes.BlockChainNetworkAdd}
              component={BlockChainNetworkAdd}
              options={{}}
            />
            <Stack.Screen
              name={AppRoutes.BlockChainNetworkEdit}
              component={BlockChainNetworkEdit}
              options={{}}
            />
            <Stack.Screen
              name={AppRoutes.FungibleTokenDetail}
              component={FungibleTokenDetail}
              options={{}}
            />
            <Stack.Screen
              name={AppRoutes.FungibleTokenTransfer}
              component={FungibleTokenTransfer}
              options={{}}
            />
            <Stack.Screen
              name={AppRoutes.FungibleTokenRecipient}
              component={FungibleTokenRecipient}
              options={{}}
            />
            <Stack.Screen
              name={AppRoutes.FungibleTokenConfirmation}
              component={FungibleTokenConfirmation}
              options={{}}
            />
            <Stack.Screen
              name={AppRoutes.sendNFTConfirmation}
              component={SendNFTConfirmation}
              // had to remove this cause scrolling is not working
              // listeners={modalListeners}
            />
          </Stack.Navigator>
        </Suspense>
        {isAuthed ? (
          <>
            {/* <ModalReedem /> */}
            <RewardsCard />
            {showPanel && <DebugPanel />}
          </>
        ) : null}
      </LayoutScrollHandlerProvider>
    </Suspense>
  )
}

function arePhoneNumbersEqual(a: string, b: string) {
  const phone1 = parsePhoneNumberFromString(a)
  const phone2 = parsePhoneNumberFromString(b)
  return phone1 && phone2 && phone1.isEqual(phone2)
}

function shouldAutoMergeAccounts(
  otpParams: ReturnType<typeof useInitialRouteResolver>['otpParams'],
  user?: UserModel
) {
  if (!user || !otpParams?.identityType || !otpParams?.identityValue) {
    return false
  }

  if (otpParams.identityType === 'phoneNumber') {
    const identityValue = otpParams.identityValue?.startsWith('+')
      ? otpParams.identityValue.trim()
      : `+${otpParams.identityValue}`.trim()
    if (user?.phone_number_verified && arePhoneNumbersEqual(identityValue, user?.phone_number))
      return true
  }

  if (otpParams.identityType === 'email') {
    const identityValue = otpParams.identityValue.trim()
    if (user?.email_verified && user?.email === identityValue) {
      return true
    }
  }

  return false
}

// TODO: move to a different file
const IdentityCheckOTP = () => {
  const config = useConfig()
  const isAuth = useIsAuth()
  const { data: user } = useUser()
  const signOutMutation = useSignOutMutation()

  const maxWidth = useMaxWidth()
  const [isOpen, setIsOpen] = useState(true)

  const { initialUrl } = useInitialQsStore.getState()

  const { otpParams } = useInitialRouteResolver({
    initialUrl: initialUrl ? new URL(initialUrl) : null,
    config
  })

  // Prevent scroll under the modal
  usePreventScroll({
    isDisabled: otpParams === undefined
  })

  const onPressContinue = useThrottledCallback(async () => {
    //
    console.log('Identity continue press')
    // Logout and connect with new otp
    signOutMutation.mutate()
  }, 250)

  const onPressCancel = useThrottledCallback(() => {
    //
    console.log('Identity cancel press')
    setIsOpen(false)
    // TODO: clean otp data ??
  }, 250)

  const mergeAccountMutation = useMutation({
    mutationFn: async () => {
      if (!otpParams) {
        throw new Error('mergeAccount missing otpParams')
      }
      const currentSession = getSession('vatom')?.value
      if (!currentSession) {
        throw new Error('mergeAccount missing currentSession')
      }

      const authSession = await loginWithOtp(otpParams, { authConfig: config.authentication })

      if (!authSession.access_token) {
        // Not a valid session
        return null // or throw?
      }

      await axios.post(
        '/users/merge',
        {
          sourceAccessToken: authSession.access_token,
          targetAccessToken: currentSession.accessToken
        },
        { baseURL: config.api.oidc }
      )
    }
  })

  // If is not authed check for otp
  if (!isAuth) {
    // If a user is not authed tries to access an authed screen it will land on the Connect screen. Then if the user has an otp code should sign in with it
    return null
  }

  if (isAuth && !otpParams) {
    return null
  }

  // check if accounts are from the same user
  // TODO: implement
  if (shouldAutoMergeAccounts(otpParams, user)) {
    console.log('LOG: > IdentityCheckOTP > shouldAutoMergeAccounts:', otpParams, user)
    // mergeAccountMutation.mutate()
    // return null
  }

  if (mergeAccountMutation.isLoading && !mergeAccountMutation.isError) {
    return null
  }

  const loggedInAs = user?.email ? user?.email : user?.phone_number
  // const message = `You're logged in as ${loggedInAs} but this Smart NFT was sent to ${otpParams?.identityValue}. Would you like to consolidate these accounts?`
  const message = `You're logged in as ${loggedInAs} but this Smart NFT was sent to ${otpParams?.identityValue}. Would you like continue with this accounts?`

  // Continue > (logout and signin with saved otp values)
  // Cancel > close this

  return (
    <Modal visible={isOpen} transparent>
      <Box flexGrow={1}>
        <Box
          maxWidth={maxWidth}
          margin={'auto'}
          alignItems={'center'}
          justifyContent={'center'}
          _light={{
            backgroundColor: convertToHexAlpha(theme.colors.grayCool[900], 0.9)
          }}
          _dark={{
            backgroundColor: convertToHexAlpha(theme.colors.grayDarkMode[900], 0.9)
          }}
          borderRadius={6}
          padding={8}
        >
          <Title preset="h1">Welcome</Title>
          <Box>
            <VStack w="100%" paddingX={4} marginTop={4}>
              <Text textAlign="center">{message}</Text>
            </VStack>

            <Box paddingX={12} marginTop={4}>
              <ProcessButton
                label={translate('common.continue')}
                isDisabled={signOutMutation.isLoading}
                onPress={onPressContinue}
                isLoading={signOutMutation.isLoading}
              />
            </Box>
            <Box paddingX={12} marginTop={4}>
              <Pressable
                accessibilityRole="button"
                colorScheme="gray"
                w="100%"
                justifyContent="center"
                onPress={onPressCancel}
                // disabled={}
                rounded={'sm'}
                p={2}
                _light={{
                  backgroundColor: theme.colors.grayCool[600]
                }}
                _dark={{
                  backgroundColor: theme.colors.grayDarkMode[700]
                }}
              >
                <TextTranslate
                  color="white"
                  fontFamily="Inter"
                  fontWeight="600"
                  alignSelf="center"
                  py={2}
                  fontSize="18px"
                  tx="common.cancel"
                />
              </Pressable>
            </Box>
          </Box>
        </Box>
        <BlurView
          // intensity={0.2}
          tint="dark"
          style={{
            position: 'absolute',
            zIndex: -1,
            flexGrow: 1,
            width: '100%',
            height: '100%'
          }}
        />
      </Box>
    </Modal>
  )
}

export const openDeepLink = async () => {
  if (Platform.OS === 'web') {
    const initialUrl = await Linking.getInitialURL()
    logger.info('[openDeepLink] initialUrl', initialUrl)
    logger.info('[openDeepLink] window.location.href', window.location.href)
    return initialUrl
  }
  try {
    const initialUrl = await Linking.getInitialURL()

    if (initialUrl) {
      const urlObject = new URL(initialUrl)

      // for some reason ios opens a browser if the scheme is https
      const deeplink =
        Platform.OS === 'ios'
          ? `${urlObject.hostname}:/${urlObject.pathname}?${urlObject.searchParams}`
          : initialUrl
      const canOpen = await Linking.canOpenURL(deeplink)
      if (canOpen) {
        // await Linking.openURL(deeplink)
      } else {
        logger.error('Cannot open url: ', deeplink, initialUrl)
      }
    }

    return initialUrl
  } catch (error) {
    logger.error('Error opening deep link', error)
  }
  return null
}

// const Subscriptions = () => {
//   useBroadcastSession()

//   const isAuthed = useIsAuth()
//   // useMatrixFullStateSync({
//   //   enabled: !!isAuthed,
//   //   refetchOnWindowFocus: false,
//   //   refetchOnMount: false
//   // })

//   // useSDKHandlers()
//   useBrowserCompatibilityWarning()
//   useForceUpdate()
//   // useNavigationPending()
//   // console.info('[Subscriptions] isAuthed:', isAuthed)

//   const locale = useLocales()

//   const config = useConfig()

//   i18n.locale = config?.features.language ?? locale?.[0]?.languageCode ?? 'en'

//   // return <SyncContainer />
//   return null
// }
