import { z } from 'zod'

export enum SellChannel {
  Marketplace = 'marketplace',
  Direct = 'direct',
  None = 'none'
}

export enum ListingStatus {
  Unlisted = 'unlisted',
  Active = 'active',
  Pending = 'pending',
  Failed = 'failed',
  Unknown = ''
}

export const ActionPayloadSchema = z.object({
  name: z.string(),
  properties: z.object({
    policy: z
      .object({
        pre: z.any()
      })
      .nullish(),
    config: z
      .object({
        unlock_key: z.string().nullish(),
        trash_key: z.boolean().nullish()
      })
      .nullish()
  }),
  meta: z.any(),
  template: z.string().nullish()
})
export type ActionPayload = z.infer<typeof ActionPayloadSchema>

export const AnimationRuleSchema = z.object({
  on: z.string(),
  play: z.string(),
  target: z.string().nullish(),
  value: z.string().nullish()
})

export const PolicySchema = z.object({
  templateVariation: z.string()
})

export const StudioInfoSchema = z.object({
  businessId: z.string(),
  blueprintId: z.string().default('').optional(),
  campaignId: z.string(),
  distributionId: z.string().default('').optional(),
  objectDefinitionId: z.string()
})

export const ImagePolicySchema = z.object({
  count_max: z.number().optional(),
  field: z.string().optional(),
  value: z.union([z.string(), z.number(), z.boolean()]).optional(),
  resource: z.string().optional()
})
// export type ImagePolicy = z.infer<typeof ImagePolicySchema>

export const ListingStatusSchema = z.nativeEnum(ListingStatus)

export const SellChannelSchema = z.nativeEnum(SellChannel)

export const CommerceInfoSchema = z.object({
  currency: z.string(),
  status: ListingStatusSchema.optional(),
  channel: SellChannelSchema.optional(),
  price: z.union([z.number(), z.string()]),
  error: z.string().optional(),
  checkoutUrl: z.string().optional(),
  productUrl: z.string().optional(),
  saleDate: z.string().optional()
})

const SimpleResourceSchema = z.object({
  name: z.string(),
  value: z.string()
})

export const VatomResourceSchema = z.object({
  name: z.string(),
  resourceType: z.string(),
  value: z.object({
    value: z.string()
  })
})

export const ActivitySchema = z.object({
  date: z.string(),
  name: z.string(),
  price: z.string(),
  initiatorId: z.string(),
  recipientId: z.string()
})

export const PositionSchema = z.object({
  type: z.literal('Point'),
  coordinates: z.array(z.number())
})

export const ARTransformSchema = z.object({
  scale: z.object({
    x: z.number(),
    y: z.number(),
    z: z.number()
  }),
  translate: z.object({
    x: z.number(),
    y: z.number(),
    z: z.number()
  }),
  rotate: z.object({
    x: z.number(),
    y: z.number(),
    z: z.number()
  })
})

export const ResourceSchema = z.object({
  name: z.string(),
  url: z.string().optional(),
  type: z.string(),
  ar_transform: ARTransformSchema.optional(),
  animation_rules: z.any().optional(),
  auto_rotate: z.boolean().optional()
})

export const eRC721ContractMetadataSchema = z.object({
  name: z.string(),
  description: z.string(),
  image: z.string(),
  external_link: z.string(),
  seller_fee_basis_points: z.number(),
  fee_recipient: z.string()
})

export const FacePayloadSchema = z.object({
  id: z.string(),
  properties: z.object({
    config: z.any().nullish(),
    // config: z
    //   .object({
    //     image: z.string().nullish(),
    //     scale: z.string().nullish(),
    //     image_mode: z.string().nullish(),
    //     image_policy: z.array(ImagePolicySchema).nullish(),
    //     layerImage: z.string().nullish(),
    //     empty_image: z.string().nullish(),
    //     full_image: z.string().nullish(),
    //     padding_start: z.number().nullish(),
    //     padding_end: z.number().nullish(),
    //     direction: z.string().nullish(),
    //     cover: z.string().nullish(),
    //     video: z.union([
    //       z.string(),
    //       z
    //         .object({
    //           ref: z.string(),
    //           type: z.string()
    //         })
    //         .nullish()
    //     ]),
    //     unlock_key: z.string().nullish(),
    //     activate_action: z.string().nullish(),
    //     scene: z.string().nullish(),
    //     animation_rules: z.array(AnimationRuleSchema).nullish(),
    //     ar_transform: ARTransformSchema.nullish(),
    //     auto_rotate: z.boolean().nullish(),
    //     bgColor: z
    //       .object({
    //         r: z.number(),
    //         g: z.number(),
    //         b: z.number(),
    //         a: z.number()
    //       })
    //       .nullish()
    //   })
    //   .nullish(),
    constraints: z
      .object({
        platform: z.string(),
        view_mode: z.string()
      })
      .optional(),
    resources: z.array(z.string()).optional(),
    display_url: z.string()
  }),
  meta: z.any(),
  template: z.string()
})
export type FacePayload = z.infer<typeof FacePayloadSchema>

export const ChangeSchema = z.object({
  operation: z.union([z.literal('create'), z.literal('update'), z.literal('delete')]),
  template: z.string(),
  id: z.string(),
  data: z.union([FacePayloadSchema, ActionPayloadSchema])
})

const GameOfChance = z.object({
  lastSentPrizeAt: z.string().nullish(),
  maxPlaysPerPeriod: z.number().nullish(),
  playPeriod: z.string().nullish(),
  playPeriodTimezone: z.string().nullish(),
  prizeDescription: z.string().nullish(),
  prizeIcon: z.string().nullish(),
  prizeName: z.string().nullish(),
  prizeToken: z.string().nullish(),
  singleUse: z.boolean().nullish(),
  prizeExchange: z.boolean().nullish(),
  playCount: z.number().nullish(),
  prizeSet: z
    .array(
      z.object({
        prizeDescription: z.string().nullish(),
        prizeIcon: z.string().nullish(),
        prizeName: z.string().nullish()
      })
    )
    .nullish()
})

export const PrivateDataSchema = z.object({
  behaviors: z.string().array().optional(),
  'studio-info-v1': StudioInfoSchema,
  'commerce-v1': CommerceInfoSchema.nullish(),
  'numbered-v1': z
    .object({
      name: z.string().optional(),
      number: z.number()
    })
    .nullish(),
  'scarcity-v1': z
    .object({
      maxObjects: z.number()
    })
    .nullish(),
  'lifecycle-v1': z
    .object({
      initialized: z.boolean(),
      supportsRefresh: z.boolean(),
      initializedAt: z.string().datetime().nullish()
    })
    .nullish(),
  'share-v1': z
    .object({
      scores: z.object({
        clicks: z.number(),
        engagements: z.number(),
        influencers: z.number(),
        networkSpend: z.number(),
        shares: z.number(),
        spend: z.number()
      }),
      shareTargetUri: z.union([z.literal('varius.object-definition:varius.io:this'), z.string()]),
      shareTargetUrl: z.string(),
      shareUrl: z.string()
    })
    .nullish(),
  'cloneable-v1': z
    .object({
      cloningScore: z.number().nullish(),
      numDirectClones: z.number().nullish()
      // cloneScore: z.number().nullish(),
      // numDirectClones: z.number().nullish()
    })
    .nullish(),
  'mutable-v1': z
    .object({
      title: z.string().optional(),
      description: z.string().optional(),
      category: z.string().optional(),
      resources: z.array(ResourceSchema).optional()
    })
    .nullish(),
  'royalties-v1': z
    .object({
      artist: z.number()
    })
    .nullish(),

  'redeemable-v1': z
    .object({
      count: z.number().nullish(),
      totalQuantity: z.number().nullish(),
      merchants: z
        .array(
          z.object({
            id: z.string(),
            provider: z.string()
          })
        )
        .nullish()
    })
    .nullish(),

  'game-of-chance-v1': GameOfChance.nullish(),
  'user-points-v2': z.any().nullish(),
  'simple-ar-game-v1': z.any().nullish(),
  ar_scale: z.any().nullish(),
  ar_anchor_id: z.any().nullish(),
  campaign: z
    .object({
      id: z.string()
    })
    .nullish(),
  'meta-v1': z.any().nullish(),
  silent_redeem: z.boolean().nullish(),
  'ar-filter-v2': z.any().nullish(),
  'form-v1': z.any().nullish(),
  'quiz-v2': z.any().nullish(),
  'poll-v1': z.any().nullish(),
  'coupon-v1': z.any().nullish(),
  //
  'honda-reward-v1': z
    .object({
      redeemed: z.boolean().nullish(),
      redeemedAt: z.string().nullish(),
      expiration_date: z.string().nullish()
    })
    .nullish(),

  //
  state: z
    .object({
      'varius.behavior:varius.io:studio-info-v1': z.object({
        businessId: z.string(),
        blueprintUri: z.string(),
        campaignUri: z.string(),
        objectDefinitionUri: z.string()
      })
    })
    .nullish(),
  analytics_data: z
    .object({
      objectDefinitionId: z.string().optional(),
      businessId: z.string().nullish(),
      blueprintId: z.string().nullish(),
      campaignId: z.string().nullish()
    })
    .nullish(),
  //
  image_policy: z.array(ImagePolicySchema).optional(),
  padding_start: z.number().optional(),
  padding_end: z.number().optional(),
  direction: z.string().optional(),
  unlock_key: z.string().optional(),
  resources: z.array(SimpleResourceSchema).optional()
})
export type PrivateData = z.infer<typeof PrivateDataSchema>

export const VatomVatomTypeSchema = z.object({
  title: z.string(),
  parent_id: z.string(),
  owner: z.string(),
  author: z.string(),
  transferred_by: z.string(),
  description: z.string(),
  category: z.string(),
  publisher_fqdn: z.string(),
  root_type: z.string(),
  child_policy: z
    .object({
      template_variation: z.string(),
      count: z.number(),
      creation_policy: z.object({
        auto_create: z.string(),
        auto_create_count: z.number(),
        auto_create_count_random: z.boolean(),
        enforce_policy_count_max: z.boolean(),
        enforce_policy_count_min: z.boolean(),
        policy_count_max: z.number(),
        policy_count_min: z.number(),
        weighted_choices: z.array(z.unknown())
      })
    })
    .array()
    .nullish(),
  template: z.string(),
  template_variation: z.string(),
  resources: z.array(VatomResourceSchema),
  cloning_score: z.number().optional(),
  padding_start: z.number().optional(),
  icon_stages: z.array(ImagePolicySchema).optional(),
  image_mode: z.string().optional(),
  activate_action: z.string().optional(),
  num_direct_clones: z.number().optional(),
  dropped: z.boolean(),
  geo_pos: PositionSchema.optional(),
  animation_rules: z.array(z.any()).nullable().optional(),
  is_map_dispenser: z.boolean().nullable().optional()
})

export const BlockVResponsePayloadSchema = z.object({
  id: z.string().brand('BlockVTokenId'),
  private: PrivateDataSchema,
  unpublished: z.boolean().optional(),
  version: z.string(),
  sync: z.number(),
  quantity: z.number().default(1).optional(),
  contractType: z.string().optional(),
  // createdAt: z.string().datetime(),
  when_created: z.string().datetime(),
  when_modified: z.string().datetime().optional(),
  'vAtom::vAtomType': VatomVatomTypeSchema,
  eth: z
    .object({
      network: z.string(),
      contract: z.string().nullish(),
      emitted: z.boolean().nullish()
    })
    .optional(),
  chain: z
    .object({
      network: z.string(),
      contract: z.string().nullish(),
      tx_id: z.string().nullish(),
      token_id: z.string().nullish(),
      minted: z.boolean().nullish()
    })
    .optional()
})
export type BlockVResponsePayload = z.infer<typeof BlockVResponsePayloadSchema>

export const eRC721MetadataSchema = z.object({
  name: z.string(),
  description: z.string(),
  image: z.string().optional(),
  external_uri: z.string().optional(),
  attributes: z.tuple([
    z.object({
      trait_type: z.string(),
      value: z.union([z.number(), z.string()])
    })
  ]),
  background_color: z.string().optional(),
  animation_url: z.string().optional(),
  youtube_url: z.string().optional(),
  contract_data: eRC721ContractMetadataSchema.optional()
})

export const BlockVResponseModel = BlockVResponsePayloadSchema.extend({
  faces: z.array(FacePayloadSchema).optional(),
  actions: z.array(ActionPayloadSchema).optional()
})
export type BlockVResponse = z.infer<typeof BlockVResponseModel>
