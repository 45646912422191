import React from 'react'
import { useSignIn } from '@vatom/sdk/auth'
import { useAppConfig } from '@vatom/sdk/react'
import { useWalletIsEmbedded } from '@vatom/wallet-sdk'
import { Toast } from '@vatom/wombo'
import { Flex } from 'native-base'

import { ThemedLoaderView } from '../../components/ThemedLoader'

import { BottomHalf, TopHalf } from './partials'

const ConnectScreen = () => {
  const { signInMutation, shouldSignIn } = useSignIn({
    onError: (err, _, context) => {
      // context?.otpParams
      console.error('ConnectScreen.signIn:', err)
      Toast({
        title: 'Authentication Failed',
        status: 'error'
      })
    }
  })

  if ((signInMutation.isLoading || shouldSignIn) && !signInMutation.isError) {
    return <ThemedLoaderView />
  }

  return (
    <Flex
      testID="LoginScreen"
      flexDirection="column"
      flex={1}
      bg="white"
      style={{ alignContent: 'center', justifyContent: 'center' }}
    >
      {/* <StatusBar barStyle={tab.style} backgroundColor={tab.back} /> */}
      <TopHalf />
      <BottomHalf onPressVatom={signInMutation.mutateAsync} />
    </Flex>
  )
}

export const Connect = () => {
  const config = useAppConfig()

  if (config.isLoading) {
    return <ThemedLoaderView />
  }

  return <ConnectScreen />
}

export default Connect
