import { FixedLengthArray } from '@vatom/utils'
import axios from 'axios'
import { z } from 'zod'

import { AnalyticsPersistenceManager } from './lib/persistenceManager'

const EventCache = new FixedLengthArray(200)

const analyticsEndPoint = `https://analytics.api.vatominc.com/t`

const AnyTokenForAnalytics = z.object({
  id: z.string(),
  studioInfo: z
    .object({
      businessId: z.string(),
      blueprintId: z.string().nullish(),
      campaignId: z.string(),
      objectDefinitionId: z.string()
    })
    .optional()
})
type AnyTokenForAnalytics = z.infer<typeof AnyTokenForAnalytics>

// //

async function track(name: string, args: Record<string, unknown>) {
  try {
    const anonymousId = await AnalyticsPersistenceManager.getAnonymousId()
    const context = await AnalyticsPersistenceManager.getContext()
    // Send analytics events via new framework
    const data = {
      anonymousId,
      context,
      event: name,
      properties: {
        event: name, // Not sure if this is needed?
        eventValue: 1, // Not sure if this is needed?
        ...args
      },
      timestamp: new Date().toISOString(),
      type: 'track',
      userId: args['userId'] ?? '' // ?? getUserId()
    }
    const payload = JSON.stringify(data)
    await axios.post(analyticsEndPoint, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  } catch (error) {
    // console.log('LOG: > track > error:', error)
    console.error('Error sending analytics:', error)
  }
}

type EventData = { name: string; args: any; token?: AnyTokenForAnalytics }

async function event(data: EventData, deps: AnalyticsParams) {
  const { name, args: _args, token } = data
  let args = _args ?? {}
  // eslint-disable-next-line prefer-rest-params
  const event = EventCache.find(arguments)
  if (event) return
  // eslint-disable-next-line prefer-rest-params
  EventCache.push(arguments)

  if (!args.userId) {
    args.userId = deps.userId ?? ''
  }

  args.provider = 'vatominc'

  args.networkUserId = deps.userIdBlockV ?? ''
  args.network = 'vatominc'

  args.viewerId = deps.viewerId || (await AnalyticsPersistenceManager.getOrigin())
  args.viewerUri = deps.viewerId
    ? `varius.viewer:varius.io:${deps.viewerId}`
    : await AnalyticsPersistenceManager.getOrigin()

  if (token) args['digitalObjectId'] = token.id

  if (token?.studioInfo) {
    args['vatomId'] = token.id

    const studioInfo = token.studioInfo
    // Get generic analytics data
    const mandatoryStack = {
      ...studioInfo
    }

    args = Object.assign({}, args, mandatoryStack)
  }

  // Get default viewerUri and campaignUri if not specified
  const campaignId = await AnalyticsPersistenceManager.getCampaignId()
  if (!args.campaignId && !args.campaignUri && !!campaignId) {
    args.campaignUri = `varius.campaign:varius.io:${campaignId}`
    args.campaignId = campaignId
  }

  track(name, args)
}

type AnalyticsParams = {
  userId?: string
  viewerId?: string
  userIdBlockV?: string
}
export const Analytics = function (params: AnalyticsParams) {
  // TODO: handle message for wallet-sdk

  // Send event using new framework
  // const isEmbedded = useVatomWalletSdkStore.getState().isEmbedded
  // if (token && isEmbedded) {
  //   VatomWallet._shared.sendMsgWithoutResponse('walletsdk:analytics', {
  //     token: getSnapshot(token),
  //     name: name,
  //     payload: args
  //   })
  // }

  const _event = async (name: string, args: any = {}, token?: AnyTokenForAnalytics) => {
    const data = {
      name,
      args,
      token
    }
    event(data, params)
  }

  return {
    track,
    error: (error: string, extraFields = {}) =>
      _event('exception', {
        ...{ error: error, fatal: false },
        ...extraFields
      }),
    event: _event
  }
}
