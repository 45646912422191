import type { QueryClient } from '@tanstack/react-query'
import { allSettled, isFulfilled, isRejected, SDKQueryClient } from '@vatom/sdk/react'
import type { Network } from 'alchemy-sdk'
import { Alchemy, Utils } from 'alchemy-sdk'
import { AlchemyProvider, Contract } from 'ethers'

import { alchemyQueryKeys } from './keys'
import type {
  AllBalancesCtx,
  AllNftsCtx,
  AssetTransfersCtx,
  BalanceCtx,
  BalancesCtx,
  ContractMetadataCtx,
  GasEstimatesCtx,
  GetNftsCtx,
  NFTMetadataCtx,
  TransactionDataCtx,
  TransactionGasEstimatesCtx
} from './types'

function getConfigForNetwork(network: Network) {
  const apiKey = 'umRvojxdI6ZY4IbRdJqUDgyG4sIghoAo'
  // const apiKey = '1qPmsG4QrGYKBsQGHTkz_5yiZEjvEp0i'
  // const polygonApiKey = 'I5FKB_z-z_BtWL1bIVsbcJv29HdV6NVJ'

  const config = {
    // apiKey: [
    //   Network.MATIC_MAINNET,
    //   Network.POLYGONZKEVM_MAINNET,
    //   Network.POLYGONZKEVM_TESTNET,
    //   Network.MATIC_MUMBAI
    // ].includes(network)
    //   ? polygonApiKey
    //   : apiKey,
    apiKey,

    network: network
  }
  return config
}

export function getAlchemyClient(network: Network) {
  const config = getConfigForNetwork(network)
  return new Alchemy(config)
}

export async function fetchAssetTransfers({ queryKey }: AssetTransfersCtx) {
  const [{ network, options }] = queryKey

  const alchemy = getAlchemyClient(network)
  return await alchemy.core.getAssetTransfers(options)
}

export async function fetchNFTMetadata({ queryKey }: NFTMetadataCtx) {
  const [{ contractAddress, tokenId, network, options }] = queryKey

  const alchemy = getAlchemyClient(network)
  const nftMetadata = await alchemy.nft.getNftMetadata(contractAddress, tokenId, options)

  return nftMetadata
}

export async function fetchTransactionData({ queryKey }: TransactionDataCtx) {
  const [{ network, contractAddress, contractType, fromAddress, toAddress, tokenId, amount }] =
    queryKey
  const alchemy = getAlchemyClient(network)
  const networkProvider = await alchemy.core.getNetwork()
  const finalNetworkProvider = networkProvider.chainId === 1 ? undefined : networkProvider
  const provider = new AlchemyProvider(finalNetworkProvider, alchemy.config.apiKey)

  // ERC-1155 ABI
  // ERC-721 ABI
  const abi =
    contractType === 'erc1155'
      ? [
          'function safeTransferFrom(address from, address to, uint256 id, uint256 amount, bytes data)'
        ]
      : ['function safeTransferFrom(address from, address to, uint256 tokenId)']

  const nftContract = new Contract(contractAddress, abi, provider)

  const data =
    contractType === 'erc1155'
      ? nftContract.interface.encodeFunctionData('safeTransferFrom', [
          fromAddress,
          toAddress,
          tokenId,
          amount,
          '0x'
        ])
      : nftContract.interface.encodeFunctionData('safeTransferFrom', [
          fromAddress,
          toAddress,
          tokenId
        ])

  return data
}

export async function getTransactionGasEstimates({
  contractAddress,
  data,
  fromAddress,
  network
}: {
  contractAddress: string
  data?: string
  fromAddress: string
  network: Network
}) {
  const alchemy = getAlchemyClient(network)
  const gasPrice = await alchemy.core.getGasPrice()

  const gasEstimate = await alchemy.core.estimateGas({
    to: contractAddress,
    from: fromAddress,
    data
  })

  return {
    gasPrice,
    gasEstimate
  }
}

export async function fetchTransactionGasEstimates({ queryKey }: TransactionGasEstimatesCtx) {
  const [{ contractAddress, data, fromAddress, network }] = queryKey

  return getTransactionGasEstimates({ contractAddress, data, fromAddress, network })
}

export async function fetchGasEstimates({ queryKey }: GasEstimatesCtx) {
  const [{ toAddress, amount, network }] = queryKey

  const alchemy = getAlchemyClient(network)
  const gasEstimate = await alchemy.core.estimateGas({
    to: toAddress,
    value: Utils.parseEther(String(amount))
  })

  const fee = await alchemy.core.getFeeData()

  const gasPrice = await alchemy.core.getGasPrice()
  const estimatedTotal = gasEstimate.mul(gasPrice)

  return {
    gasLimit: Number(gasEstimate),
    maxPriorityFeePerGas: Number(fee.maxPriorityFeePerGas),
    maxFeePerGas: Number(fee.maxFeePerGas),
    estimatedTotal
  }
}

export async function fetchTokenBalance({ queryKey }: BalanceCtx) {
  const [{ address, network }] = queryKey

  const alchemy = getAlchemyClient(network)
  const balance = await alchemy.core.getBalance(address)

  if (Number(balance) <= 0) {
    throw new Error('Zero balance')
  }

  return {
    balance,
    address,
    network,
    /* @ts-expect-error */
    symbol: defaultNetworkSymbols[network]
  }
}

export async function fetchTokenBalances({ queryKey }: BalancesCtx) {
  const [{ address, network }] = queryKey

  const alchemy = getAlchemyClient(network)

  const allBalances = await alchemy.core.getTokenBalances(address)

  const nonZeroBalances = allBalances.tokenBalances.filter(token => {
    return Number(token.tokenBalance ?? 0) !== 0
  })

  return nonZeroBalances
}

export function fetchContractMetadata({ queryKey }: ContractMetadataCtx) {
  const [{ contractAddress, network }] = queryKey
  const alchemy = getAlchemyClient(network)
  return alchemy.core.getTokenMetadata(contractAddress)
}

export function getAllBalancesFn(queryClient: QueryClient) {
  return async ({ queryKey }: AllBalancesCtx) => {
    const [{ addresses, networks }] = queryKey
    // Flat list of request for each address and network we do push to the queryClient to hydrate the cache
    const promisesPerAddress = addresses.flatMap(address => {
      const promisesPerNetworks = networks.flatMap(async network => {
        const balanceQueryKey = alchemyQueryKeys.getTokenBalances(address, network)
        const allBalancesForNetwork = await queryClient.ensureQueryData({
          queryKey: balanceQueryKey,
          queryFn: fetchTokenBalances,
          staleTime: 0,
          cacheTime: 0
        })

        const metadataPromises = allBalancesForNetwork.flatMap(async tokenBalance => {
          const tokenMetadataQueryKey = alchemyQueryKeys.getTokenMetadata({
            contractAddress: tokenBalance.contractAddress,
            network
          })

          const tokenMetadata = await queryClient.ensureQueryData({
            queryKey: tokenMetadataQueryKey,
            queryFn: fetchContractMetadata,
            staleTime: 0,
            cacheTime: 0
          })

          return {
            tokenBalance,
            tokenMetadata,
            network,
            address
          }
        })

        const allResults = await allSettled(metadataPromises)
        const fulfilledResults = allResults.filter(isFulfilled).map(result => result.value)
        const rejectedResults = allResults.filter(isRejected)

        rejectedResults.forEach(result => {
          console.error('Error fetching token balances', result.reason)
        })

        return fulfilledResults
      })

      return promisesPerNetworks
    })

    const allResults = await allSettled(promisesPerAddress)
    const fulfilledResults = allResults.filter(isFulfilled).flatMap(result => result.value)
    const rejectedResults = allResults.filter(isRejected)

    rejectedResults.forEach(result => {
      console.error('Error fetching token balances', result.reason)
    })
    return fulfilledResults
  }
}

export async function fetchNfts({ queryKey }: GetNftsCtx) {
  const [{ ownerAddress, network }] = queryKey

  const alchemy = getAlchemyClient(network)
  return alchemy.nft.getNftsForOwner(ownerAddress, {
    pageSize: 10
  })
}

export function getAllNftsFn() {
  return async ({ queryKey }: AllNftsCtx) => {
    const queryClient = SDKQueryClient
    const [{ ownerAddresses, networks }] = queryKey

    const promisesPerAddress = ownerAddresses.flatMap(ownerAddress =>
      networks.map(async network => {
        const nftsQueryKey = alchemyQueryKeys.getNfts({ ownerAddress, network })

        const nfts = await queryClient.ensureQueryData({
          queryKey: nftsQueryKey,
          queryFn: fetchNfts
        })

        return nfts
      })
    )

    const allResults = await allSettled(promisesPerAddress)
    const fulfilledResults = allResults.filter(isFulfilled).flatMap(result => result.value)
    const rejectedResults = allResults.filter(isRejected)

    rejectedResults.forEach(result => {
      console.error('Error fetching nfts', result.reason)
    })
    return fulfilledResults
  }
}
