export class CookieManager {
  clearAll() {
    const cookies = document.cookie.split(';')
    if (cookies.length === 0) {
      return
    }
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i]
      const eqPos = cookie.indexOf('=')
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
    }
  }
  removeSessionCookies() {
    return this.clearAll()
  }
}
