import type { IPostMessage, MessageTypes } from '../types'

export const postMessage = <T extends MessageTypes>(
  data: IPostMessage<T>,
  target: MessageEvent['source'] | null,
  origin = '*'
) => {
  return target
    ? target?.postMessage(JSON.stringify(data))
    : window?.parent?.postMessage(JSON.stringify(data), '*')
}
