import type { inferQueryKeys } from '@lukemorales/query-key-factory'
import { createQueryKeys } from '@lukemorales/query-key-factory'
import { BillingService, UserService } from '@vatom/sdk/services'

export const usersKeys = createQueryKeys('users', {
  me: {
    queryKey: null,
    queryFn: UserService.fetchMe,
    contextQueries: {
      identities: {
        queryKey: null,
        queryFn: UserService.fetchIdentities
      },
      campaignInfo: ({ businessId, campaignId }: { businessId: string; campaignId: string }) => ({
        queryKey: [{ businessId, campaignId }],
        queryFn: async () => UserService.fetchUserCampaignInfo({ businessId, campaignId })
      }),
      campaignPoints: ({ userId, campaignId }: { userId: string; campaignId: string }) => ({
        queryKey: [{ userId, campaignId }],
        queryFn: async () => UserService.fetchUserPoints({ userId, campaignId })
      }),
      businessInfo: ({ businessId }: { businessId: string }) => ({
        queryKey: [{ businessId }],
        queryFn: async () => UserService.fetchUserBusinessInfo({ businessId })
      }),
      formsBusiness: ({
        businessId,
        campaignId
      }: {
        businessId?: string
        campaignId?: string
      }) => ({
        queryKey: [{ businessId }],
        queryFn: async () => UserService.fetchFormBusiness({ businessId, campaignId })
      }),
      termBusiness: ({ businessId }: { businessId: string }) => ({
        queryKey: [{ businessId }],
        queryFn: async () => UserService.fetchTermsBusiness({ businessId })
      }),
      account: ({ businessId }: { businessId: string }) => ({
        queryKey: [{ businessId }],
        queryFn: async () => BillingService.fetchAccount(businessId)
      })
    }
  },
  publicProfile: (userId: string) => ({
    queryKey: [{ userId }],
    queryFn: async () => UserService.fetchPublicProfile(userId)
  })
})

export type UsersKeys = inferQueryKeys<typeof usersKeys>
