import type { AccountModel } from '@vatom/models'
import { getConfig } from '@vatom/sdk/react'

import { vatomAxiosInstance } from '../vatomAxiosInstance'

async function fetchAccount(businessId: string): Promise<AccountModel> {
  try {
    const config = getConfig()
    const { data } = await vatomAxiosInstance.get(`${config.api.billing}/b/${businessId}/account`)
    return data
  } catch (error) {
    console.error('fetchAccount', error)
    throw error
  }
}

export const BillingService = {
  fetchAccount
}
