import { View } from 'react-native'
import WebView from 'react-native-webview'
import { useSessionStore } from '@vatom/sdk/auth'

import usePath from '../hooks/usePathname'

// Idk what CLEAR path is but I can see how when logging out usePath returns CLEAR during a render
const pathsToUnmount = ['logout-callback']

export const OidcSession = () => {
  const refreshToken = useSessionStore(s => s.sessions.get('vatom')?.value.refreshToken)

  const [path] = usePath()

  if (!refreshToken || pathsToUnmount.includes(path)) return null

  return (
    <View
      style={{
        display: 'none'
      }}
    >
      <WebView
        source={{
          html: `<html> <body onload="document.forms[0].submit();">
                    <form method="post" action="https://id.vatom.com/session">
                      <input type="hidden" name="refreshToken"  value="${refreshToken}" >
                    </form >
                  </body></html>`
        }}
      />
    </View>
  )
}
