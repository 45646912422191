import React from 'react'
import { Linking } from 'react-native'
import { getConfig } from '@vatom/sdk/react'
import { translate } from '@vatom/utils'
import { GradientButton, Text } from '@vatom/wombo'
import { Box, Flex } from 'native-base'

import { WebWrapper } from '../../../components/WebWrapper'

// import { i18n } from '../../../i18n'

interface BottomHalfProps {
  onPressVatom: () => void
}

export const BottomHalf = ({ onPressVatom }: BottomHalfProps) => {
  const { privacyPolicyURL } = getConfig()

  return (
    <WebWrapper flex={1}>
      <Flex
        bg="white"
        width={'100%'}
        paddingTop={50}
        _web={{
          alignSelf: 'center',
          flex: 1
          // height: '60vh',
          // maxWidth: 768
        }}
        _ios={{ flex: 1 }}
        _android={{ flex: 1 }}
      >
        <Box px={4}>
          <GradientButton
            w={'100%'}
            alignSelf={'center'}
            maxW={400}
            colorScheme="brand"
            onPress={onPressVatom}
            variant="solid"
            hasImage
            imageHeight="16px"
            imageWidth="18px"
            imgSource={require('../assets/logo-button.png')}
            imgAlt="Vatom Logo"
          >
            <Text
              fontSize="16px"
              color="white"
              fontFamily="Inter"
              fontWeight="500"
              tx="loginScreen.signinText"
            >
              Sign in with Vatom
            </Text>
          </GradientButton>
        </Box>

        <Box alignSelf="center" position="relative" pt={30} px={4}>
          <Text textAlign="center" fontSize="sm" color="gray.500" fontFamily="body">
            {`${translate('loginScreen.bySigningIn')} `}
            <Text
              color="lightBlue.600"
              underline={true}
              onPress={() => Linking.openURL(privacyPolicyURL)}
              tx="common.termsOfService"
            >
              Terms of Service
            </Text>
            {` ${translate('common.and')} `}
            <Text
              color="lightBlue.600"
              underline={true}
              onPress={() => Linking.openURL(privacyPolicyURL)}
              tx="loginScreen.privacyPolicy"
            >
              Privacy Policy
            </Text>
            {`. ${translate('loginScreen.confirmAge')}`}
          </Text>
        </Box>
      </Flex>
    </WebWrapper>
  )
}

export default BottomHalf
