import type { TypedUseQueryOptions } from '@lukemorales/query-key-factory'
import type { UseQueryOptions } from '@tanstack/react-query'
import { useQueries, useQuery } from '@tanstack/react-query'
import type { BusinessModel, IdentityModel, PublicProfileModel, UserModel } from '@vatom/models'
import { UserService } from '@vatom/sdk/services'

import { SDKQueryClient } from '../../queryClient'

import { usersKeys } from './keys'

export const useUserPublicProfile = (userId: string) => {
  return useQuery({
    ...usersKeys.publicProfile(userId),
    enabled: !!userId
  })
}
type PublicProfilesOption<T = PublicProfileModel[]> = TypedUseQueryOptions<
  typeof usersKeys.publicProfile,
  T
>

export const useUserPublicProfiles = <T = PublicProfileModel>(
  userIds: string[],
  { select }: Pick<PublicProfilesOption<T>, 'select'>
) => {
  return useQueries({
    queries: userIds.map(userId => ({
      ...usersKeys.publicProfile(userId),
      enabled: !!userId,
      select
    }))
  })
}

// NOTE: User should have been prefetch on login
// export async function ensureUser() {
//   // It should't be a case to use this
//   return await SDKQueryClient.ensureQueryData(usersKeys.me)
// }
export function getUser(queryClient = SDKQueryClient) {
  // The user was logged in and the query was prefetched
  // Retrieve user data from cache
  const key = ['users', 'me'] // usersKeys.me.queryKey
  const userInCache = queryClient.getQueryData<UserModel>(key)
  if (!userInCache) {
    // throw new Error('getUser. Not found in cache.')
    console.warn('getUser. Not found in cache')
    return undefined
  }
  return userInCache
}

export function getUserId() {
  return getUser()?.sub ?? ''
}

type UseUserOptions<T = UserModel> = Pick<UseQueryOptions<UserModel, unknown, T>, 'select'>

export function useUser<T = UserModel>(options?: UseUserOptions<T>) {
  return useQuery({
    // staleTime: Infinity,
    // cacheTime: Infinity
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    select: options?.select,
    // ...usersKeys.me
    queryKey: ['users', 'me'],
    queryFn: UserService.fetchMe
  })
}

type UseIdentitiesOption<T = IdentityModel[]> = Pick<
  UseQueryOptions<IdentityModel[], unknown, T>,
  'select'
>

export function useIdentities<T = IdentityModel[]>(options: UseIdentitiesOption<T> = {}) {
  return useQuery({
    select: options.select,
    ...usersKeys.me._ctx.identities
  })
}

export const useUserInfoBusiness = (businessId: BusinessModel['id']) => {
  return useQuery({
    ...usersKeys.me._ctx.businessInfo({
      businessId
    }),
    enabled: !!businessId
  })
}

export const useUserInfoCampaign = ({
  campaignId,
  businessId
}: {
  campaignId: string
  businessId: BusinessModel['id']
}) => {
  return useQuery({
    ...usersKeys.me._ctx.campaignInfo({
      businessId,
      campaignId
    }),
    enabled: !!businessId
  })
}
export const useUserCampaignPoints = ({ campaignId }: { campaignId: string }) => {
  const userId = getUserId()
  return useQuery({
    ...usersKeys.me._ctx.campaignPoints({
      userId,
      campaignId
    }),
    enabled: !!campaignId,
    retry(failureCount, error) {
      if (failureCount === 2) {
        return false
      }
      return true
    }
  })
}

export const useAccount = () => {
  const userBusinessId = getUser()?.default_business_id
  return useQuery({
    ...usersKeys.me._ctx.account({
      businessId: userBusinessId ?? ''
    }),
    enabled: !!userBusinessId,
    refetchOnWindowFocus: false
  })
}
