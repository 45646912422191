import { vatomAxiosInstance } from '@vatom/sdk/services'

import { getConfig } from '../appConfig'

import type { RelationshipContact } from './types'

export const getRelationShipHost = () => getConfig().authentication.discoveryUrl

export const followUser = async (contact: RelationshipContact) => {
  const config = getConfig()
  const { data } = await vatomAxiosInstance.post(
    `${config.api.oidc}/me/rels/following`,
    JSON.stringify(contact)
  )
  return data
  // await fetch(`${getRelationShipHost()}/me/rels/following`, {
  //   method: 'POST',
  //   headers: new Headers({
  //     Authorization: `Bearer ${accessToken}`,
  //     'Content-Type': 'application/json'
  //   }),
  //   body: JSON.stringify(contact)
  // })
}

export const unFollowUser = async (contact: RelationshipContact | undefined) => {
  const config = getConfig()
  await vatomAxiosInstance.delete(`${config.api.oidc}/me/rels/following/${contact?.key}`)
  // await fetch(`${getRelationShipHost()}/me/rels/following/${contact?.key}`, {
  //   method: 'DELETE',
  //   headers: new Headers({
  //     Authorization: `Bearer ${accessToken}`
  //   })
  // })
}
