import parse from 'fast-json-parse'

import logger from '../../logger'

export const storage = window.localStorage

/**
 * Loads a string from storage.
 *
 * @param key The key to fetch.
 */
export function loadString(key: string) {
  try {
    const str = storage.getItem(key)
    return str ?? null
  } catch {
    // not sure why this would fail... even reading the RN docs I'm unclear
    return null
  }
}

/**
 * Saves a string to storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 */
export function saveString(key: string, value: string) {
  try {
    storage.setItem(key, value)
    return true
  } catch {
    // return false
    throw new Error('Unable to save to local storage')
  }
}

/**
 * Loads something from storage and runs it thru JSON.parse.
 *
 * @param key The key to fetch.
 */
export async function load(key: string) {
  try {
    const almostThere = storage.getItem(key)
    if (almostThere) {
      const result = parse(almostThere)
      if (result.err) {
        logger.error('unable to parse json', result.err.message)
        throw result.err.message
      } else {
        // logger.info('json parsed successfully', result.value)
        return result.value
      }
    }
    return null
  } catch {
    return null
  }
}

/**
 * Saves an object to storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 */
export function save(key: string, value: any) {
  try {
    storage.setItem(key, JSON.stringify(value))
    return true
  } catch (error) {
    // return false
    logger.debug(`Unable to save to local storage`, key, value)
    if (__DEV__) {
      logger.error(`Unable to save to local storage`, error, key, value)
    }
    throw error
  }
}

/**
 * Removes something from storage.
 *
 * @param key The key to kill.
 */
export function remove(key: string) {
  try {
    storage.removeItem(key)
  } catch {
    return
  }
}

/**
 * Burn it all to the ground.
 */
export function clear() {
  try {
    storage.clear()
  } catch {
    return
  }
}
