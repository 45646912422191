import type { ActionPayload, BlockVResponsePayload, FacePayload } from '@vatom/models/blockV'
import { getConfig } from '@vatom/sdk/react'

import { blockVAxiosInstance } from '../blockVAxiosInstance'

type PerformActionPayload = {
  'this.id': string
  userRef: {
    id?: string
    provider: 'vatominc'
  }
  // payload: any
} & Record<string, unknown>

type ActionResponse = {
  request_id: string
  payload: {
    main: {
      error?: unknown
      output: any
    }
    post?: unknown
    pre?: unknown
  }
}

export async function performAction(action: string, payload: PerformActionPayload) {
  try {
    const config = getConfig()
    const { data } = await blockVAxiosInstance.post<ActionResponse>(
      `${config.server}/v1/user/vatom/action/${action}`,
      payload
    )
    return data.payload
  } catch (error) {
    console.warn('BlockVService.performAction.error:', error)
    throw error
  }
}

type FetchByIdsResponse = {
  request_id: string
  payload: {
    vatoms: BlockVResponsePayload[]
    faces: FacePayload[]
    actions: ActionPayload[]
  }
}
export async function fetchByIds(tokenIds: string[]) {
  try {
    const config = getConfig()
    const { data } = await blockVAxiosInstance.post<FetchByIdsResponse>(
      `${config.server}/v1/user/vatom/get`,
      {
        ids: tokenIds
      }
    )
    return data.payload
  } catch (error) {
    console.warn('BlockVService.fetchByIds.error:', error)
    throw error
  }
}

type ChildrenResponse = {
  request_id: string
  payload: {
    count: number
    results: BlockVResponsePayload[]
    faces: FacePayload[]
    actions: ActionPayload[]
  }
}
export async function fetchChildren(parentId: string) {
  try {
    const config = getConfig()
    const { data } = await blockVAxiosInstance.post<ChildrenResponse>(
      `${config.server}/v1/vatom/discover`,
      {
        scope: {
          key: 'vAtom::vAtomType.parent_id',
          value: parentId
        },
        return: {
          type: '*',
          fields: []
        }
      }
    )
    console.log('LOG: BlockVService.fetchChildren > data:', data)
    return data.payload
  } catch (error) {
    console.warn('BlockVService.fetchChildren.error:', error)
    throw error
  }
}

type UserResponse = {
  request_id: string
  payload: {
    id: string
    meta: any
    properties: any
    system_properties: any
  }
}
export async function fetchUser() {
  try {
    const config = getConfig()
    const { data } = await blockVAxiosInstance.get<UserResponse>(`${config.server}/v1/user`)
    console.log('LOG: BlockVService.fetchUser > data:', data)
    return data.payload
  } catch (error) {
    console.warn('BlockVService.fetchUser.error:', error)
    throw error
  }
}

export async function setParent(payload: any) {
  try {
    const config = getConfig()
    const { data } = await blockVAxiosInstance.patch(`${config.server}/v1/vatoms`, payload)
    return data
  } catch (error) {
    console.warn('BlockVService.setParent.error:', error)
    throw error
  }
}

export async function trashVatom(vatomID: string) {
  try {
    const config = getConfig()
    const payload = {
      'this.id': vatomID
    }
    const { data } = await blockVAxiosInstance.post(`${config.server}/v1/user/vatom/trash`, payload)
    return data
  } catch (error) {
    console.warn('BlockVService.trashVatom.error:', error)
    throw error
  }
}
