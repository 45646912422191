import { create } from 'zustand'

export const nftFilterBy = {
  all: 'all',
  smartNft: 'smartNft',
  wallet: 'wallet',
  custodial: 'custodial'
} as const

export type NftFilterBy = keyof typeof nftFilterBy

export type NftFilterState = {
  filterBy: NftFilterBy | string
  filterAddress: string
}

export const useNftFilterStore = create<NftFilterState>(set => ({
  filterBy: nftFilterBy.all,
  filterAddress: ''
}))
