import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import type {
  Campaign,
  ObjectDefinition as ODef,
  ObjectDefinitionSearchParams
} from '@vatom/experience-sdk'
import { vatomAxiosInstance } from '@vatom/sdk/services'
import axios from 'axios'

import { getConfig } from '../appConfig'
import { getUserId } from '../user'

import type { CampaignOverview } from './types/campaign-overview'
import { getPickUpPointsForObjectDefinitionId } from './selectors'
import type { CampaignPoints, CampaignRules, ObjectDefinition } from './types'

// TODO: create key factory

export const vatomQueryKeys = {
  // Designs
  objectDefinition: [{ scope: 'vatom-object-definition' }] as const,
  campaignGroupRules: [{ scope: 'campaign-group-rules' }] as const,
  campaignGroupOverview: [{ scope: 'campaign-group-overview' }] as const,
  campaignPoints: [{ scope: 'campaign-points' }] as const,
  listBusinessCampaignGroups: [{ scope: 'list-business-campaign-groups' }] as const,
  objectDefinitionSearch: [{ scope: 'object-definition-search' }] as const,
  getVatomObjectDefinition: (businessId: string, objectDefinitionId: string) =>
    [{ ...vatomQueryKeys.objectDefinition[0], businessId, objectDefinitionId }] as const,
  getCampaignGroupRules: (businessId: string, campaignId: string) =>
    [{ ...vatomQueryKeys.campaignGroupRules[0], businessId, campaignId }] as const,
  getCampaignGroupOverview: (businessId: string, campaignId: string) =>
    [{ ...vatomQueryKeys.campaignGroupOverview[0], businessId, campaignId }] as const,
  getCampaignPoints: (campaignId?: string, userId?: string) =>
    [{ ...vatomQueryKeys.campaignPoints[0], campaignId, userId }] as const,
  getListBusinessCampaignGroups: (businessId: string) =>
    [{ ...vatomQueryKeys.listBusinessCampaignGroups[0], businessId }] as const,
  getObjectDefinitionsSearch: (
    businessId: string,
    campaignId: string,
    searchParams?: ObjectDefinitionSearchParams
  ) =>
    [{ ...vatomQueryKeys.objectDefinitionSearch[0], businessId, campaignId, searchParams }] as const
}

const fetchObjectDefinition = async (businessId: string, objectDefinitionId: string) => {
  const config = getConfig()
  const { data } = await axios.get<ObjectDefinition>(
    `${config.api.studio}/b/${businessId}/object-definitions/${objectDefinitionId}`
  )
  return data
}

export const useVatomObjectDefinition = (businessId: string, objectDefinitionId: string) => {
  const vatom = useQuery({
    queryKey: vatomQueryKeys.getVatomObjectDefinition(businessId, objectDefinitionId),
    queryFn: async ({ queryKey: [{ businessId, objectDefinitionId }] }) =>
      fetchObjectDefinition(businessId, objectDefinitionId)
  })
  return vatom
}

export const useCampaignGroupsRules = <SelectResult = CampaignRules>(
  businessId: string,
  campaignId: string,
  select?: (data: CampaignRules) => SelectResult
) => {
  const rules = useQuery({
    queryKey: vatomQueryKeys.getCampaignGroupRules(businessId, campaignId),
    queryFn: async ({ queryKey: [{ businessId, campaignId }] }) => {
      const config = getConfig()
      const response = await axios.get<CampaignRules>(
        `${config.api.studio}/b/${businessId}/campaign-groups/${campaignId}/rules`
      )
      return response.data
    },
    refetchOnMount: true,
    select
  })

  return rules
}

export const useCampaignGroupOverview = <SelectResult = CampaignOverview>(
  businessId: string,
  campaignId: string,
  select?: (data: CampaignOverview) => SelectResult
) => {
  const rules = useQuery({
    queryKey: vatomQueryKeys.getCampaignGroupOverview(businessId, campaignId),
    queryFn: async ({ queryKey: [{ businessId, campaignId }] }) => {
      const config = getConfig()
      const response = await axios.get<CampaignOverview>(
        `${config.api.studio}/b/${businessId}/campaign-groups/${campaignId}/overview`
      )
      return response.data
    },
    refetchOnMount: true,
    select
  })

  return rules
}

async function fetchCampaignPoints({ userId, campaignId }: { userId: string; campaignId: string }) {
  try {
    const config = getConfig()
    const { data } = await vatomAxiosInstance.get<CampaignPoints>(
      `${config.api.points}/u/${userId}/campaigns/${campaignId}/points`
    )
    return data
  } catch (error) {
    console.error('fetchCampaignPoints', error)
    throw error
  }
}

// Points
export type UseCampaignPointsOptions = Omit<
  UseQueryOptions<
    CampaignPoints,
    unknown,
    CampaignPoints,
    ReturnType<typeof vatomQueryKeys.getCampaignPoints>
  >,
  'queryKey' | 'queryFn' | 'enabled' | 'refetchOnMount'
>

// options?: UseCampaignPointsOptions
export const useCampaignPoints = (campaignId: string) => {
  const userId = getUserId()
  return useQuery({
    queryKey: vatomQueryKeys.getCampaignPoints(campaignId, userId),
    queryFn: async () => fetchCampaignPoints({ userId, campaignId }),
    enabled: !!userId,
    refetchOnMount: true
    // ...options
  })
}

export const useImperativeCampaignPoints = () => {
  const userId = getUserId()
  const queryClient = useQueryClient()

  return (campaignId: string) =>
    queryClient.fetchQuery({
      queryKey: vatomQueryKeys.getCampaignPoints(campaignId, userId),
      queryFn: async () => fetchCampaignPoints({ userId, campaignId })
    })
}

export const usePointsOptimisticUpdate = (campaignId?: string, rules?: CampaignRules) => {
  const queryClient = useQueryClient()
  const userId = getUserId()

  const handleOptimisticPickup = (token: any) => {
    if (!token.studioInfo?.objectDefinitionId || !rules) {
      console.warn(
        'usePointsOptimisticUpdate: missing objectDefinitionId or rules, skipping optimistic update'
      )
      throw new Error('usePointsOptimisticUpdate: missing objectDefinitionId or rules')
    }

    const pointsToAdd = getPickUpPointsForObjectDefinitionId(
      token.studioInfo?.objectDefinitionId,
      rules
    )

    if (pointsToAdd.points) {
      queryClient.setQueryData<CampaignPoints>(
        vatomQueryKeys.getCampaignPoints(campaignId, userId),
        currentPoints => {
          if (!currentPoints) {
            return {
              total: pointsToAdd.points,
              [pointsToAdd.channel]: pointsToAdd.points
            }
          }
          return {
            ...currentPoints,
            total: (currentPoints.total ?? 0) + pointsToAdd.points,
            [pointsToAdd.channel]: currentPoints[pointsToAdd.channel] + pointsToAdd.points
          }
        }
      )
    }
  }
  return handleOptimisticPickup
}

export type ItemsResponse = {
  items: Campaign[]
}

export const fetchListBusinessCampaignGroups = async (businessId: string) => {
  const config = getConfig()
  const { data } = await axios.get<ItemsResponse>(
    `${config.api.studio}/b/${businessId}/campaign-groups?includeObjectDefinitions=true`
  )

  return data
}

export const useListBusinessCampaignGroups = () => {
  const queryClient = useQueryClient()

  return (businessId: string) =>
    queryClient.fetchQuery({
      queryKey: vatomQueryKeys.getListBusinessCampaignGroups(businessId),
      queryFn: async ({ queryKey: [{ businessId }] }) => fetchListBusinessCampaignGroups(businessId)
    })
}

export type SearchResponse = {
  items: ODef[]
}

export const fetchObjectDefinitionsSearch = async (
  businessId: string,
  campaignId: string,
  searchParams?: ObjectDefinitionSearchParams
): Promise<SearchResponse> => {
  const payload = searchParams ?? {}
  const config = getConfig()
  const { data } = await vatomAxiosInstance.post(
    `${config.api.studio}/b/${businessId}/campaigns/${campaignId}/object-definitions/search`,
    payload
  )

  return data
}

export const useObjectDefinitionsSearch = () => {
  const queryClient = useQueryClient()

  return (businessId: string, campaignId: string, searchParams?: ObjectDefinitionSearchParams) =>
    queryClient.fetchQuery({
      queryKey: vatomQueryKeys.getObjectDefinitionsSearch(businessId, campaignId, searchParams),
      queryFn: async () => fetchObjectDefinitionsSearch(businessId, campaignId, searchParams)
    })
}
