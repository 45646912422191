import { Analytics } from '@vatom/sdk/analytics'
import { getSession } from '@vatom/sdk/auth'

import { getConfig, getUserId } from '../store'

function getNetworkUserId() {
  return getSession('blockV')?.value.userId
}

export const analytics = () => {
  const blockVUserId = getNetworkUserId()
  const config = getConfig()
  const userId = getUserId()
  return Analytics({ userId, userIdBlockV: blockVUserId, viewerId: config.viewerId })
}
export const useAnalytics = () => {
  return analytics()
}
