import { useCallback, useMemo, useRef } from 'react'
import type { VatomToken } from '@vatom/models'
import type { BlockVToken } from '@vatom/models/blockV'
import { IdentityType } from '@vatom/sdk/core'
import type { AlchemyNftResponse, AllNft, AnyToken } from '@vatom/sdk/react'
import {
  alchemyInventoryKeys,
  blockVInventoryKeys,
  defaultNetworks,
  ensureVatomById,
  getConfig,
  isAlchemyNft,
  networksWithoutTests,
  SDKQueryClient,
  useAlchemyById,
  useBlockVById,
  useGetAlchemyNfts,
  useGetBlockVNfts,
  useGetVatomNfts,
  useIdentities,
  useNftFilterStore,
  useVatomById,
  useWalletPagination,
  vatomInventoryKeys
} from '@vatom/sdk/react'
import { Network } from 'alchemy-sdk'

import { businessFilters, businessSort, walletFilters } from './inventoryFilterHelpers'

const useIdentitiesAddresses = () => {
  const identities = useIdentities()

  const addresses = useMemo(
    () =>
      identities.data
        ?.filter(ident => ident.type === IdentityType.Eth)
        ?.map(wallet => wallet.value) ?? [],
    [identities]
  )
  return addresses
}

export type UseWalletInventoryParams<T> = {
  businessId?: T
  pinnedIds?: string[]
  isTestnetsEnabled?: boolean
}

type UseWalletInventory<T extends string | undefined> = {
  data: AnyToken[]
  filteredData: T extends string ? (VatomToken | BlockVToken)[] : AnyToken[]
  isLoading: boolean
  isFetching: boolean
  fetchNextPage: () => Promise<void>
  refresh: () => Promise<void>
}
/**
 * Use to retrieve all tokens from Vatom and multiple addresses
 * @returns
 */
export const useWalletInventory = <T extends string | undefined>({
  businessId,
  pinnedIds,
  isTestnetsEnabled = true
}: UseWalletInventoryParams<T> = {}) => {
  const _renderCount = useRef(1).current++
  console.log('LOG: useWalletInventory> _renderCount:', _renderCount)

  const networks = isTestnetsEnabled ? defaultNetworks : networksWithoutTests

  const config = getConfig()
  const walletFilterState = useNftFilterStore()

  const addresses = useIdentitiesAddresses()

  const others = useGetAlchemyNfts(
    {
      addresses
    },
    {
      enabled: !businessId
    }
  )
  const vatoms = useGetVatomNfts()
  const blockV = useGetBlockVNfts()

  const isFetching = useMemo(
    () => others.some(s => s.isFetching) || vatoms.isFetching || blockV.isFetching,
    [blockV.isFetching, others, vatoms.isFetching]
  )

  const isLoading = useMemo(() => {
    if (businessId) {
      return vatoms.isLoading || blockV.isLoading
    }
    return others.some(s => s.isLoading) || vatoms.isLoading || blockV.isLoading
  }, [blockV.isLoading, businessId, others, vatoms.isLoading])

  const getHasNextPage = useCallback(() => {
    const { page, pageSize } = useWalletPagination.getState()
    const currentAddresses =
      addresses?.flatMap(address =>
        networks.map(network =>
          SDKQueryClient.getQueryData<AlchemyNftResponse>(
            alchemyInventoryKeys.list({
              page,
              address,
              network,
              limit: pageSize
            }).queryKey
          )
        )
      ) ?? []

    const currentBlockV = SDKQueryClient.getQueryData<{ hits: number }>(
      blockVInventoryKeys.list({ page, limit: pageSize }).queryKey
    )

    // BlockV
    if (pageSize * page < Math.round(currentBlockV?.hits ?? 1)) {
      return true
    }
    if (currentAddresses && currentAddresses.some(c => c?.pageKey)) {
      return true
    }
    if (vatoms.data?.nextCursor !== 'end' && vatoms.data?.nextCursor !== '') {
      // TODO: do something
    }

    return false
  }, [addresses, networks, vatoms.data?.nextCursor])

  const fetchNextPage = useCallback(() => {
    if (isFetching) {
      return
    }

    if (getHasNextPage()) {
      // Set page key
      useWalletPagination.setState(state => ({ page: state.page + 1 }))
    }
  }, [getHasNextPage, isFetching])

  const refresh = useCallback(() => {
    SDKQueryClient.invalidateQueries([
      alchemyInventoryKeys._def,
      blockVInventoryKeys._def,
      vatomInventoryKeys._def
    ])
  }, [])

  /**
   * Data manipulation start here
   */
  const vatomItems = useMemo(() => vatoms.data?.items ?? [], [vatoms.data])

  const otherItems = useMemo(() => others.flatMap(o => o.data?.ownedNfts ?? []), [others])

  const blockVItems = useMemo(() => blockV.data ?? [], [blockV])

  const data = useMemo(
    () => [...vatomItems, ...otherItems, ...blockVItems],
    [blockVItems, otherItems, vatomItems]
  )

  const filteredData = useMemo(() => {
    // Use root wallet filters
    return data.filter(t => walletFilters(t, walletFilterState))
  }, [data, walletFilterState])

  // const filteredData = useMemo(() => {
  //   if (!businessId) {
  //     // Use root wallet filters
  //     return data.filter(t => walletFilters(t, walletFilterState)) as AnyToken[]
  //   }
  //   // filter by business
  //   const bTokens = data
  //     .filter(t => !isAlchemyNft(t))
  //     .filter(t => businessFilters(t, businessId, { config })) as (VatomToken | BlockVToken)[]
  //   // .filter(t => tokenBlackListFilter(t, config))
  //   console.log('LOG: useWalletInventory > business > tokens:', bTokens)
  //   return bTokens.sort((a, b) => businessSort(a, b, { pinnedIds }))
  // }, [businessId, config, data, pinnedIds, walletFilterState]) as T extends string
  //   ? (VatomToken | BlockVToken)[]
  //   : AnyToken[]

  const businessData = useMemo(() => {
    // filter by business
    if (!businessId) {
      return []
    }
    const bTokens = data
      .filter(t => !isAlchemyNft(t))
      .filter(t => businessFilters(t, businessId, { config })) as (VatomToken | BlockVToken)[]
    // .filter(t => tokenBlackListFilter(t, config))
    console.log('LOG: useWalletInventory > business > tokens:', bTokens)
    return bTokens.sort((a, b) => businessSort(a, b, { pinnedIds }))
  }, [businessId, config, data, pinnedIds])

  return {
    data,
    filteredData, //: businessId ? filteredData : businessData,
    businessData,
    isLoading,
    isFetching,
    fetchNextPage,
    refresh
  }
}

///

type WalletInventoryByIdParams = {
  id?: string
  contractAddress?: string
  owner?: string
}

function getTypeOfToken({
  id,
  contractAddress,
  owner
}: WalletInventoryByIdParams): AllNft['type'] | undefined {
  if (id && id.split('-').length > 2) {
    // id = 05306886-d996-4555-b6db-e6cbf275c7ed
    return 'blockV'
  }
  if (id && (!owner || !contractAddress)) {
    // id = 3UJwtKS7l3
    return 'vatom'
  }
  if (owner && contractAddress) {
    return 'alchemy'
  }
  return undefined
}

async function getWalletInventoryById(params: WalletInventoryByIdParams) {
  const typeOfToken = getTypeOfToken(params)
  //
  if (typeOfToken === 'blockV') {
    return
  }
  if (typeOfToken === 'vatom') {
    return await ensureVatomById(params.id as string)
  }
  if (typeOfToken === 'alchemy') {
    // return alchemy
    return
  }
  return undefined
}

type WalletInventoryById =
  | ReturnType<typeof useVatomById>
  | ReturnType<typeof useBlockVById>
  | ReturnType<typeof useAlchemyById>
  | undefined
export const useWalletInventoryById = (params: WalletInventoryByIdParams): WalletInventoryById => {
  const typeOfToken = getTypeOfToken(params)
  console.log('LOG: useWalletInventoryById > typeOfToken:', typeOfToken)

  const vatom = useVatomById(params.id as string, {
    enabled: typeOfToken === 'vatom'
  })
  // console.log('LOG: > useWalletInventoryById > vatom:', vatom)
  const blockV = useBlockVById(params.id as string, {
    enabled: typeOfToken === 'blockV'
  })
  // console.log('LOG: > useWalletInventoryById > blockV:', blockV)
  // TODO: fix this
  const network = Network.ETH_SEPOLIA
  const alchemy = useAlchemyById(
    {
      id: params.id ?? '',
      address: params.contractAddress ?? '',
      owner: params.owner ?? '',
      network
    },
    {
      enabled: typeOfToken === 'alchemy' && !!params.contractAddress && !!params.owner
    }
  )
  // console.log('LOG: > useWalletInventoryById > alchemy:', alchemy)

  if (typeOfToken === 'blockV') {
    return blockV
  }
  if (typeOfToken === 'vatom') {
    return vatom
  }
  if (typeOfToken === 'alchemy') {
    return alchemy
  }
  return undefined
}
