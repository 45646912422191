import type {
  ActionPayload,
  BlockVResponse,
  BlockVResponsePayload,
  FacePayload
} from './BlockVTypesModel'
import { ListingStatus, SellChannel } from './BlockVTypesModel'

export function getBlockChainInfo(token: BlockVResponse, tokenId: string) {
  if (token.eth) {
    const { emitted, network, contract } = token.eth
    return {
      tokenId,
      network,
      networkName: getNetworkName(network),
      networkIcon: getNetworkIcon(network),
      contractAddress: token.eth.contract,
      owner: 'unknown', // Need to perform a reverse lookup?
      tokenLink:
        emitted && network && contract ? getTokenLink(token.id, network, contract) : undefined
    }
  } else if (token.chain) {
    const { minted, network, contract, tx_id, token_id } = token.chain
    return {
      tokenId: token_id || tokenId,
      network,
      networkName: getNetworkName(network),
      networkIcon: getNetworkIcon(network),
      contractAddress: contract,
      owner: 'unknown', // Need to perform a reverse lookup?
      tokenLink:
        minted && network && contract ? getTokenLink(network, contract, tx_id ?? '') : undefined
    }
  }
  return undefined
}

export function getNetworkName(network: string | undefined) {
  switch (network) {
    case 'polygon':
    case 'matic_mainnet':
      return 'Polygon Mainnet'
    case 'matic_testnet':
      return 'Polygon Testnet'
    case 'bsc_mainnet':
      return 'Binance Mainnet'
    case 'bsc_testnet':
      return 'Binance Testnet'
    case 'mainnet':
      return 'Ethereum Mainnet'
    case 'testnet':
      return 'Ethereum Testnet'
    case 'kaleido':
      return 'Kaleido'
    case 'palm_mainnet':
      return 'Palm'
    case 'solana':
      return 'Solana'
    case 'casper_testnet':
      return 'Casper Testnet'
    case 'casper_mainnet':
      return 'Casper'
    case 'sepolia':
      return 'Ethereum Sepolia'
    default:
      return 'Unknown'
  }
}
// TODO: fix this?
export function getNetworkIcon(network: string | undefined) {
  return null
  // switch (network) {
  //   case 'polygon':
  //   case 'matic_mainnet':
  //   case 'matic_testnet':
  //     return require('../res/network-polygon.png')
  //   case 'bsc_mainnet':
  //   case 'bsc_testnet':
  //     return require('../res/network-dex.png')
  //   case 'mainnet':
  //   case 'testnet':
  //     return require('../res/network-ethereum.png')
  //   case 'kaleido':
  //     return require('../res/network-kaleido.png')
  //   case 'palm_mainnet':
  //     return require('../res/network-palm.png')
  //   case 'solana':
  //     return require('../res/network-solana.png')
  //   case 'casper_testnet':
  //     return require('../res/network-casper.png')
  //   case 'casper_mainnet':
  //     return require('../res/network-casper.png')
  //   default:
  //     return require('../res/pixel.png')
  // }
}
export function getTokenLink(
  tokenId: string,
  network: string,
  contract: string,
  txId?: string
): string | undefined {
  if (!network || !contract) return

  switch (network) {
    case 'polygon':
    case 'matic_mainnet':
      return `https://polygonscan.com/token/${contract}?a=${tokenId}`
    case 'matic_testnet':
      return `https:///mumbai.polygonscan.com/token/${contract}?a=${tokenId}`
    case 'bsc_mainnet':
      return `https://bscscan.com/token/${contract}?a=${tokenId}`
    case 'bsc_testnet':
      return `https://testnet.bscscan.com/token/${contract}?a=${tokenId}`
    case 'mainnet':
      return `https://etherscan.io/token/${contract}?a=${tokenId}`
    case 'testnet':
      return `https://goerli.etherscan.io/token/${contract}?a=${tokenId}`
    case 'kaleido':
      return `https://etherscan.io/token/${contract}?a=${tokenId}`
    case 'palm_mainnet':
      return `https://explorer.palm.io/token/${contract}?a=${tokenId}`
    case 'solana':
      return `https://solscan.io/token/${contract}`
    case 'casper_testnet':
      return `https://testnet.cspr.live/deploy/${txId}`
    case 'casper_mainnet':
      return `https://cspr.live/deploy/${txId}`
    default:
      return 'Unknown'
  }
}

export function getCommerceInfo(token: BlockVResponse) {
  const commerceState = token?.private?.['commerce-v1']
  if (!commerceState) return undefined
  return {
    ...commerceState,
    status:
      commerceState.channel === SellChannel.None ? ListingStatus.Unlisted : commerceState.status
  }
}

export function isForSale(token: BlockVResponse) {
  const commerceState = getCommerceInfo(token)
  return (
    commerceState?.channel === SellChannel.Direct ||
    commerceState?.channel === SellChannel.Marketplace
  )
}
export function isPendingList(token: BlockVResponse) {
  const commerceState = getCommerceInfo(token)
  return (
    commerceState?.channel &&
    commerceState?.channel !== SellChannel.None &&
    commerceState.status === 'pending'
  )
}

export function getActionsList(token: BlockVResponse) {
  if (!token?.actions) {
    return []
  }
  const actions = []
  for (const action of token.actions) {
    let name = action?.name?.split('::').slice(-1)[0]
    if (!name) {
      continue
    }
    // Normalize names
    if (name.includes('list-for-sale-v1')) name = 'List'
    if (name.includes('remove-from-sale-v1')) name = 'Delist'
    if (name.includes('share-link-v1')) name = 'ShareLink'
    if (name.includes('varius.action:varius.io:initialize-v1')) name = 'Initialize'
    if (name.includes('initialize-v1')) name = 'Initialize'
    if (name.includes('Clone')) name = 'Share'

    // Filter out invalid actions
    if (name.includes('Drop') && token['vAtom::vAtomType'].dropped) continue
    if (name.includes('Pickup') && !token['vAtom::vAtomType'].dropped) continue
    if (name.includes('List') && isForSale(token)) continue
    if (name.includes('Transfer') && token['vAtom::vAtomType'].dropped) continue
    if (name.includes('Mint') && token.unpublished) continue
    if (name.includes('Delist') && !(isForSale(token) || isPendingList(token))) continue

    actions.push(name)
  }
  return actions
}

export function getVatomFaces(token: BlockVResponse) {
  // const vatomFaces: Face[] = (token.faces ?? []).map(face => FaceModel.parse(face))
  return (token.faces ?? []) as FacePayload[]
}

export function getIdFromIdOrUri(idOrUri: string) {
  if (idOrUri?.includes('varius')) {
    return idOrUri.split(':')[2]
  }
  return idOrUri
}

export function getPrivateData(token: BlockVResponse) {
  // Create private data by normalizing the studio info and state (there are old deprecated standards)
  // if (!token.private) {
  //   return {}
  // }
  const privateData = token.private
  const studioInfoDeprecated = token.private.state?.['varius.behavior:varius.io:studio-info-v1']
  const studioInfo = token.private['studio-info-v1']

  if (studioInfo || studioInfoDeprecated) {
    privateData['studio-info-v1'] = {
      ...studioInfo,
      businessId: (studioInfo?.businessId || studioInfoDeprecated?.businessId)!,
      blueprintId: getIdFromIdOrUri(
        (studioInfo?.blueprintId || studioInfoDeprecated?.blueprintUri)!
      ),
      campaignId: getIdFromIdOrUri((studioInfo?.campaignId || studioInfoDeprecated?.campaignUri)!),
      objectDefinitionId: getIdFromIdOrUri(
        (studioInfo?.objectDefinitionId || studioInfoDeprecated?.objectDefinitionUri)!
      )
    }
  }

  const cloningScore = token['vAtom::vAtomType'].cloning_score || 0
  const numDirectClones = token['vAtom::vAtomType'].num_direct_clones || 0
  const cloneable = {
    cloningScore,
    numDirectClones
  }

  privateData['cloneable-v1'] = {
    ...privateData['cloneable-v1'],
    ...cloneable
  }

  delete privateData.state
  return privateData
}

export function generateTokenId(id: string) {
  const hex = id.replace(/-/g, '')

  function add(x: any, y: any) {
    let c = 0
    const r = []
    x = x.split('').map(Number)
    y = y.split('').map(Number)
    while (x.length || y.length) {
      const s = (x.pop() || 0) + (y.pop() || 0) + c
      r.unshift(s < 10 ? s : s - 10)
      c = s < 10 ? 0 : 1
    }
    if (c) r.unshift(c)
    return r.join('')
  }

  let dec = '0'
  hex.split('').forEach(function (chr) {
    const n = parseInt(chr, 16)
    for (let t = 8; t; t >>= 1) {
      dec = add(dec, dec)
      if (n & t) dec = add(dec, '1')
    }
  })
  return dec
}

export function getResources(token: BlockVResponse) {
  try {
    const vatomFaces = getVatomFaces(token)

    const mutable = token.private['mutable-v1']

    return token['vAtom::vAtomType'].resources.map(r => {
      const mutableResource = mutable?.resources?.find(mr => mr.name === r.name)
      const resourceType = mutableResource?.type || r.resourceType
      const url = mutableResource?.url || r.value.value

      const resource = {
        name: r.name,
        url: encodeAssetProvider(url),
        type: resourceType.split('::').splice(1).join('/').toLowerCase(),
        animation_rules: null,
        ar_transform: null,
        auto_rotate: null
      }

      if (r.resourceType.includes('Scene')) {
        // Hack for Nathan Hochman
        const autoRotate = token.private?.['studio-info-v1']?.businessId !== 'aUp5ILiw5I'

        // Find the 3D face
        const face = vatomFaces.find(f => f?.properties?.display_url === 'native://generic-3d')
        if (face) {
          const animationRules =
            face?.properties?.config?.animation_rules ||
            token?.['vAtom::vAtomType']?.animation_rules ||
            []
          // resource.animation_rules = animationRules.map(AnimationRule.create)

          resource.animation_rules = animationRules

          if (face?.properties?.config?.ar_transform) {
            resource.ar_transform = face.properties.config?.ar_transform
          }

          resource.auto_rotate =
            face?.properties?.config?.auto_rotate === undefined
              ? autoRotate
              : face.properties.config?.auto_rotate
        }
      }
      try {
        // return Resource.create(resource)
        return resource
      } catch (error) {
        console.error('Error creating resource', error, resource)
        return { name: r.name, url: '', type: 'unknown' }
      }
    })
  } catch (error) {
    console.error('Error getting resources', error)
    return []
  }
}

export function canPerformAction(token: BlockVResponse, action: string): boolean {
  return !!getActionsList(token).find(a => a === action)
}

export function getEditionInfo(token: BlockVResponse) {
  const numberedBehavior = token.private['numbered-v1']

  if (!numberedBehavior) {
    return undefined
  }

  const numbered = token.private['lifecycle-v1']?.initialized ? numberedBehavior.number : 0
  const scarcity = token.private['scarcity-v1']?.maxObjects || Infinity

  return {
    numbered,
    scarcity
  }
}

export function getMetadata(token: BlockVResponse) {
  const vatomFaces = getVatomFaces(token)
  const resources = getResources(token)
  const activatedImage = resources.find((r: any) => r?.name === 'ActivatedImage')
  const resource = resources.find((r: any) => r?.name !== 'ActivatedImage')
  const attributes: [{ trait_type: string; value: string | number }] = [
    {
      trait_type: 'category',
      value: token['vAtom::vAtomType'].category || 'none'
    }
  ]
  const editionInfo = getEditionInfo(token)
  /* Edition */
  if (editionInfo && editionInfo.numbered) {
    const editionInfoAsString = `${editionInfo.numbered}/${
      editionInfo.scarcity === Infinity ? '--' : editionInfo.scarcity
    }`
    attributes.push({
      trait_type: 'edition',
      value: editionInfoAsString
    })
  }
  /* Sharing */
  if (canPerformAction(token, 'Clone') || canPerformAction(token, 'share-link-v1')) {
    attributes.push({
      trait_type: 'shares',
      value: (token['vAtom::vAtomType']['num_direct_clones'] || '0').toString()
    })
  }
  attributes.push({
    trait_type: 'template',
    value: token['vAtom::vAtomType'].template
  })
  attributes.push({
    trait_type: 'templateVariation',
    value: token['vAtom::vAtomType'].template_variation
  })
  attributes.push({
    trait_type: 'actions',
    value: token.actions?.map(a => a?.name?.split('::Action::').pop()).join(', ') || '--'
  })
  attributes.push({
    trait_type: 'faces',
    value:
      vatomFaces
        .map(a => `${a?.properties?.constraints?.view_mode}: ${a?.properties?.display_url}`)
        .join(', ') || '--'
  })
  if (token?.private?.['studio-info-v1']) {
    Object.keys(token?.private?.['studio-info-v1']).map(title =>
      attributes.push({
        trait_type: title.toLowerCase(),
        value: (token?.private?.['studio-info-v1'] as any)[title]
      })
    )
  }
  const mutable = token.private['mutable-v1']
  return {
    name: mutable?.title || token['vAtom::vAtomType'].title,
    description: mutable?.description || token['vAtom::vAtomType'].description,
    image: encodeAssetProvider(activatedImage?.url || ''),
    animation_url: resource ? encodeAssetProvider(resource?.url || '') : '',
    attributes
  }
}

function mapString(o: any) {
  return Object.keys(o)
    .map(key => `${key}=${o[key]}`)
    .join('&')
}

interface AssetProvider {
  length: number
  uri: string
  descriptor: string
}

class BlockVInnerStore {
  prefix: string
  privateRefreshToken: string | null
  privateAssetProvider: AssetProvider[] | null

  constructor(prefix: string) {
    this.prefix = prefix

    this.privateRefreshToken = null
    this.privateAssetProvider = null
  }

  set assetProvider(provider: AssetProvider[]) {
    this.privateAssetProvider = provider
    if (typeof localStorage !== 'undefined') {
      // eslint-disable-next-line no-undef
      localStorage.setItem(`${this.prefix}_asset_provider`, JSON.stringify(provider))
    }
  }

  get assetProvider(): AssetProvider[] {
    if (this.privateAssetProvider) {
      return this.privateAssetProvider
    }
    if (typeof localStorage !== 'undefined') {
      // eslint-disable-next-line no-undef
      try {
        const assetProvider = localStorage.getItem(`${this.prefix}_asset_provider`)
        if (assetProvider) return JSON.parse(assetProvider)
        else return []
      } catch (err) {
        console.log('LOG: > getassetProvider > err:', err)
        // logger.warn('Unable to parse JSON payload ', err)
      }
    }
    return []
  }
}

const blockVInnerStore = new BlockVInnerStore('native')

export function encodeAssetProvider(url: string) {
  if (!url || url.includes('undefined')) return url
  const aP = blockVInnerStore.assetProvider
  const aPlen = aP.length
  const compare = new URL(url)
  for (let i = 0; i < aPlen; i += 1) {
    const comparethis = new URL(aP[i].uri)
    if (compare.hostname === comparethis.hostname) {
      // same uri so get the policy signature and key and append
      const queryString = mapString(aP[i].descriptor)
      return `${url}?${queryString}`
    }
  }
  return url
}

export function getChildren(token: BlockVResponse) {
  return []
}

export function getPolicies(token: BlockVResponse, face: ReturnType<typeof getFace>) {
  const properties = token['vAtom::vAtomType']

  return (
    face?.properties?.config?.image_policy ||
    token?.private?.image_policy ||
    properties?.icon_stages ||
    []
  )
}

export function getKeyValue(token: BlockVResponse, keyPath: string[]) {
  let keyValue: any = token
  while (keyPath.length > 0) {
    keyValue = keyValue[keyPath[0]]
    keyPath.splice(0, 1)
    if (!keyValue) {
      break
    }
  }
  return keyValue
}

export function policyMatches(
  token: BlockVResponse,
  policy: any,
  children: ReturnType<typeof getChildren>
) {
  if (typeof policy.count_max !== 'undefined') {
    return children.length <= policy.count_max
  } else if (policy.field) {
    const keyPath = policy.field
      // eslint-disable-next-line no-useless-escape
      .split(/\.(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/)
      .map((k: string) => k.replace(/"/g, ''))
    const keyValue = getKeyValue(token, keyPath)
    return policy.value === keyValue
  }
  return false
}

export function getFeaturedImage(token: BlockVResponse) {
  const metadata = getMetadata(token)

  if (getFaceId(token, 'icon') === 'ImagePolicyFace') {
    const children = getChildren(token)
    const face = getFace(token, 'icon')
    const policies = getPolicies(token, face)
    const properties = token['vAtom::vAtomType']

    for (const policy of policies) {
      if (policyMatches(token, policy, children)) {
        const res = properties.resources.find(r => r.name === policy.resource)?.value.value

        if (res) return res
      }
    }

    console.warn('Image policy face: No policy matched, resorting to the ActivatedImage.')
    const resource = properties.resources.find(r => r.name === 'ActivatedImage')
    return resource?.value.value ?? metadata.image
  }

  return metadata.image
}

export type ViewMode = 'icon' | 'engaged' | 'card' | 'fullscreen'

export function getFace(token: BlockVResponse, view: ViewMode) {
  const vatomFaces = getVatomFaces(token)

  // Check to see if the vatom has a card face
  const cardFace =
    vatomFaces.find(
      v =>
        v.properties?.constraints?.view_mode === 'card' &&
        v.properties?.constraints.platform === 'web'
    ) ||
    vatomFaces.find(
      v =>
        v.properties?.constraints?.view_mode === 'card' &&
        v.properties.constraints?.platform === 'generic'
    )

  const engagedFace =
    vatomFaces.find(
      v =>
        v.properties?.constraints?.view_mode === 'engaged' &&
        v.properties.constraints.platform === 'web'
    ) ||
    vatomFaces.find(
      v =>
        v.properties?.constraints?.view_mode === 'engaged' &&
        v.properties.constraints.platform === 'generic'
    )
  const iconFace =
    vatomFaces.find(
      v =>
        v.properties?.constraints?.view_mode === 'icon' &&
        v.properties.constraints.platform === 'web'
    ) ||
    vatomFaces.find(
      v =>
        v.properties?.constraints?.view_mode === 'icon' &&
        v.properties.constraints.platform === 'generic'
    )

  const fullscreenFace =
    vatomFaces.find(
      v =>
        v.properties?.constraints?.view_mode === 'fullscreen' &&
        v.properties.constraints.platform === 'web'
    ) ||
    vatomFaces.find(
      v =>
        v.properties?.constraints?.view_mode === 'fullscreen' &&
        v.properties.constraints.platform === 'generic'
    )

  // Check to see if the vatom has legacy video or 3d icons that should be returned in the engaged view
  const legacyVideoFace = vatomFaces.find(
    f =>
      f.properties?.constraints?.view_mode === 'icon' &&
      f.properties.display_url === 'native://video'
  )
  const legacy3DIconFace = vatomFaces.find(
    f =>
      f?.properties?.constraints?.view_mode === 'icon' &&
      f.properties.display_url === 'native://generic-3d'
  )

  // const gameType = Object.keys(ARGames).find(
  //   k =>
  //     ARGames[k] === self.vatomFaces.find(f => f.properties.display_url)?.properties.display_url
  // )

  switch (view) {
    // This display mode is used when a user opens the vatom. It falls back to icon if no engaged face is found.
    case 'engaged':
      // eslint-disable-next-line no-case-declarations
      return engagedFace || legacyVideoFace || legacy3DIconFace || cardFace || iconFace
    case 'icon':
      return iconFace
    case 'fullscreen':
      return fullscreenFace
    case 'card':
      // Only return a Card Face if an EngagedFace exists
      if (engagedFace || legacyVideoFace || legacy3DIconFace) return cardFace
      else return null
    default:
      throw new Error(`Unknown View: ${view}`)
  }
}

export function getFaceId(token: BlockVResponse, view: ViewMode) {
  const face = getFace(token, view)

  if (!face) return undefined
  if (face?.properties?.display_url?.startsWith('http')) return 'WebFace' as const

  switch (face?.properties?.display_url) {
    case 'native://image':
      return 'ImageFace' as const
    case 'native://generic-3d':
      return 'ThreeDFace' as const
    case 'native://video':
      return 'VideoFace' as const
    case 'native://image-policy':
      return 'ImagePolicyFace' as const
    default:
      return 'ImageFace' as const
  }
}

export function getHasEngaged(token: BlockVResponse) {
  const vatomFaces = getVatomFaces(token)
  const hasEngaged =
    vatomFaces.find(
      f =>
        f?.properties?.constraints?.view_mode === 'engaged' &&
        f?.properties.display_url !== 'native://image'
    ) !== undefined
  const hasLegacyVideoIcon =
    vatomFaces.find(
      f =>
        f.properties?.constraints?.view_mode === 'icon' &&
        f.properties.display_url === 'native://video'
    ) !== undefined
  const hasLegacy3DIcon =
    vatomFaces.find(
      f =>
        f.properties?.constraints?.view_mode === 'icon' &&
        f.properties.display_url === 'native://generic-3d'
    ) !== undefined
  return hasEngaged || hasLegacyVideoIcon || hasLegacy3DIcon
}

export function getHasCardView(token: BlockVResponse) {
  if (!getHasEngaged(token)) return false

  const vatomFaces = getVatomFaces(token)
  return (
    vatomFaces.find(face => {
      return face.properties?.constraints?.view_mode === 'card'
    }) || false
  )
}

export const ARGameTypes = {
  SimpleGame: 'SimpleGame',
  GeoGame: 'GeoGame',
  MultiLevelGeoGame: 'MultiLevelGeoGame',
  CollectionGameV1: 'CollectionGameV1'
  // Once we want games that are not comming from a legacy webface we can just use this as a normal enum like object
  // SomeOtherGame: 'other-game',
} as const

export type ARGameType = keyof typeof ARGameTypes

export const ARGameIds = {
  [ARGameTypes.SimpleGame]: 'BvLBiEhb4iXzma5B3I_8K',
  [ARGameTypes.GeoGame]: 'fddWbHNEL8fNaCA5xWxlJ',
  [ARGameTypes.MultiLevelGeoGame]: 'Fdqrg4xdfIra4BjPsUvE6',
  [ARGameTypes.CollectionGameV1]: 'SGUQUPtEYS'
} as const

export function getUnlockKey(token: BlockVResponse, view: ViewMode) {
  const activateAction = token.actions?.find(a => a.name.endsWith('Activate'))?.properties?.config
    ?.unlock_key
  const face = getFace(token, view)
  const configFace = JSON.parse(face?.properties.config || '{}')
  const unlock_key_face = configFace?.unlock_key
  const privateUnlock_key = token.private.unlock_key
  return activateAction || unlock_key_face || privateUnlock_key
}

///////////////////
// Other Helpers

type MergeResponsePayloads = {
  vatoms: BlockVResponsePayload[]
  faces: FacePayload[]
  actions: ActionPayload[]
}
export function mergeResponsePayloads(payload: MergeResponsePayloads) {
  const response = []
  for (const token of payload.vatoms) {
    response.push(findResponsePayloadForToken(token, payload))
  }
  return response
}

export function findResponsePayloadForToken(
  tokenPayload: BlockVResponsePayload,
  payload: {
    faces: FacePayload[]
    actions: ActionPayload[]
  }
) {
  const actions =
    payload?.actions?.filter((action: ActionPayload) =>
      action.name.includes(tokenPayload['vAtom::vAtomType'].template)
    ) ?? []
  const faces =
    payload?.faces?.filter(
      (face: FacePayload) => face.template === tokenPayload['vAtom::vAtomType'].template
    ) ?? []

  return { ...tokenPayload, faces, actions }
}

//
/////
type TransferPayloadParam =
  | string
  | {
      userID?: string
      id?: string
      phoneNumber?: string
      email?: string
    }
export function createTransferPayload(user: TransferPayloadParam) {
  // Check if user is a VatomUser
  const payload: any = {}
  if (typeof user === 'string') {
    // Check if string is email or phone number
    if (/^0x[a-fA-F0-9]{40}$/.test(user)) {
      payload['new.owner.eth_address'] = user
    } else if (user.indexOf('@') !== -1) {
      payload['new.owner.email'] = user
    } else if (user.indexOf('+') === 0) {
      payload['new.owner.phone_number'] = user
    } else {
      payload['new.owner.id'] = user
    }
  } else {
    // This must be a VatomUser, fetch the identifying property
    if (user.userID || user.id) {
      payload['new.owner.id'] = user.userID ?? user.id
    } else if (user.phoneNumber) {
      payload['new.owner.phone_number'] = user.phoneNumber
    } else if (user.email) {
      payload['new.owner.email'] = user.email
    } else {
      return Promise.reject({
        code: 'INVALID_PARAMETER',
        message: `The user object supplied didn't have any identifying fields. It must have either a userID, an email, or a phoneNumber.`
      })
    }
  }

  // Send request
  return payload
}
