import React from 'react'
import type { ImageSourcePropType } from 'react-native'
import type { IButtonProps } from 'native-base'
import { HStack, Image, Pressable } from 'native-base'

import GradientBackground from '../GradientBackground'
import theme from '../theme'

/* eslint-disable-next-line */
export interface GradientButtonProps extends IButtonProps {
  children: React.ReactNode
  /**
   * onPress handler passed to GradientButton component.
   */
  onPress?: () => void
  /**
   * Use colorScheme prop and the button will handle the gradient values.
   * A color scheme is any of the core theme colors found here:
   * `libs/wombo/src/lib/theme/colors.ts`
   *
   * These would be valus like 'brand', 'accent', 'red', 'blue', etc.
   */
  colorScheme?: string
  /**
   * Boolean that render image to left of button text when truthy.
   */
  hasImage?: boolean
  /**
   * Image height used if hasImage === true
   */
  imageHeight?: string | number
  /**
   * Image width used if hasImage === true
   */
  imageWidth?: string | number
  /**
   * Image source used if hasImage === true
   */
  imgSource?: ImageSourcePropType
  /**
   * Alt attribute for Image accessibility
   */
  imgAlt?: string
  /**
   * Width of Pressable button. Default is 'full'
   */
  width?: string | number
}

export const GradientButton = ({
  colorScheme = 'brand',
  children = 'Button',
  hasImage,
  imageHeight,
  imageWidth,
  imgSource,
  imgAlt,
  onPress,
  width = 'full',
  ...props
}: GradientButtonProps) => {
  return (
    <Pressable
      onPress={onPress}
      _dark={{
        backgroundColor: theme.colors.systemColorsDark['orange']
      }}
      _light={{
        backgroundColor: theme.colors.systemColorsLight['orange']
      }}
      {...props}
      rounded={'sm'}
      p={2}
    >
      {({ isHovered, isPressed }) =>
        hasImage ? (
          <HStack space={2} justifyContent="center" alignItems="center">
            <Image
              alignSelf="center"
              height={imageHeight}
              width={imageWidth}
              source={imgSource}
              accessibilityIgnoresInvertColors
              alt={imgAlt ?? 'image'}
            />
            {children}
          </HStack>
        ) : (
          children
        )
      }
    </Pressable>
  )
}

export default GradientButton
