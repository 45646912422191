import type { VatomToken } from '@vatom/models'
import { getConfig } from '@vatom/sdk/react'

import { vatomAxiosInstance } from '../vatomAxiosInstance'

export type UserInventoryResponse = {
  items: VatomToken[]
  nextCursor: string
}

type UserInventoryParams = {
  cursor: string
}
const defaultParamsUserInventory: UserInventoryParams = {
  cursor: ''
}

export async function fetchUserInventory({ cursor } = defaultParamsUserInventory) {
  const config = getConfig()
  const { data } = await vatomAxiosInstance.post<UserInventoryResponse>(
    `${config.api.network}/vatoms/user-inventory/search`
    // { cursor }
  )
  return data
}
