import deepMerge from '@fastify/deepmerge'
import AsyncStorage from '@react-native-async-storage/async-storage'
import type { QueryFunctionContext, UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import type { AppConfig } from '@vatom/models'
import { businessDataSchema } from '@vatom/models'
import {
  allSettled,
  getConfig,
  isFulfilled,
  isRejected,
  SDKQueryClient,
  useConfigState
} from '@vatom/sdk/react'
import { BusinessService, vatomAxiosInstance } from '@vatom/sdk/services'
import type { PageConfig } from '@vatom/wallet-sdk'
import { VatomWallet } from '@vatom/wallet-sdk'
import axios from 'axios'
import { keyBy } from 'lodash-es'

import { businessKeys2 } from './keys'
import type { BusinessData, BusinessReference } from './types'

// TODO: use query keys store

export const businessQueryKeys = {
  business: [{ scope: 'business' }] as const,
  getBusiness: (businessIdOrName: string, publicOnly?: boolean) =>
    [{ ...businessQueryKeys.business[0], businessIdOrName, publicOnly }] as const,
  businesses: [{ scope: 'businesses' }] as const,
  getBusinesses: (name: string, context?: string, join?: boolean) =>
    [{ ...businessQueryKeys.businesses[0], name, context, join }] as const,
  businessesByIds: [{ scope: 'businessesByIds' }] as const,
  getBusinessesByIds: (ids: string[]) =>
    [{ ...businessQueryKeys.businessesByIds[0], ids }] as const,
  businessesUser: [{ scope: 'businessesUser' }] as const,
  businessesInventoryUser: [{ scope: 'businessesInventoryUser' }] as const,
  businessCommunities: [{ scope: 'communities' }] as const,
  getBusinessCommunities: (businessId: string) =>
    [{ ...businessQueryKeys.businessCommunities[0], businessId }] as const
}

export const fetchBusiness = async ({
  queryKey: [{ businessIdOrName, publicOnly }]
}: QueryFunctionContext<ReturnType<(typeof businessQueryKeys)['getBusiness']>>) => {
  const data = await BusinessService.fetchBusiness({ businessIdOrName, publicOnly })
  return data
}

export const fetchBusinesses = async ({
  queryKey: [{ name, context, join }]
}: QueryFunctionContext<ReturnType<(typeof businessQueryKeys)['getBusinesses']>>) => {
  const config = getConfig()
  let url = `${config.api.studio}/b?name=${name}`

  // const apiInstance = getRootStore().service.studio

  const redirectContext = await AsyncStorage.getItem('REDIRECT_CONTEXT')

  if (context || redirectContext) {
    url = `${url}&context=${context || redirectContext}`
  }
  if (join) {
    url = `${url}&join=${join}`
    AsyncStorage.removeItem('REDIRECT_CONTEXT')
  }

  const { data } = await axios.get<BusinessData[]>(url)
  return data
}

type UseBusinessOptions<T> = Omit<
  UseQueryOptions<BusinessData, unknown, T, ReturnType<typeof businessQueryKeys.getBusiness>>,
  'queryKey' | 'queryFn'
>

const getCustomDomainConfigSelector = (appConfig: AppConfig) => (data: BusinessData) => {
  const businessProfileFromWalletConfig = appConfig.businessProfileOverride
  const strategy = appConfig?.businessProfileOverrideStrategy
  const shouldApply =
    businessProfileFromWalletConfig &&
    appConfig?.isBusinessLocked &&
    appConfig.businessId === data.id

  if (!shouldApply) {
    return data
  }
  let result

  if (strategy === 'deep-merge') {
    const dp = deepMerge()
    const merged = dp(data, businessProfileFromWalletConfig)
    result = merged
  }

  if (strategy === 'override') {
    result = businessProfileFromWalletConfig
  }

  if (strategy === 'shallow-merge') {
    result = { ...data, ...businessProfileFromWalletConfig }
  }

  const parsedResult = businessDataSchema.safeParse(result)
  if (parsedResult && !parsedResult.success) {
    console.warn('Error handling business profile override:', {
      error: parsedResult.error,
      businessProfileFromWalletConfig,
      strategy,
      businessProfileFromStudio: data
    })
    return result as BusinessData
  }

  return result as BusinessData
}

// Memoize the business config selector to avoid re-creating the result on every render
const businessConfigMemo = new Map<string, ReturnType<typeof getCustomDomainConfigSelector>>()
const getBusinessConfigWithMemo = (appConfig: AppConfig) => {
  const businessConfigSelector = businessConfigMemo.get(appConfig.businessId)
  if (businessConfigSelector) {
    return businessConfigSelector
  }
  const businessConfig = getCustomDomainConfigSelector(appConfig)
  businessConfigMemo.set(appConfig.businessId, businessConfig)
  return businessConfig
}

export const getBusinessSelector =
  <T = BusinessData>(appConfig: AppConfig) =>
  (selector?: (data: BusinessData) => T) =>
  (data: BusinessData) => {
    const customDomainConfigSelector = getBusinessConfigWithMemo(appConfig)
    const result = customDomainConfigSelector(data)
    if (selector) {
      return selector(getPageConfig(result))
    }
    return getPageConfig(result)
  }

export const useBusiness = <T = BusinessData>(
  props: BusinessReference,
  options?: UseBusinessOptions<T>,
  publicOnly?: boolean
) => {
  const resultByName = useBusinessSearch(props, {
    enabled: !props?.businessId
  })
  const foundByName = resultByName?.data?.[0]?.id
  const businessId = foundByName ?? props?.businessId
  const appConfig = useConfigState()

  return useQuery({
    queryKey: businessQueryKeys.getBusiness(businessId ?? '', publicOnly ? publicOnly : true),
    queryFn: fetchBusiness,
    select: getBusinessSelector<T>(appConfig.data)(options?.select),
    enabled: !!businessId
  })
}

// type BusinessParams = {
//   businessId: string
//   publicOnly?: boolean
// }
// export const prefetchBusiness = async (params: BusinessParams, queryClient = SDKQueryClient) => {
//   await queryClient.prefetchQuery({
//     queryKey: businessQueryKeys.getBusiness(
//       params.businessId ?? '',
//       params?.publicOnly ? params.publicOnly : true
//     ),
//     queryFn: fetchBusiness
//     // select: getBusinessSelector<T>(appConfig.data)(options?.select)
//   })
// }

type BusinessSearch = {
  business?: string
  businessId?: string
  context?: string
  join?: boolean
}

const businessSearchOptions = {
  enabled: true
}
export function useBusinessSearch(
  { business, businessId, context, join }: BusinessSearch,
  options = businessSearchOptions
) {
  const businessIdOrName = business ?? businessId ?? ''
  const enabled =
    options.enabled &&
    ((!!businessIdOrName && !join) || (!!businessIdOrName && (join || !!context)))

  return useQuery({
    // ...businessKeys.search({
    //   name: businessIdOrName,
    //   context: context ?? '',
    //   join: join ?? false
    // }),
    ...businessKeys2.search({
      name: businessIdOrName,
      context: context ?? '',
      join: join ?? false
    }),
    enabled
  })
}

// TODO: move to selector file
const defaultBusinessByIdsSelector = <TResult = BusinessData>(data: TResult[]) => {
  return keyBy(data, 'id')
}

export const useBusinessByIds = <TResult = BusinessData>(
  businessIds: string[],
  select: (data: BusinessData[]) => TResult[]
) => {
  const query = useQuery({
    ...businessKeys2.byIds(businessIds),
    enabled: !!businessIds.length,
    select: select ?? defaultBusinessByIdsSelector
  })

  return query
}

const fetchBusinessesUser = async () => {
  const config = getConfig()
  const { data } = await vatomAxiosInstance.get<string[]>(`${config.api.vatoms}/me/businesses`)
  return data
}

const fetchBusinessesInventoryUser = async () => {
  const config = getConfig()
  const response = await vatomAxiosInstance.get<{ items: string[] }>(
    `${config.api.network}/vatoms/user-inventory/businesses`
  )
  return response.data.items
}

const fetchAllBusinessesUser = async () => {
  const promises = await allSettled([fetchBusinessesUser(), fetchBusinessesInventoryUser()])
  const res = promises.filter(isFulfilled)
  const rej = promises.filter(isRejected)
  if (rej.length > 0) {
    console.error(
      'fetchAllBusinessesUser:',
      rej.map(r => r.reason)
    )
  }

  const queryData = res.flatMap(r => r.value)
  const data = [...new Set(queryData)]
  return data
}

export const useBusinessesUser = () => {
  const query = useQuery({
    queryKey: businessQueryKeys.businessesUser,
    queryFn: fetchAllBusinessesUser
  })
  return query
}

const getPageConfig = (business: BusinessData) => {
  try {
    const isEmbedded = VatomWallet.isEmbedded()
    const sdkPageConfig = VatomWallet.getSDKPageConfig()

    if (!isEmbedded) {
      return business
    }

    if (sdkPageConfig) {
      // @ts-expect-error 2 type from different files. Fix will be putting all types in another library
      business.pageConfig = sdkPageConfig
    } else {
      // @ts-expect-error 2 type from different files. Fix will be putting all types in another library
      business.pageConfig = {} as PageConfig
    }

    console.log('getPageConfig.business', business)

    return business
  } catch (error) {
    console.log('getPageConfig.error: ', error)
    return business
  }
}

export type Community = {
  id: string
  businessId: string
  name: string
  isDefault: boolean
  description: string | null
  icon: string
  isArchived: boolean
}

const fetchBusinessCommunities = async ({
  queryKey: [{ businessId }]
}: QueryFunctionContext<ReturnType<(typeof businessQueryKeys)['getBusinessCommunities']>>) => {
  const config = getConfig()
  const { data } = await vatomAxiosInstance.get<Community[]>(
    `${config.api.events}/b/${businessId}/communities`
  )
  return data
}

export type UseCommunitiesOptions<T> = Omit<
  UseQueryOptions<
    Community[],
    unknown,
    T,
    ReturnType<typeof businessQueryKeys.getBusinessCommunities>
  >,
  'queryKey' | 'queryFn'
>

export const useBusinessCommunities = <T = Community[]>(
  businessId: string,
  options?: UseCommunitiesOptions<T>
) => {
  const query = useQuery({
    queryKey: businessQueryKeys.getBusinessCommunities(businessId),
    queryFn: fetchBusinessCommunities,
    enabled: !!businessId,
    refetchOnWindowFocus: false,
    ...options
  })
  return query
}
