import { z } from 'zod'

import type { BlockVResponse } from './BlockVTypesModel'
import { BlockVResponseModel } from './BlockVTypesModel'
import {
  ARGameIds,
  ARGameTypes,
  canPerformAction,
  generateTokenId,
  getActionsList,
  getBlockChainInfo,
  getCommerceInfo,
  getFeaturedImage,
  getHasCardView,
  getMetadata,
  getPrivateData,
  getResources,
  getVatomFaces
} from './BlockVUtils'

// ex BVatomToken
export const BlockVTokenModel = BlockVResponseModel.extend({
  // id: z.string()
  // shouldShowNotification: z.boolean().default(true).optional(),
  tokenUri: z.string().nullish(),
  contractAddress: z.string().optional()
  // network: z.string().optional(),
  // openedAt: z.string().nullable().optional(),
  // BVatomToken
  // vatomFaces: z.array(FaceModel),
  // vatomActions: ActionPayloadSchema.array()
})
  .transform(token => {
    const properties = token['vAtom::vAtomType']

    const created = token.when_created
    const modified = token.when_modified ? token.when_modified : token.when_created

    const tokenId = generateTokenId(token.id)

    return {
      ...token,
      type: z.literal('blockV').value, // ex 'vatom'
      tokenId,
      vatomFaces: getVatomFaces(token),
      vatomActions: token.actions ?? [],
      properties,
      private: getPrivateData(token),
      //
      owner: properties.owner,
      author: properties.author,
      lastOwner: properties.transferred_by,
      parentId: properties.parent_id ?? '.',
      //
      modified: modified,
      created: created,
      updatedAt: modified,
      createdAt: created,
      //
      isFolder: properties.root_type.indexOf('ContainerType') !== -1,
      isDefinedFolder: properties.root_type.indexOf('DefinedFolderContainerType') !== -1,
      isDiscoverFolder: properties.root_type.indexOf('DiscoverFolderContainerType') !== -1,
      //
      get displayImage() {
        return getFeaturedImage(token)
      },
      get metadata() {
        return getMetadata(token)
      },
      get actionsList() {
        return getActionsList(token)
      },
      get blockchainInfo() {
        return getBlockChainInfo(token, tokenId)
      },
      get isMinted() {
        return !!(token.eth?.emitted || token.chain?.minted)
      },
      get commerceInfo() {
        return getCommerceInfo(token)
      },
      get provenance() {
        return []
      },
      get position() {
        return token['vAtom::vAtomType'].dropped ? token['vAtom::vAtomType'].geo_pos : undefined
      },
      get studioInfo() {
        return token.private?.['studio-info-v1']
      },
      get shareInfo() {
        return token.private?.['share-v1']?.shareUrl
      },
      get resources() {
        return getResources(token)
      },
      get threeDInfo(): {
        url?: string
        autoRotate?: boolean
      } | null {
        const face = getVatomFaces(token).find(
          f => f?.properties?.display_url === 'native://generic-3d'
        )
        if (!face) {
          return null
        }

        const autoRotate = token.private?.['studio-info-v1']?.businessId !== 'aUp5ILiw5I'
        return {
          url: getMetadata(token).animation_url,
          autoRotate: face?.properties?.config?.auto_rotate === undefined ? autoRotate : false
        }
      },
      get supportedAddresses() {
        return ['identities.type:email', 'identities.type:eth', 'identities.type: phoneNumber']
      },
      get hasCardView() {
        return getHasCardView(token)
      },
      get isARGame() {
        return getVatomFaces(token).some(f => {
          return Object.values(ARGameIds).some(id => f?.properties?.display_url?.includes(id))
        })
      },
      get isCrate() {
        const vatomFaces = getVatomFaces(token)
        const isFolder = vatomFaces.find(n => n?.properties?.display_url === 'native://folder')
        const isCrateV2 = !!token.private.behaviors?.includes('crate-v2')
        const isCrateV3 = !!token.private.behaviors?.includes('crate-v3')

        return isFolder || isCrateV2 || isCrateV3
      },
      get canShare() {
        return canPerformAction(token, 'Clone') || canPerformAction(token, 'share-link-v1')
      },
      get userPoints() {
        return token.private['user-points-v2']
      },
      get gameType() {
        const face = getVatomFaces(token).find(f => {
          return Object.values(ARGameIds).find(id => f.properties.display_url.includes(id))
        })

        const entry = Object.entries(ARGameIds).find(([_, id]) =>
          face?.properties.display_url.includes(id)
        )
        if (!entry) {
          return null
        }
        const [gameType] = entry
        return ARGameTypes[gameType as keyof typeof ARGameTypes]
      },
      get gameConfig() {
        const face = getVatomFaces(token)?.find(f => {
          return Object.values(ARGameIds).find(id => f.properties.display_url.includes(id))
        })
        return face?.properties.config
      },
      get royalties() {
        return token.private['royalties-v1']?.artist ?? 0
      }
    }
  })
  .brand<'BlockVToken'>()
export type BlockVToken = z.infer<typeof BlockVTokenModel>

export type BlockVTokenId = BlockVToken['id']
export const isBlockVTokenId = (id: string): id is BlockVTokenId => true

export function isBlockVToken(token: unknown): token is BlockVToken {
  const parsed = BlockVTokenModel.safeParse(token)
  !parsed.success && console.warn('isBlockVToken: parse error', parsed.error)
  return parsed.success
}

function performAction(
  token: BlockVResponse,
  {
    action,
    payload
  }: {
    action: string
    payload: any
  }
) {
  const undos: any[] = []
  switch (action) {
    case 'Transfer':
      // payload = createTransferPayload(payload)
      // undos.push(self.region?.preemptiveChange(self.id, '/owner', '.'))
      // undos.push(self.region?.preemptiveChange(self.id, '/properties/owner', '.'))
      break
    case 'Clone':
      // payload = createTransferPayload(payload)
      // We don't need this because we are not transfering, we're just cloning
      // undos.push(self.region?.preemptiveChange(self.id, '/properties/owner', '.'))
      break

    case 'Drop':
      // undos.push(self.region?.preemptiveChange(self.id, '/properties/geo_pos', payload))
      // undos.push(self.region?.preemptiveChange(self.id, '/properties/dropped', true))
      break

    case 'Pickup':
      // undos.push(self.region?.preemptiveChange(self.id, '/properties/dropped', false))
      break

    case 'Redeem':
      // undos.push(self.region?.preemptiveChange(self.id, '/owner', '.'))
      // undos.push(self.region?.preemptiveChange(self.id, '/properties/owner', '.'))

      break

    case 'Delete':
    // undos.push(self.region?.preemptiveChange(self.id, '/owner', '.'))
    // undos.push(self.region?.preemptiveChange(self.id, '/properties/owner', '.'))

    // return self.api.vatomApi.trashVatom(self.id).catch((err: Error) => {
    //   undos.map(u => u())
    //   throw err
    // })

    // case 'Combine':
    // return this.combineWith(payload)

    // case 'Split':
    // return this.split()

    // default:
    //   break
  }

  // if (action === 'Initialize') {
  //   const hasLegacyLifecycle = self.vatomActions.find(a =>
  //     a.name.includes('varius.action:varius.io:initialize-v1')
  //   )
  //   action = hasLegacyLifecycle ? 'varius.action:varius.io:initialize-v1' : 'initialize-v1'
  // }

  // const fullPayload = Object.assign(
  //   {
  //     'this.id': self.id,
  //     userRef: {
  //       id: getUser()?.sub,
  //       provider: 'vatominc'
  //     }
  //   },
  //   payload
  // )

  // // Perform the action
  // return self.api.vatomApi
  //   .performAction(action, fullPayload)
  //   .then(res => {
  //     // if (res) {
  //     // await self.region.load()
  //     // }
  //     return res
  //   })
  //   .catch((err: any) => {
  //     console.log('Error performing action: UNDOING...', err, '')
  //     // An error occurred, undo preemptive actions
  //     undos.map(u => u())

  //     // Workaround: If error was an attempt to pick up a vatom but the vatom is already picked up, it's possible
  //     // that the GeoPos region missed an update. Notify all GeoPos regions that this vatom is no longer available.
  //     if (err.code == 1645)
  //       self.rootStore
  //         .dataPool!.regions.find(r => r.id === 'geopos')
  //         ?.regions.forEach(r => r.preemptiveChange(self.id, '/properties/dropped', false))

  //     // Pass on the error
  //     throw err
  //   })
  return null
}
