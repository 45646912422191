import type { BlockVToken } from '@vatom/models/blockV'
import { type VatomToken, VatomTokenUtils } from '@vatom/models/vatom'
import { NftTokenType } from 'alchemy-sdk'

import type { AlchemyNft, AllNft, AnyToken } from './types'

export function getAlchemyEdition(alchemyNft: AlchemyNft) {
  if (alchemyNft.tokenType !== NftTokenType.ERC721) {
    return ''
  }

  return `${alchemyNft.tokenId}/${alchemyNft.contract.totalSupply}`
}

export function getVatomDisplayImage(vatomNft: VatomToken) {
  return VatomTokenUtils.getFeaturedImage(vatomNft)
}

export function getVatomPosition(vatomNft: VatomToken) {
  const map = vatomNft.state['map-ready-v1']
  if (!map || !map.location) {
    return null
  }
  const { lon, lat } = map.location
  return {
    coordinates: [lon, lat]
  }
}

export function getVatomCategory(vatomNft: VatomToken, options?: { isTitle?: boolean }) {
  const toTitleCase = (str: string) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }
  const category = vatomNft?.state?.['meta-v1']?.category ?? ''
  return options?.isTitle ? toTitleCase(category) : category
}

export function isVatomNft(token: unknown): token is VatomToken {
  if (!token) return false
  return typeof token === 'object' && 'type' in token && token.type === 'vatom'
}

export function isBlockVNft(token: unknown): token is BlockVToken {
  if (!token) return false
  return typeof token === 'object' && 'type' in token && token.type === 'blockV'
}

export function isAlchemyNft(token: unknown): token is AlchemyNft {
  if (!token) return false
  return typeof token === 'object' && 'type' in token && token.type === 'alchemy'
}
