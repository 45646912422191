/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import { Platform } from 'react-native'

const usePath = () => {
  if (Platform.OS !== 'web') {
    console.warn('usePath is only available on web')
    return ['', () => null] as const
  }

  const initialPath = window.location.pathname.substr(1)

  const [path, setPath] = React.useState(initialPath)

  const changePath = (path: string) => {
    window.history.pushState({}, path, `/${path}`)
    setPath(path)
  }

  React.useEffect(() => {
    window.onpopstate = () => {
      setPath(window.location.pathname.substr(1))
    }
    return () => {
      // We might want to check this out once we implement hash routing for experiences
      window.onhashchange = null
    }
  }, [])

  return [path, changePath] as const
}

export default usePath
