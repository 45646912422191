import type { LoaderProps, LoaderViewProps } from '@vatom/wombo'
import { Loader, LoaderView } from '@vatom/wombo'

import { useBusinessTheme } from '../../hooks/useBusinessTheme'

export function ThemedLoaderView(props: LoaderViewProps) {
  const { loaderColor, ...rest } = props
  const theme = useBusinessTheme()
  const color = theme.brandTheme?.primary ?? loaderColor

  return <LoaderView loaderColor={color} {...rest} />
}

export function ThemedLoader(props: LoaderProps) {
  const { color, ...rest } = props
  const theme = useBusinessTheme()
  const colorThemed = theme.brandTheme?.primary ?? color

  return <Loader color={colorThemed} {...rest} />
}
