import { useMutation, useQueryClient } from '@tanstack/react-query'

import { followUser, unFollowUser } from './api'
// import { relationshipsQueryKeys } from './queryKeys'
import { relationshipsKeys } from './keys'
import type { RelationshipContact } from './types'

export const useRelFollowContact = () => {
  const queryClient = useQueryClient()
  const queryKey = relationshipsKeys.following.queryKey

  return useMutation({
    mutationFn: async (contact: RelationshipContact) => {
      await followUser(contact)
    },
    onMutate: async (contact: RelationshipContact) => {
      await queryClient.cancelQueries({ queryKey })
      const previousContacts = queryClient.getQueryData<RelationshipContact[]>(queryKey)
      queryClient.setQueryData<RelationshipContact[]>(queryKey, param => {
        return param ? [...param, contact] : param
      })

      return { previousContacts }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: relationshipsKeys.following.queryKey })
      queryClient.invalidateQueries({ queryKey: relationshipsKeys.userFollowers._def })
      queryClient.invalidateQueries({ queryKey: relationshipsKeys.userFollowing._def })
    },
    onError: (err, newContacts, context) => {
      queryClient.setQueryData<RelationshipContact[]>(queryKey, param => context?.previousContacts)
    }
  })
}

export const useRelUnfollowContact = () => {
  const queryClient = useQueryClient()
  const queryKey = relationshipsKeys.following.queryKey

  return useMutation({
    mutationFn: async (contact: RelationshipContact | undefined) => {
      await unFollowUser(contact)
    },
    onMutate: async contact => {
      await queryClient.cancelQueries({ queryKey: queryKey })
      const previousContacts = queryClient.getQueryData<RelationshipContact[]>(queryKey)

      queryClient.setQueryData<RelationshipContact[]>(queryKey, currentContacts => {
        return currentContacts?.filter(c =>
          contact?.key ? c.key !== contact?.key : c.value !== contact?.value
        )
      })

      return { previousContacts }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: relationshipsKeys.following.queryKey })
      queryClient.invalidateQueries({ queryKey: relationshipsKeys.userFollowers._def })
      queryClient.invalidateQueries({ queryKey: relationshipsKeys.userFollowing._def })
    },
    onError: (err, newContacts, context) => {
      queryClient.setQueryData<RelationshipContact[]>(queryKey, param => context?.previousContacts)
    }
  })
}
