import type { PathConfigMap } from '@react-navigation/native'
import { getStateFromPath } from '@react-navigation/native'
import type { AppStackParamList } from '@vatom/wallet-routes'
import { AppRoutes, TabRoutes } from '@vatom/wallet-routes'
import { pathToRegexp } from 'path-to-regexp'

// eslint-disable-next-line @typescript-eslint/ban-types
type Options<ParamList extends {}> = {
  initialRouteName?: string
  screens: PathConfigMap<ParamList>
}

export function getInitialNavigationState(
  _path: string,
  options: Options<AppStackParamList> | undefined
) {
  const path = _path.startsWith(':/') ? _path.replace(':/', '/') : _path
  const state = getStateFromPath(path, options)
  console.log('LOG: > state:', state)

  const link = new URL('https://wallet.vatom.com' + path)

  const urlSearchParams = new URLSearchParams(link.search)
  urlSearchParams.delete('state')

  const isCommunityHome = pathToRegexp('/b/:business/c/:community').test(path)
  // TODO: Replace .includes and other weak checks with pathToRegexp
  if (
    !isCommunityHome &&
    state &&
    path.startsWith('/b/') &&
    (path.includes('/space/') || path.includes('/c/'))
  ) {
    return {
      routes: communitiesStack(state.routes[1])
    }
  }

  if (path.startsWith('/b/')) {
    const businessOptions = options
    if (businessOptions?.initialRouteName) {
      businessOptions.initialRouteName = AppRoutes.BusinessProxy
    }
    const businessState = getStateFromPath(path, businessOptions)
    const customState = customStack(state?.routes[1] ?? '')

    return customState
      ? {
          routes: customState
        }
      : businessState
  }

  if (path === '/') {
    return {
      routes: [
        {
          name: AppRoutes.home,
          state: {
            routes: [
              {
                name: TabRoutes.Wallet
              }
            ]
          }
        }
      ]
    }
  }

  return state
}

const customStack = (currentRoute: any) => {
  const params = currentRoute.params
  const stack = {
    [AppRoutes.profileOtherUser_Business]: [
      {
        name: AppRoutes.BusinessProxy,
        params: {
          business: params?.business
        },
        state: {
          routes: [
            {
              name: TabRoutes.Connect
            }
          ]
        }
      },
      currentRoute
    ]
  }
  // @ts-expect-error this is a weird one, not really sure how to fix. Somehow we need to check if the .name is 1 of the key of stack
  return stack[currentRoute.name]
}

const communitiesStack = (currentRoute: any) => {
  const params = currentRoute.params
  const createStack = (withCommunities: boolean) => {
    const keyName = withCommunities ? 'Communities' : ''
    return {
      [AppRoutes[`${keyName}Room`]]: [
        {
          name: AppRoutes.BusinessProxy,
          params: {
            business: params?.business
          },
          state: {
            routes: [
              {
                name: TabRoutes.Connect
              }
            ]
          }
        },
        currentRoute
      ],
      [AppRoutes[`${keyName}RoomMembers`]]: [
        {
          name: AppRoutes.BusinessProxy,
          params: {
            business: params?.business
          },
          state: {
            routes: [
              {
                name: TabRoutes.Connect
              }
            ]
          }
        },
        {
          name: AppRoutes[`${keyName}Room`],
          params: {
            business: params?.business,
            spaceId: params?.spaceId
          }
        },
        currentRoute
      ],
      [AppRoutes[`${keyName}RoomNewMessage`]]: [
        {
          name: AppRoutes.BusinessProxy,
          params: {
            business: params?.business
          },
          state: {
            routes: [
              {
                name: TabRoutes.Connect
              }
            ]
          }
        },
        {
          name: AppRoutes[`${keyName}Room`],
          params: {
            business: params?.business,
            spaceId: params?.spaceId
          }
        },
        currentRoute
      ],
      [AppRoutes[`${keyName}RoomThread`]]: [
        {
          name: AppRoutes.BusinessProxy,
          params: {
            business: params?.business
          },
          state: {
            routes: [
              {
                name: TabRoutes.Connect
              }
            ]
          }
        },
        {
          name: AppRoutes[`${keyName}Room`],
          params: {
            business: params?.business,
            spaceId: params?.spaceId
          }
        },
        currentRoute
      ],
      [AppRoutes[`${keyName}RoomReply`]]: [
        {
          name: AppRoutes.BusinessProxy,
          params: {
            business: params?.business
          },
          state: {
            routes: [
              {
                name: TabRoutes.Connect
              }
            ]
          }
        },
        {
          name: AppRoutes[`${keyName}Room`],
          params: {
            business: params?.business,
            spaceId: params?.spaceId
          }
        },
        {
          name: AppRoutes[`${keyName}RoomThread`],
          params: {
            business: params?.business,
            spaceId: params?.spaceId,
            messageId: params?.messageId
          }
        },
        currentRoute
      ]
    }
  }
  const stack = {
    ...createStack(true),
    ...createStack(false)
  }

  return stack[currentRoute.name]
}
