import { Network } from 'alchemy-sdk'

export const AlchemyNetworkScanHosts = {
  [Network.ETH_MAINNET]: 'https://etherscan.io',
  [Network.ETH_GOERLI]: 'https://goerli.etherscan.io',
  [Network.ETH_SEPOLIA]: 'https://sepolia.etherscan.io',
  [Network.OPT_MAINNET]: 'https://optimistic.etherscan.io',
  [Network.OPT_GOERLI]: 'https://goerli-optimistic.etherscan.io',
  [Network.OPT_SEPOLIA]: 'https://sepolia-optimistic.etherscan.io',
  [Network.ARB_MAINNET]: 'https://arbiscan.io',
  [Network.ARB_GOERLI]: 'https://goerli.arbiscan.io',
  [Network.ARB_SEPOLIA]: 'https://sepolia.arbiscan.io',
  [Network.MATIC_MAINNET]: 'https://polygonscan.com',
  [Network.MATIC_MUMBAI]: 'https://mumbai.polygonscan.com',
  [Network.MATIC_AMOY]: 'https://amoy.polygonscan.com',
  [Network.ASTAR_MAINNET]: 'https://blockscout.com/astar',
  [Network.POLYGONZKEVM_MAINNET]: 'https://zkevm.polygonscan.com',
  [Network.POLYGONZKEVM_TESTNET]: 'https://testnet-zkevm.polygonscan.com',
  [Network.BASE_MAINNET]: 'https://basescan.org',
  [Network.BASE_GOERLI]: 'https://goerli.basescan.org',
  [Network.BASE_SEPOLIA]: 'https://sepolia.basescan.org',
  [Network.ZKSYNC_MAINNET]: 'https://zkscan.io',
  [Network.ZKSYNC_SEPOLIA]: 'https://sepolia.zkscan.io'
}

export const vatomNetworkToAlchemyNetwork = {
  sepolia: Network.ETH_SEPOLIA
}

export const web3ChainListToAlchemyNetwork = {
  eth: Network.ETH_MAINNET,
  sepolia: Network.ETH_SEPOLIA,
  polygon: Network.MATIC_MAINNET,
  mumbai: Network.MATIC_MUMBAI
}

export const defaultNetworkSymbols = {
  [Network.ETH_MAINNET]: 'ETH',
  [Network.ETH_SEPOLIA]: 'ETH',
  [Network.OPT_MAINNET]: 'OPT',
  [Network.OPT_SEPOLIA]: 'OPT',
  [Network.ARB_MAINNET]: 'ARB',
  [Network.ARB_SEPOLIA]: 'ARB',
  [Network.MATIC_MAINNET]: 'MATIC',
  [Network.MATIC_AMOY]: 'MATIC',
  [Network.BASE_MAINNET]: 'BASE',
  [Network.BASE_SEPOLIA]: 'BASE',
  [Network.ZKSYNC_MAINNET]: 'ZKSYNC',
  [Network.ZKSYNC_SEPOLIA]: 'ZKSYNC'
}

export const defaultNetworks = [
  Network.ETH_MAINNET,
  Network.ETH_SEPOLIA,
  // Network.OPT_MAINNET,
  // Network.OPT_SEPOLIA,
  // Network.ARB_MAINNET,
  // Network.ARB_SEPOLIA,
  Network.MATIC_MAINNET
  // Network.MATIC_MUMBAI
  // Network.MATIC_AMOY
  // Network.BASE_MAINNET,
  // Network.BASE_SEPOLIA,
  // Network.ZKSYNC_MAINNET,
  // Network.ZKSYNC_SEPOLIA
]

export const networksWithoutTests = [
  Network.ETH_MAINNET,
  // Network.OPT_MAINNET,
  // Network.ARB_MAINNET,
  Network.MATIC_MAINNET
  // Network.BASE_MAINNET,
  // Network.ZKSYNC_MAINNET
]
