import { useCallback } from 'react'
import { Platform } from 'react-native'
import { useLinkTo } from '@react-navigation/native'
import { usePatchUserProfile, useUser } from '@vatom/sdk/react'
import { create } from 'zustand'

import { VatomWallet } from '../utils/WalletSdk'

interface WasVisitedStore {
  isVisited: boolean
  isDeeplink: boolean
}

export const useWasVisitedStore = create<WasVisitedStore>(set => ({
  isVisited: false,
  isDeeplink: false
}))

// auto navigate base on the last visited routes from '/me'
export const useLastVisitedRoute = () => {
  // TODO: review this
  const linkTo = useLinkTo()
  // useUser({
  //   refetchOnMount: true,
  //   select: (user): string => {
  //     return user?.lastVisitedRoute ?? ''
  //   },
  //   // onSuccess: (lastVisitedRoute: string) => {
  //   //   const { isVisited, isDeeplink } = useWasVisitedStore.getState()
  //   //   console.log(
  //   //     'lastVisitedRoute',
  //   //     Boolean(lastVisitedRoute && !isVisited && !isDeeplink),
  //   //     lastVisitedRoute,
  //   //     isVisited,
  //   //     isDeeplink
  //   //   )
  //   //   // if (isDifferentDeepLink(lastVisitedRoute)) {
  //   //   //   return
  //   //   // }
  //   //   // TODO: We might want a similar handling for mobile
  //   //   const isRoot = Platform.OS === 'web' ? window.location.pathname === '/' : true

  //   //   if (lastVisitedRoute && !isVisited && !isDeeplink && isRoot) {
  //   //     useWasVisitedStore.setState({ isVisited: true })
  //   //     linkTo(lastVisitedRoute)
  //   //   }
  //   // },
  //   // enabled: !VatomWallet.isEmbedded()
  // })
}

export const useLastVisitedRouteMutation = () => {
  const patchUserMutation = usePatchUserProfile()

  return useCallback(
    (businessId: string) => {
      if (VatomWallet.isEmbedded()) {
        return
      }
      patchUserMutation.mutate({ lastVisitedRoute: businessId ? `/b/${businessId}` : '' })
    },
    [patchUserMutation]
  )
}
