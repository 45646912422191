import { View } from 'react-native'
import { type Route } from '@react-navigation/native'
import { useAuthCode } from '@vatom/sdk/auth'
import { type AppStackParamList } from '@vatom/wallet-routes'

import { ThemedLoaderView } from '../../components/ThemedLoader'

export interface ILoginCallback {
  route: Route<string, AppStackParamList['LoginCallback']>
}

export const LoginCallback = ({ route }: ILoginCallback) => {
  console.log('LOG: > LoginCallback > route:', route)

  useAuthCode(route.params?.code)

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <ThemedLoaderView />
    </View>
  )
}

export default LoginCallback
