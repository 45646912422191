import { Platform } from 'react-native'

import { useUser } from '../store'

const isWeb = Platform.OS === 'web'

const partialEmails = [
  'jeff',
  'luis.palacios',
  'alistair',
  'colin',
  'yurki',
  'ezequiel.benitez',
  'kevin.delacruz',
  'yerim.morales',
  'ashish'
]
const completeEmails = ['luis@pineapples.dev', 'yerim.morales@bahia360.mx']

const domains = ['vatom.com', 'vatominc.com']

const getShouldShowDebugPanel = (userEmail?: string) => {
  if (!userEmail) return false
  const emailParts = userEmail.split('@')
  const email = emailParts[0]
  const domain = emailParts[1]

  const shouldShowDebugPanel =
    completeEmails.includes(userEmail) ||
    (partialEmails.some(e => email.includes(e)) && domains.includes(domain))

  return shouldShowDebugPanel
}

export function isDebugMode(userEmail?: string) {
  return __DEV__ || getShouldShowDebugPanel(userEmail)
}

export const useDebugMode = () => {
  const { data: user } = useUser()
  return !isWeb ? false : isDebugMode(user?.email)
}
