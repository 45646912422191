import axios, { type AxiosInstance, type AxiosPromise } from 'axios'

type RefreshAuth = (error: any) => Promise<void>

type TempCache = {
  skipInstances: AxiosInstance[]
  refreshCall: Promise<void> | undefined
  requestQueueInterceptorId: number | undefined
}

const interceptorsConfig = {
  statusCode: [401]
}

function shouldInterceptError(error: any, instance: AxiosInstance, cache: TempCache) {
  if (!error) {
    return false
  }
  // NOTE: important flag
  if (error.config?.skipAuthRefresh) {
    return false
  }
  if (
    !(!error.response && error.request.status === 0) &&
    (!error.response || !interceptorsConfig.statusCode.includes(parseInt(error.response.status)))
  ) {
    return false
  }

  return !cache.skipInstances.includes(instance)
}

function createRefreshCall(error: any, refreshAuth: RefreshAuth, cache: TempCache) {
  if (!cache.refreshCall) {
    cache.refreshCall = refreshAuth(error)
    if (typeof cache.refreshCall.then !== 'function') {
      console.warn('requires `refreshAuth` to return a promise')
      return Promise.reject()
    }
  }
  return cache.refreshCall
}

function resendFailedRequest(error: any, instance: AxiosInstance): AxiosPromise {
  error.config.skipAuthRefresh = true
  return instance(error.config)
}

function createRequestQueueInterceptor(instance: AxiosInstance, cache: TempCache) {
  if (typeof cache.requestQueueInterceptorId === 'undefined') {
    cache.requestQueueInterceptorId = instance.interceptors.request.use(request => {
      if (!cache.refreshCall) {
        return request
      }
      return cache.refreshCall
        .catch(() => {
          throw new axios.Cancel('Request call failed')
        })
        .then(() => {
          return request
        })
    })
  }
  return cache.requestQueueInterceptorId
}

function unsetCache(instance: AxiosInstance, cache: TempCache): void {
  cache.requestQueueInterceptorId &&
    instance.interceptors.request.eject(cache.requestQueueInterceptorId)
  cache.requestQueueInterceptorId = undefined
  cache.refreshCall = undefined
  cache.skipInstances = cache.skipInstances.filter(skipInstance => skipInstance !== instance)
}

export function createAuthInterceptor({
  axiosInstance,
  refreshAuth
}: {
  axiosInstance: AxiosInstance
  refreshAuth: RefreshAuth
}) {
  if (typeof refreshAuth !== 'function') {
    throw new Error('refreshAuth requires to be a function that returns a promise')
  }
  // Create a temp cache to store request while refreshing token
  const tempCache: TempCache = {
    skipInstances: [],
    refreshCall: undefined,
    requestQueueInterceptorId: undefined
  }

  return axiosInstance.interceptors.response.use(
    response => response,
    async error => {
      if (!shouldInterceptError(error, axiosInstance, tempCache)) {
        return Promise.reject(error)
      }
      // pause instance while refreshing
      tempCache.skipInstances.push(axiosInstance)

      const refreshing = createRefreshCall(error, refreshAuth, tempCache)

      createRequestQueueInterceptor(axiosInstance, tempCache)

      return await refreshing
        .catch(error => Promise.reject(error))
        .then(() => resendFailedRequest(error, axiosInstance))
        .finally(() => unsetCache(axiosInstance, tempCache))
    }
  )
}
