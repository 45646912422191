import { useMutation } from '@tanstack/react-query'
import { UserService } from '@vatom/sdk/services'

import { SDKQueryClient } from '../../queryClient'

import { usersKeys } from './keys'

export const usePatchUserProfile = () => {
  return useMutation({
    mutationFn: UserService.updateUser,
    onSuccess: () => {
      SDKQueryClient.invalidateQueries({
        queryKey: usersKeys.me.queryKey
      })
    }
  })
}

export const useUserCampaignInfoMutation = () => {
  return useMutation({
    mutationFn: UserService.updateCampaignInfo,
    onSuccess: () => {
      SDKQueryClient.invalidateQueries({
        queryKey: usersKeys.me._ctx.campaignInfo._def
      })
    }
  })
}
