import type { PropsWithChildren } from 'react'
import { createContext, useCallback, useContext, useEffect, useMemo } from 'react'
import { Platform } from 'react-native'
import type { ParamListBase } from '@react-navigation/native'
import type { NativeStackScreenProps } from '@react-navigation/native-stack'
import type { VatomToken } from '@vatom/models'
import type { BlockVToken } from '@vatom/models/blockV'
import type { BusinessCommunities, BusinessSnapshot } from '@vatom/sdk/core'
import type { BusinessData } from '@vatom/sdk/react'
import {
  getConfig,
  isAlchemyNft,
  isBlockVNft,
  isVatomNft,
  useAppConfig,
  useBusiness,
  useCommunities,
  useConfig,
  useFilterBlackList,
  useWalletInventory
} from '@vatom/sdk/react'
import { useColorMode } from 'native-base'

import { findKeyInParams } from '../navigators'

import { useBusinessThemeConfig } from './useBusinessTheme'

export type BusinessWithCommunities = BusinessSnapshot & { communities: BusinessCommunities[] }

// Will show businesses defined in the config even if the user doesn't own tokens from them
// const useBusinessItems = () => {
//   const config = useConfig()
//   const { data: businessIds } = useBusinessesUser()
//   const ids = [...config.subBusinesses, ...(businessIds ?? [])]
//   const { data: businesses } = useBusinesses(ids)
//   return businesses ?? []
// }

type InventoryByBusiness = {
  defer?: boolean
  businessId?: string
}
type InventoryByBusinessResult = ReturnType<typeof useWalletInventory>
type TokenType = VatomToken | BlockVToken
/**
 * @deprecated
 */
export const useInventoryByBusiness = ({
  defer
}: InventoryByBusiness = {}): InventoryByBusinessResult => {
  const inventory = useWalletInventory()
  const config = useConfig()
  const filterByBlackList = useFilterBlackList()
  const { business, businessIdentifier, isLoading } = useBusinessSelector()

  if (!businessIdentifier) {
    // No selected Business so return all tokens
    return inventory
  }

  const businessId = business?.id ?? businessIdentifier
  const businessTokens = inventory.data.filter(t => {
    if (isAlchemyNft(t)) {
      return false
    }

    const isVatom = isVatomNft(t) || isBlockVNft(t)
    // is same businessId
    if (isVatom && t.studioInfo?.businessId === businessId) {
      return true
    }

    const isJoyAppHack =
      t.type === 'vatom' && (businessId === 'Cox1qh6ggb' || businessId === 'QkxH4IbOIl')

    // if (isJoyAppHack && isBlockVNft(t)) {
    //   return t['vAtom::vAtomType'].publisher_fqdn === 'mx.joyapp'
    // }

    const isCustomDomainRoot = config.isBusinessLocked && config.businessId === businessIdentifier

    if (isVatom && isCustomDomainRoot && t.studioInfo?.businessId) {
      return config.subBusinesses.includes(t.studioInfo.businessId)
    }

    return false
  }) as TokenType[]
  // const tokens = businessTokens.filter(t => {
  //   const vatom = isBlockVNft(t) ? t : false
  //   if (!vatom) return true
  //   return vatom.properties.parent_id === '.'
  // })

  // TODO: fix black list
  // const tokensFiltered = businessTokens ? filterByBlackList(businessTokens) : []
  // Move pinned objects to the beginning
  const pinnedTokenIds = business?.pageConfig?.features?.vatom?.pinned ?? []

  const finalTokens = businessTokens.sort((a, b) => {
    if (
      a.studioInfo?.objectDefinitionId &&
      pinnedTokenIds.includes(a.studioInfo?.objectDefinitionId)
    ) {
      return -1
    }

    // Honda hack to pin coupon first
    const hondaViewIds = ['GJmxWCIKSo', '8WjdTi4m4z']
    const aIsHondaHack =
      a.studioInfo?.businessId === 'jEnLk2u9l3' &&
      hondaViewIds.includes(
        (a as unknown as VatomToken)?.viewPlacements?.find(v => v?.placeholder?.id === 'card-v1')
          ?.view?.id ?? ''
      )
    const bIsHondaHack =
      b.studioInfo?.businessId === 'jEnLk2u9l3' &&
      hondaViewIds.includes(
        (b as unknown as VatomToken)?.viewPlacements.find(v => v?.placeholder?.id === 'card-v1')
          ?.view?.id ?? ''
      )

    if (aIsHondaHack) {
      return -1
    }
    if (bIsHondaHack) {
      return 1
    }

    // const aTarget = a.modified ?? a.created
    // const bTarget = b.modified ?? b.created
    const aTarget = new Date(a.createdAt)
    const bTarget = new Date(b.createdAt)
    try {
      if (aTarget.getTime() < bTarget.getTime()) {
        return 1
      }
      if (aTarget.getTime() > bTarget.getTime()) {
        return -1
      }

      return 0
    } catch (error) {
      console.error('Error sorting', error)
      return 0
    }
  })

  return {
    ...inventory,
    filteredData: finalTokens,
    isLoading: inventory?.isLoading || isLoading
  }
}

type BusinessSelectorContextProps = {
  business: BusinessData | undefined
  businessIdentifier: string | undefined
  communities: BusinessCommunities[] | undefined
  communityId?: string | undefined
  isLoading: boolean
}
const BusinessSelectorContext = createContext({
  business: undefined,
  businessIdentifier: undefined,
  communities: undefined,
  communityId: undefined,
  isLoading: false
} as BusinessSelectorContextProps)

type BusinessSelectorProviderProps = PropsWithChildren<{
  businessIdentifier?: string
}>
const BusinessSelectorProvider = ({
  children,
  businessIdentifier: parentBusinessIdentifier
}: BusinessSelectorProviderProps) => {
  const config = useAppConfig()
  const businessIdentifier = config.data?.isBusinessLocked
    ? parentBusinessIdentifier ?? config.data?.businessId
    : parentBusinessIdentifier

  const { data: business, isLoading: isLoadingBusiness } = useBusiness({
    business: businessIdentifier,
    businessId: businessIdentifier,
    join: true
  })
  const communities = useCommunities(business?.id ?? '')

  const isLoading = config.isLoading || Boolean(businessIdentifier && isLoadingBusiness)

  const businessTheme = useBusinessThemeConfig({
    pageConfig: business?.pageConfig,
    brandConfig: business?.brandConfig
  })
  // COLOR MODE
  const { setColorMode } = useColorMode()
  const setThemeMode = useCallback(() => {
    if (isLoading && businessIdentifier) {
      // is loading stay the same until change
      return
    }
    // if (!isLoading && !businessIdentifier) {
    //   setColorMode(defaultColorMode)
    //   setColorStatusBarWebkit(businessTheme.pageTheme.background)
    //   return
    // }

    const colorMode = businessTheme.isDark ? 'dark' : 'light'
    setColorMode(colorMode)
    const headerStatusBarColor = business?.pageConfig?.features?.header?.brandedBackground
      ? businessTheme?.brandTheme?.primary
      : businessTheme?.brandTheme?.background
    setColorStatusBarWebkit(headerStatusBarColor ?? businessTheme.pageTheme.background)
    // if (!isLoading && businessIdentifier) {
    //   return
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessIdentifier, businessTheme, isLoading, setColorMode])

  useEffect(() => {
    setThemeMode()
  }, [setThemeMode])

  return (
    <BusinessSelectorContext.Provider
      value={{
        businessIdentifier,
        business,
        communities: communities.data,
        isLoading: isLoading
      }}
    >
      {children}
    </BusinessSelectorContext.Provider>
  )
}

const setColorStatusBarWebkit = (color: string) => {
  if (Platform.OS === 'web') {
    const metaTag = document.querySelector('meta[name="theme-color"]')
    metaTag?.setAttribute('content', color)
  }
}

const withBusinessSelector =
  <P extends NativeStackScreenProps<ParamListBase>>(Component: React.ComponentType<P>) =>
  (props: P) => {
    const businessKey = 'business'
    const config = getConfig()
    const businessIdFromConfig = config.isBusinessLocked ? config.businessId : undefined
    const businessIdentifier =
      businessIdFromConfig && config.subBusinesses?.length === 0
        ? businessIdFromConfig
        : findKeyInParams(props?.route, businessKey) || undefined

    return (
      <BusinessSelectorProvider businessIdentifier={businessIdentifier}>
        <Component {...props} />
      </BusinessSelectorProvider>
    )
  }

const useBusinessSelector = () => {
  const ctx = useContext(BusinessSelectorContext) as BusinessSelectorContextProps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoed = useMemo(() => ctx, [ctx.business?.id, ctx.businessIdentifier])
  return memoed
}

export { BusinessSelectorProvider, useBusinessSelector, withBusinessSelector }
