import { Platform } from 'react-native'
import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

// import { AppConfig, AppConfigInput } from '@vatom/models'
import { SDKQueryClient } from '../../queryClient'

import type { AppConfig, AppConfigInput } from './models'
import { AppConfigurationSchema } from './models'

export const appConfigQueryKey = ['remoteConfig'] as const

export type AppConfigQueryOptions<T> = Omit<
  UseQueryOptions<AppConfig, unknown, T, typeof appConfigQueryKey>,
  'queryKey' | 'queryFn'
>

const getHostnameForConfig = () => {
  const defaulthostsClients = ['wallet.vatominc.com', 'wallet.vatom.com', 'wallet.localhost:3000']
  const defaulthost = 'wallet.vatom.com'

  const shouldUseDefaultHost =
    Platform.OS !== 'web' || defaulthostsClients.includes(window.location.host)

  if (shouldUseDefaultHost) {
    return defaulthost
  }

  return window.location.host
}

async function fetchConfig() {
  const defaultRemoteConfigUrl = `https://businesses.api.vatominc.com/viewers/config?hostname=${getHostnameForConfig()}`

  const res = await fetch(defaultRemoteConfigUrl)
  const data = (await res.json()) as AppConfigInput
  // data.businessId = 'nCHNthBpv7'
  // data.subBusinesses = ['nZyOolWbB4']
  // data.businessId = 'dZCHAOE76T' //  zprize test
  // data.businessId = 'rIEWFSgB7s' // xprize demo
  // data.businessId = 'nQwtevgfOa' //  vatom demo
  // data.businessId = '864tfHPWsw' //  vodacom

  const validated = AppConfigurationSchema.safeParse(data)
  if (validated.success) {
    return validated.data
  } else {
    console.error('Invalid config response', validated.error)
    return data as AppConfig
  }
}

export function configNftsReactQuery() {
  return true
}

export const prefetchConfig = async () => {
  await SDKQueryClient.prefetchQuery({
    queryKey: appConfigQueryKey,
    queryFn: fetchConfig,
    staleTime: 60 * 1000,
    cacheTime: Infinity
  })
}

export const getConfig = () =>
  SDKQueryClient.getQueryData<AppConfig>(appConfigQueryKey) ?? AppConfigurationSchema.parse({})

export const useAppConfig = <T = AppConfig>(options?: AppConfigQueryOptions<T>) => {
  const query = useQuery({
    queryKey: appConfigQueryKey,
    queryFn: fetchConfig,
    keepPreviousData: true,
    staleTime: 60 * 1000,
    cacheTime: Infinity,
    ...options
  })
  return query
}

export const ensureAppConfig = async () => {
  const config = await SDKQueryClient.ensureQueryData<AppConfig>({
    queryKey: appConfigQueryKey,
    queryFn: fetchConfig
  })
  return config
}
