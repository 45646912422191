import type { Query } from '@tanstack/react-query'
import { ArraySafeParseAsyncFromSchema, ArraySafeParseFromSchema } from '@vatom/models'
import type {
  ActionPayload,
  BlockVResponsePayload,
  BlockVToken,
  FacePayload
} from '@vatom/models/blockV'
import { BlockVTokenModel, BlockVUtils } from '@vatom/models/blockV'
import { VatomTokenSchema } from '@vatom/models/vatom'
import type { UserInventoryResponse } from '@vatom/sdk/services'
import {
  BlockVService,
  UserInventoryService,
  vatomAxiosInstance,
  VatomTokenService
} from '@vatom/sdk/services'
import type { Network, OwnedNft, OwnedNftsValidAt } from 'alchemy-sdk'

import { SDKQueryClient } from '../../queryClient'
import { getAlchemyClient } from '../alchemy'
import { getConfig } from '../appConfig'

import { alchemyInventoryKeys, blockVInventoryKeys, vatomInventoryKeys } from './keys'
import { useWalletPagination } from './queries'
import type { AlchemyByIdParams, AlchemyListOptions, AlchemyNft } from './types'

const transformAlchemyToken = (token: OwnedNft, address: string, network: Network): AlchemyNft => {
  return {
    ...token,
    address,
    network,
    type: 'alchemy',
    id: token.tokenId
  }
}

// type FetchAlchemyData = AlchemyNftResponse
type FetchAlchemyData = {
  ownedNfts: AlchemyNft[]
  pageKey?: string | undefined
  totalCount: number
  validAt: OwnedNftsValidAt
}

const fetchPage = async ({
  pageKey,
  network,
  address,
  limit
}: {
  pageKey?: string
  network: Network
  address: string
  limit: number
}) => {
  const alchemy = getAlchemyClient(network)
  const res = await alchemy.nft.getNftsForOwner(address, {
    pageKey,
    pageSize: limit
  })

  const data = res.ownedNfts.map(t => transformAlchemyToken(t, address, network))
  return {
    ...res,
    ownedNfts: data
  } satisfies FetchAlchemyData
}

export const fetchAlchemyNfts = async (options: AlchemyListOptions): Promise<FetchAlchemyData> => {
  const { network, address, page: _page, limit } = options

  const page = _page === 0 ? 1 : _page

  const queryKey = alchemyInventoryKeys.list({ page: page - 1, address, network, limit }).queryKey
  const previousPageData = SDKQueryClient.getQueryData<FetchAlchemyData>(queryKey)

  if (page === 1 || !previousPageData) {
    // fetch first page
    const newPageResult = await fetchPage({ pageKey: undefined, network, address, limit })
    newPageResult.ownedNfts = [...newPageResult.ownedNfts]
    return newPageResult
  }

  if (previousPageData && !previousPageData.pageKey) {
    // no next page, return cache
    return previousPageData
  }

  const newPageResult = await fetchPage({
    pageKey: previousPageData.pageKey,
    network,
    address,
    limit
  })

  if (newPageResult) {
    SDKQueryClient.removeQueries({
      queryKey: alchemyInventoryKeys.list._def,
      type: 'inactive',
      predicate: (query: Query) => {
        const [, , queryParams] = query.queryKey as ReturnType<
          typeof alchemyInventoryKeys.list
        >['queryKey']

        const isPrevious = queryParams.page < page - 2
        const isNext = queryParams.page > page

        return (
          (isPrevious || isNext) &&
          queryParams.address === address &&
          queryParams.network === network
        )
      }
    })
  }

  // merge results
  newPageResult.ownedNfts = [...previousPageData.ownedNfts, ...newPageResult.ownedNfts]

  return {
    ...newPageResult,
    ownedNfts: newPageResult.ownedNfts
  }
}

export function invalidateInventory(queryClient = SDKQueryClient) {
  queryClient.invalidateQueries([
    alchemyInventoryKeys.list._def,
    blockVInventoryKeys.list._def,
    vatomInventoryKeys.list._def
  ])
  useWalletPagination.setState({ page: 1 })
}

function getPreviousDataVatomInventory({ page, limit }: { page: number; limit: number }) {
  const previousPageData: UserInventoryResponse | undefined =
    SDKQueryClient.getQueryData<UserInventoryResponse>(
      vatomInventoryKeys.list({ page, limit }).queryKey
    )
  return previousPageData
}

export async function getVatomInventory({
  page,
  limit
}: {
  page: number
  limit: number
}): Promise<UserInventoryResponse> {
  const previousData = getPreviousDataVatomInventory({ page, limit: limit })

  if (previousData) {
    if (previousData.nextCursor === 'end') {
      // No more pages
      return previousData as unknown as UserInventoryResponse
    }
  }

  // TODO: use cursor
  const data = await UserInventoryService.fetchUserInventory()

  if (data.items.length === 0) {
    return data
  }

  const parsed = await ArraySafeParseAsyncFromSchema(VatomTokenSchema, data.items)
  parsed.errors.length > 0 && console.warn('getVatoms: parse errors', parsed.errors)

  // TODO: merge new data with previousData

  return {
    items: parsed.data,
    nextCursor: data.nextCursor
  }
}

export const fetchVatomNft = async (tokenId: string) => {
  const config = getConfig()
  const res = await vatomAxiosInstance.get<UserInventoryResponse>(
    `${config.api.network}/vatoms/${tokenId}`
  )
  const data = res.data
  return data
}

type FetchBlockVNfts = {
  parentId?: string
  businessId?: string
  page?: number
  limit?: number
  sortBy?: 'when_modified' | 'when_created' | 'name'
  sortOrder?: 'asc' | 'desc'
}

const defaultFetchTokensParams = {
  // parentId: '.',
  businessId: '',
  page: 0,
  limit: 50,
  sortBy: 'when_modified',
  sortOrder: 'desc'
} satisfies FetchBlockVNfts

type InventoryResponse = {
  payload: InventoryPayload
  message: any
}
type InventoryPayload = {
  actions: ActionPayload[]
  faces: FacePayload[]
  vatoms: BlockVResponsePayload[]
  hits: number
}

export type BlockVFetch = {
  items: BlockVToken[]
  hits: number
}

export const fetchBlockVNfts = async (params?: FetchBlockVNfts) => {
  const config = getConfig()

  const mergedParams = {
    ...defaultFetchTokensParams,
    ...params
  }
  const { parentId, businessId, page: _page, limit, sortBy, sortOrder } = mergedParams

  const page = _page === 0 ? 1 : _page

  const previousPageData = SDKQueryClient.getQueryData<BlockVFetch>(
    blockVInventoryKeys.list({ page: page - 1, limit }).queryKey
  )
  // if (page === 1 || !previousPageData) {
  //   // first page, no data. fetch
  //   // nothing to do
  // }

  const payload = {
    // parent_id: parentId,
    business_id: businessId,
    limit,
    page: page - 1,
    sort_by: sortBy,
    sort_order: sortOrder
  }

  const { data } = await vatomAxiosInstance.post<InventoryResponse>(
    `${config.api.vatoms}/me/inventory`,
    JSON.stringify(payload)
  )

  // No payload in response > error
  if (!data?.payload) {
    return {
      items: [],
      hits: 0
    }
  }

  const tokens = BlockVUtils.mergeResponsePayloads(data.payload)
  const { errors, data: newTokens } = ArraySafeParseFromSchema(BlockVTokenModel, tokens)
  errors.length > 0 && console.warn('fetchBlockVNfts: parse errors', errors)

  if (previousPageData) {
    // Remove previous queries
    // The data is going to be merge in the response
    SDKQueryClient.removeQueries({
      queryKey: blockVInventoryKeys.list._def,
      type: 'inactive',
      predicate: (query: Query) => {
        const [, , queryParams] = query.queryKey as ReturnType<
          typeof blockVInventoryKeys.list
        >['queryKey']

        const isPrevious = queryParams.page < page - 2
        const isNext = queryParams.page > page

        return isPrevious || isNext
      }
    })
  }

  const items: BlockVFetch['items'] = previousPageData?.items
    ? [...previousPageData.items, ...newTokens]
    : newTokens

  return {
    items,
    hits: data.payload.hits
  }
}

export const fetchVatomById = async (tokenId: string) => {
  const response = await VatomTokenService.fetchById(tokenId)
  return VatomTokenSchema.parse(response)
}

export const fetchAlchemyById = async ({ id, network, address }: AlchemyByIdParams) => {
  const alchemy = getAlchemyClient(network)
  const data = await alchemy.nft.getNftMetadata(address, id)
  // NOTE: missing balance key in getNftMetadata response = Nft
  return transformAlchemyToken({ ...data, balance: '' }, address, network)
}

export const fetchBlockVById = async (tokenId: string) => {
  const response = await BlockVService.fetchByIds([tokenId])
  console.log('LOG: > fetchBlockVById > response:', response)
  const [token] = BlockVUtils.mergeResponsePayloads(response)
  console.log('LOG: > fetchBlockVById > token:', token)
  return BlockVTokenModel.parse(token)
}

export const fetchBlockVByIds = async (tokenIds: string[]) => {
  const response = await BlockVService.fetchByIds(tokenIds)

  const tokens = BlockVUtils.mergeResponsePayloads(response)
  const { errors, data } = ArraySafeParseFromSchema(BlockVTokenModel, tokens)
  errors.length > 0 && console.warn('fetchBlockVByIds: blockV parse errors', errors)

  return data
}
