import { z } from 'zod'

import { getFeaturedImage } from './VatomModelUtils'

const StateSchema = z.object({
  'studio-info-v1': z.object({
    businessId: z.string(),
    campaignId: z.string(),
    objectDefinitionId: z.string(),
    blueprintId: z.string().optional()
  }),
  'share-v1': z
    .object({
      shareUrl: z.string().nullish(),
      score: z.any().nullish(),
      shareTargetUri: z.string().nullish(),
      shareTargetUrl: z.string().nullish()
    })
    .optional(),
  'map-ready-v1': z
    .object({
      location: z
        .object({
          lat: z.number(),
          lon: z.number()
        })
        .optional(),
      businessId: z.string().optional(),
      campaignId: z.string().optional(),
      objectDefinitionId: z.string().optional()
    })
    .optional(),
  'meta-v1': z
    .object({
      displayName: z.string(),
      description: z.string(),
      category: z.string().nullable().optional(),
      isDraft: z.boolean()
    })
    .optional(),
  'map-ready-poi-business-v1': z
    .object({
      hoursText: z.string().optional()
    })
    .optional(),
  'link-v1': z
    .object({
      location: z.string().optional(),
      blank: z.boolean().optional()
    })
    .optional(),
  'pepsi-store-prizes-v1': z
    .object({
      numPrizesAvailable: z.number().optional()
    })
    .optional(),
  'gft-reward-v1': z
    .object({
      gftCampaignId: z.string().optional(),
      gftEnvironment: z.string().optional(),
      couponId: z.string().optional(),
      status: z.string().optional()
    })
    .optional(),
  'transferable-v1': z.object({}).optional(),
  'cloneable-v1': z
    .object({
      cloneScore: z.number(),
      landing_page: z.string(),
      perCloneGain: z.number(),
      parentVatomId: z.string().optional(),
      maxClonesTotal: z.number(),
      maxClonesPerInstance: z.number()
    })
    .optional(),
  'quiz-v2': z
    .object({
      answerOptions: z.array(z.string()).optional().default([]),
      clue: z.string().optional(),
      lastPlayed: z.string().optional(),
      question: z.string().optional(),
      status: z.string().optional(),
      solved: z.boolean().optional()
    })
    .optional(),
  'poll-v1': z
    .object({
      lastReplied: z.string().optional(),
      question: z.string().optional(),
      response: z.string().optional(),
      responseOptions: z.array(z.string()).optional(),
      results: z.object({}).optional()
    })
    .optional(),
  'game-of-chance-v1': z
    .object({
      error: z
        .object({
          code: z.number().optional(),
          message: z.string().optional()
        })
        .optional(),
      lastSentPrizeAt: z.string().optional(),
      maxPlaysPerPeriod: z.number().optional(),
      playPeriod: z.string().optional(),
      playPeriodTimezone: z.string().optional(),
      prizeDescription: z.string().optional(),
      prizeIcon: z.string().optional(),
      prizeName: z.string().optional(),
      prizeToken: z.string().optional(),
      singleUse: z.boolean().optional(),
      prizeExchange: z.boolean().optional(),
      playCount: z.number().optional(),
      prizeSet: z
        .array(
          z.object({
            prizeDescription: z.string().optional(),
            prizeIcon: z.string().optional(),
            prizeName: z.string().optional()
          })
        )
        .optional()
    })
    .optional(),
  'coupon-v1': z
    .object({
      availableToGuestUser: z.boolean().optional(),
      code: z.string().optional(),
      couponUrl: z.string().optional(),
      enabled: z.boolean().optional(),
      expiry: z.string().optional(),
      id: z.string().optional(),
      pin: z.string().optional(),
      rule: z.string().optional(),
      value: z.union([z.string(), z.number()]).optional(),
      provider: z
        .object({
          providerId: z.string().optional()
        })
        .optional()
    })
    .optional(),
  'form-v1': z
    .object({
      data: z
        .object({
          Name: z.string().optional()
        })
        .optional(),
      dataAsString: z.string().optional(),
      globalResponseLimit: z.number().optional(),
      responseCount: z.number().optional(),
      submitted: z.boolean().optional(),
      submitted_at: z.string().optional()
    })
    .optional(),
  'honda-reward-v1': z
    .object({
      redeemed: z.boolean().optional(),
      redeemedAt: z.string().optional(),
      expiration_date: z.string().optional()
    })
    .optional()
})

const ViewConfigInnerSchema = z.object({
  type: z.string(),
  ref: z.string()
})

const ViewConfigImageSchema = z.object({
  image: ViewConfigInnerSchema
})

const StateConfigRuleSchema = z
  .object({
    statePointer: z.string().optional(),
    isEqualTo: z.number().optional()
  })
  .optional()
  .default({})

const ViewConfigStatesSchema = z.object({
  states: z.array(
    z.object({
      image: ViewConfigInnerSchema,
      name: z.string(),
      rule: StateConfigRuleSchema
    })
  )
})

const ThreeDV1Schema = z.object({
  id: z.literal('3d-v1'),
  entrypoint: z.string().default('3D'),
  config: z.object({
    scene: ViewConfigInnerSchema
  })
})

export type ThreeDV1 = z.infer<typeof ThreeDV1Schema>
export const ThreeDV1 = (v: PlacementView): ThreeDV1 => v as ThreeDV1

export function isThreeDV1(view: PlacementView): view is ThreeDV1 {
  return view.id === '3d-v1'
}

//
const ImageV1Schema = z.object({
  id: z.literal('image-v1'),
  config: ViewConfigImageSchema
})
export type ImageV1 = z.infer<typeof ImageV1Schema>
export const ImageV1 = (v: PlacementView): ImageV1 => v as ImageV1

export function isImageV1(view: PlacementView): view is ImageV1 {
  return view.id === 'image-v1'
}

//
export const DynamicImageV1Schema = z.object({
  id: z.literal('dynamic-image-v1'),
  config: ViewConfigStatesSchema
})
export type DynamicImageV1 = z.infer<typeof DynamicImageV1Schema>
export const DynamicImageV1 = (v: PlacementView): DynamicImageV1 => v as DynamicImageV1

export function isDynamicImageV1(view: PlacementView): view is DynamicImageV1 {
  return view.id === 'dynamic-image-v1'
}

//
const CardViewV1Schema = z.object({
  id: z.string(),
  entrypoint: z.string(),
  config: z.any()
})
export type CardViewV1 = z.infer<typeof CardViewV1Schema>
export const CardViewV1 = (v: PlacementView): CardViewV1 => v as CardViewV1

export function isCardViewV1(view: PlacementView): view is CardViewV1 {
  return view.id === 'card-v1'
}

const PlacementViewSchema = z.union([
  CardViewV1Schema,
  ImageV1Schema,
  DynamicImageV1Schema,
  ThreeDV1Schema
])
type PlacementView = z.infer<typeof PlacementViewSchema>

const ViewPlacementsSchema = z.object({
  placeholder: z.object({
    id: z.string()
  }),
  view: PlacementViewSchema
})
export type ViewPlacements = z.input<typeof ViewPlacementsSchema>

export type VatomTokenId = VatomToken['id']
export const isVatomTokenId = (id: string): id is VatomTokenId => true

const VatomPayloadSchema = z.object({
  // id: z.string().refine(isVatomTokenId),
  id: z.string().brand<'VatomTokenId'>(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime().nullable(),
  openedAt: z.string().datetime().nullable(),
  state: StateSchema,
  viewPlacements: ViewPlacementsSchema.array()
  //   parentId: z.string(),
  //   owner: z.string(),
  //   author: z.string(),
  //   lastOwner: z.string().optional(),
})
export type VatomPayload = z.infer<typeof VatomPayloadSchema>

export const VatomTokenSchema = VatomPayloadSchema.transform(vatom => {
  return {
    ...vatom,
    type: z.literal('vatom').value, // ex: vatom-new
    get studioInfo() {
      return vatom.state['studio-info-v1']
    },
    get metadata() {
      const meta = vatom.state['meta-v1']

      return {
        name: meta?.displayName ?? '',
        description: meta?.description ?? '',
        image: getFeaturedImage(vatom) ?? '',
        category: meta?.category ?? ''
      }
    }
    // get isMinted() {
    //   return false
    // }
  }
}).brand<'VatomToken'>()

export function isVatomToken(token: unknown): token is VatomToken {
  const parsed = VatomTokenSchema.safeParse(token)
  !parsed.success && console.warn('isVatomToken: parse error', parsed.error)
  return parsed.success
}

export type VatomToken = z.infer<typeof VatomTokenSchema>
// export type VatomModelType = z.infer<typeof VatomTokenSchema>
// export type VatomModelInput = z.input<typeof VatomTokenSchema>

export type StateSchemaType = z.input<typeof StateSchema>

type StateKey = keyof StateSchemaType
export const StatePointers = (pointers: string[]) => pointers as unknown[] as StateKey[]
