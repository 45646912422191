import React from 'react'
import { Text } from '@vatom/wombo'

// Inter-Semibold
// fontWeight="600"

// Inter
// fontWeight: 400

// Inter-Bold
// fontWeight: 700

// Inter-Light
// fontWeight: 300

// Inter-Thin
// fontWeight: 200

const TitlePresets = {
  h1: {
    //styleName: Title/H1;
    fontFamily: 'Inter',
    fontSize: 34,
    lineHeight: 41
  },
  h2: {
    //styleName: Title/H2;
    fontFamily: 'Inter',
    fontSize: 28,
    lineHeight: 34
  },
  h3: {
    //styleName: Title/H3;
    fontFamily: 'Inter',
    fontSize: 22,
    lineHeight: 28
  },
  h4: {
    //styleName: Title/H4;
    fontFamily: 'Inter',
    fontSize: 20,
    lineHeight: 28
  },
  h5: {
    //styleName: Title/H5;
    fontFamily: 'Inter',
    fontSize: 17,
    lineHeight: 24
  },
  h6: {
    //styleName: Title/H6;
    fontFamily: 'Inter',
    fontSize: 14,
    lineHeight: 18
  }
} as const

type TitlePresetType = keyof typeof TitlePresets

const TitleVariants = {
  thin: {
    fontFamily: 'Inter',
    fontWeight: 200
  },
  light: {
    fontFamily: 'Inter',
    fontWeight: 300
  },
  normal: {
    fontFamily: 'Inter',
    fontWeight: 400
  },
  semibold: {
    fontFamily: 'Inter',
    fontWeight: 600
  },
  bold: {
    fontFamily: 'Inter',
    fontWeight: 700
  }
} as const
type TitleVariantType = keyof typeof TitleVariants

type TitleProps = React.PropsWithChildren<{
  preset?: TitlePresetType
  variant?: TitleVariantType
}> &
  React.ComponentProps<typeof Text>

function Title({ children, preset = 'h4', variant = 'normal', ...textProps }: TitleProps) {
  const TitlePropStyles = {
    ...TitlePresets[preset],
    ...TitleVariants[variant]
  }
  return (
    <Text {...TitlePropStyles} {...textProps}>
      {children}
    </Text>
  )
}

export { Title }
export type { TitlePresetType, TitleProps, TitleVariantType }
