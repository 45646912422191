import type { TypedUseQueryOptions } from '@lukemorales/query-key-factory'
import type { UseQueryOptions } from '@tanstack/react-query'
import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query'
import type { Network } from 'alchemy-sdk'
import { create } from 'zustand'

import { SDKQueryClient } from '../../queryClient'
import { defaultNetworks, networksWithoutTests } from '../alchemy'

import { alchemyInventoryKeys, blockVInventoryKeys, vatomInventoryKeys } from './keys'
import type { AlchemyNft } from './types'

const defaultPageSize = 100
// NOTE: Vatom API /user-inventory/search has a server limit of 100

export const useWalletPagination = create<{
  page: number
  pageSize: number
  setPage: (page: number) => void
  setPageSize: (pageSize: number) => void
}>(set => ({
  page: 1,
  pageSize: defaultPageSize,
  setPage: (page: number) => set({ page }),
  setPageSize: (pageSize: number) => set({ pageSize })
}))

export const getAlchemyCurrentQueryKey = ({
  address,
  network
}: {
  address: string
  network: Network
}) => {
  const { page, pageSize } = useWalletPagination.getState()

  return alchemyInventoryKeys.list({ page, address, network, limit: pageSize }).queryKey
}

export const getVatomCurrentQueryKey = () => {
  const { page, pageSize } = useWalletPagination.getState()

  return vatomInventoryKeys.list({ page, limit: pageSize }).queryKey
}

export const getBlockVCurrentQueryKey = () => {
  const { page, pageSize } = useWalletPagination.getState()

  return blockVInventoryKeys.list({ page, limit: pageSize }).queryKey
}

//////////////
// Alchemy
//////////////
type UseAlchemyOptions = Pick<TypedUseQueryOptions<typeof alchemyInventoryKeys.list>, 'enabled'>
export const useGetAlchemyNfts = (
  { addresses }: { addresses: string[] },
  options: UseAlchemyOptions = {}
) => {
  const { page, pageSize } = useWalletPagination()
  const isTestnetsEnabled = true
  const networks = isTestnetsEnabled ? defaultNetworks : networksWithoutTests
  // const networks = [Network.ETH_SEPOLIA]

  const queries = addresses.flatMap(address =>
    networks.map(network => ({
      ...alchemyInventoryKeys.list({ page, address, network, limit: pageSize }),
      keepPreviousData: true,
      retry: false,
      ...options
    }))
  )
  return useQueries({ queries })
}

type AlchemyByIdParams = {
  id: string
  address: string
  owner: string
  network: Network
}
export const useAlchemyById = (
  params: AlchemyByIdParams,
  options: Pick<TypedUseQueryOptions<typeof alchemyInventoryKeys.byId>, 'enabled' | 'select'>
) => {
  const queryClient = useQueryClient()
  return useQuery({
    ...alchemyInventoryKeys.byId(params),
    keepPreviousData: true,
    select: options.select,
    enabled: options.enabled ?? true,
    initialData: () => {
      const { page, pageSize } = useWalletPagination.getState()
      const data = queryClient
        ?.getQueryData<AlchemyNft[]>(
          alchemyInventoryKeys.list({
            page,
            address: params.address,
            network: params.network,
            limit: pageSize
          }).queryKey
        )
        ?.find(
          t =>
            t.tokenId === params.id && t.network === params.network && t.address === params.address
        )
      return data
    }
  })
}

//////////////
// Vatom
//////////////
export const useGetVatomNfts = () => {
  const { page, pageSize } = useWalletPagination()

  return useQuery({
    ...vatomInventoryKeys.list({ page, limit: pageSize }),
    keepPreviousData: true,
    cacheTime: 0,
    staleTime: 0
  })
}

type VatomByIdOptions = Pick<
  TypedUseQueryOptions<typeof vatomInventoryKeys.byId>,
  'enabled' | 'select'
>
type VatomInventoryById = Awaited<ReturnType<ReturnType<typeof vatomInventoryKeys.list>['queryFn']>>

export const useVatomById = (tokenId: string, options: VatomByIdOptions = {}) => {
  const queryClient = useQueryClient()
  return useQuery({
    ...vatomInventoryKeys.byId(tokenId),
    keepPreviousData: true,
    select: data => data,
    enabled: options.enabled ?? true,
    initialData: () => {
      return getQueryDataVatomById(tokenId, queryClient)
    }
    // initialData: () => {
    //   const { page, pageSize } = useWalletPagination.getState()
    //   const queryKey = vatomInventoryKeys.list({ page, limit: pageSize }).queryKey
    //   const data = queryClient.getQueryData<VatomInventoryById>(queryKey)
    //   if (!data) {
    //     return
    //   }
    //   return data?.items?.find(t => t.id === tokenId)
    // }
  })
}

// function getVatomInventoryKey() {
//   const { page, pageSize } = useWalletPagination.getState()
//   return vatomInventoryKeys.list({ page, limit: pageSize }).queryKey
// }

function getQueryDataVatomById(tokenId: string, queryClient = SDKQueryClient) {
  const { page, pageSize } = useWalletPagination.getState()
  const queryKey = vatomInventoryKeys.list({ page, limit: pageSize }).queryKey
  // const queryKey = getVatomInventoryKey()
  const data = queryClient.getQueryData<VatomInventoryById>(queryKey)
  if (!data) {
    return
  }
  return data?.items?.find(t => t.id === tokenId)
}

export async function ensureVatomById(tokenId: string, queryClient = SDKQueryClient) {
  return await queryClient.ensureQueryData(vatomInventoryKeys.byId(tokenId))
}

//////////////
// BlockV
//////////////
export const useGetBlockVNfts = () => {
  const { page, pageSize } = useWalletPagination()

  return useQuery({
    ...blockVInventoryKeys.list({ page, limit: pageSize }),
    keepPreviousData: true,
    select: data => data?.items
  })
}

type BlockVById = Awaited<ReturnType<ReturnType<typeof blockVInventoryKeys.byId>['queryFn']>>
export const getBlockVById = async (tokenId: string) => {
  const data = await SDKQueryClient.ensureQueryData<BlockVById>(
    blockVInventoryKeys.byId(tokenId).queryKey
  )
  return data
}

type BlockInventoryData = Awaited<
  ReturnType<ReturnType<typeof blockVInventoryKeys.list>['queryFn']>
>
export const useBlockVById = (tokenId: string, options: UseQueryOptions) => {
  const queryClient = useQueryClient()
  return useQuery({
    ...blockVInventoryKeys.byId(tokenId),
    keepPreviousData: true,
    select: data => data,
    enabled: options.enabled ?? true,
    initialData: () => {
      const { page, pageSize } = useWalletPagination.getState()
      const data = queryClient.getQueryData<BlockInventoryData>(
        blockVInventoryKeys.list({ page, limit: pageSize }).queryKey
      )
      if (!data) {
        return
      }
      return data?.items?.find(t => t.id === tokenId)
    }
  })
}
