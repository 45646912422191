// import type { inferQueryKeys } from '@lukemorales/query-key-factory'
// import { createQueryKeys } from '@lukemorales/query-key-factory'
import { BusinessService } from '@vatom/sdk/services'

// export const businessQueryKeys = {
//   business: [{ scope: 'business' }] as const,
//   getBusiness: (businessIdOrName: string, publicOnly?: boolean) =>
//     [{ ...businessQueryKeys.business[0], businessIdOrName, publicOnly }] as const,
//   businesses: [{ scope: 'businesses' }] as const,
//   getBusinesses: (name: string, context?: string, join?: boolean) =>
//     [{ ...businessQueryKeys.businesses[0], name, context, join }] as const,
//   businessesByIds: [{ scope: 'businessesByIds' }] as const,
//   getBusinessesByIds: (ids: string[]) =>
//     [{ ...businessQueryKeys.businessesByIds[0], ids }] as const,
//   businessesUser: [{ scope: 'businessesUser' }] as const,
//   businessesInventoryUser: [{ scope: 'businessesInventoryUser' }] as const,
//   businessCommunities: [{ scope: 'communities' }] as const,
//   getBusinessCommunities: (businessId: string) =>
//     [{ ...businessQueryKeys.businessCommunities[0], businessId }] as const
// }

// export const businessKeys = createQueryKeys('business', {
//   search: ({ name, context, join }: { name: string; context: string; join: boolean }) => ({
//     queryKey: [{ name, context, join }],
//     queryFn: async () => BusinessService.fetchBusinesses({ name, context, join })
//   }),
//   byIds: (ids: string[]) => ({
//     queryKey: [{ ids }],
//     queryFn: async () => BusinessService.fetchBusinessesByIds(ids)
//   })
//   // byUser: {
//   //   queryKey: [''],
//   //   queryFn: BusinessService.fetchBusinessesUser
//   // }
// })

// export type BusinessKeys = inferQueryKeys<typeof businessKeys>

export const businessKeys2 = (() => {
  const _def = ['business'] as const
  const search = (args: { name: string; context: string; join: boolean }) => ({
    queryKey: [..._def, 'search', { ...args }] as const,
    queryFn: async () => BusinessService.fetchBusinesses(args)
  })
  search._def = [..._def, 'search'] as const

  const byIds = (ids: string[]) => ({
    queryKey: [..._def, 'byIds', { ids }] as const,
    queryFn: async () => BusinessService.fetchBusinessesByIds(ids)
  })
  byIds._def = [..._def, 'byIds'] as const

  return {
    _def,
    search,
    byIds
  }
})()
