import type { inferQueryKeys } from '@lukemorales/query-key-factory'
import { createQueryKeys } from '@lukemorales/query-key-factory'

import {
  fetchAlchemyById,
  fetchAlchemyNfts,
  fetchBlockVById,
  fetchBlockVNfts,
  fetchVatomById,
  getVatomInventory
} from './fetch'
import type { AlchemyByIdParams, AlchemyListOptions } from './types'

export const alchemyInventoryKeys = createQueryKeys('alchemyInventory', {
  list: ({ page, address, network, limit }: AlchemyListOptions) => ({
    queryKey: [{ page, address, network, limit }],
    queryFn: async () => fetchAlchemyNfts({ page, address, network, limit })
  }),
  byId: ({ id, address, network }: AlchemyByIdParams) => ({
    queryKey: [{ id, address, network }],
    queryFn: async () => fetchAlchemyById({ id, address, network })
  })
})
export type AlchemyInventoryKeys = inferQueryKeys<typeof alchemyInventoryKeys>

export const vatomInventoryKeys = createQueryKeys('vatomInventory', {
  list: ({ page, limit }: { page: number; limit: number }) => ({
    queryKey: [{ page, limit }],
    queryFn: async () => getVatomInventory({ page, limit })
  }),
  byId: (tokenId: string) => ({
    queryKey: [{ tokenId }],
    queryFn: async () => fetchVatomById(tokenId)
  })
})
export type VatomInventoryKeys = inferQueryKeys<typeof vatomInventoryKeys>

export const blockVInventoryKeys = createQueryKeys('blockVInventory', {
  list: ({ page, limit }: { page: number; limit?: number }) => ({
    queryKey: [{ page, limit }],
    queryFn: async () => fetchBlockVNfts({ page, limit })
  }),
  byId: (tokenId: string) => ({
    queryKey: [{ tokenId }],
    queryFn: async () => fetchBlockVById(tokenId)
  })
})
export type BlockVInventoryKeys = inferQueryKeys<typeof blockVInventoryKeys>
