import { authRefreshAsync } from '@vatom/sdk/auth'
import axios, { type CreateAxiosDefaults } from 'axios'

import { createAuthInterceptor } from './axios-helper'

const COMMON_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}
const axiosConfig = {
  headers: COMMON_HEADERS
} satisfies CreateAxiosDefaults

export const vatomAxiosInstance = axios.create(axiosConfig)

async function refreshAuthToken({
  failedRequest,
  refreshToken
}: {
  failedRequest: any
  refreshToken: string
}) {
  const accessToken = await authRefreshAsync({ refreshToken })
  // Set auth headers
  failedRequest.config.headers['Authorization'] = `Bearer ${accessToken}`
  vatomAxiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`

  return Promise.resolve()
}

export function setAuthorizationAxiosInstance({
  accessToken,
  refreshToken
}: {
  accessToken: string
  refreshToken: string
}) {
  // set access token to headers
  vatomAxiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
  createAuthInterceptor({
    axiosInstance: vatomAxiosInstance,
    refreshAuth: async (error: any) =>
      await refreshAuthToken({ failedRequest: error, refreshToken })
  })
}
