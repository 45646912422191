import { AppRoutes, CommunitiesRoutes, SpacesRoutes, TabRoutes, WalletRoutes } from './routes'

export const PublicScreensConfig = {
  // [AppRoutes.businessConnect]: {
  //   path: '/b/:business'
  // },
  [AppRoutes.home]: {
    path: '',
    initialRouteName: TabRoutes.Wallet,
    screens: {
      [TabRoutes.Wallet]: {
        initialRouteName: '',
        screens: {
          [WalletRoutes.NFTs]: '',
          [WalletRoutes.Coins]: 'coins'
        }
      },
      [TabRoutes.Map]: 'map',
      [TabRoutes.Connect]: {
        screens: {
          [SpacesRoutes.Spaces]: 'spaces',
          [SpacesRoutes.People]: 'people'
        }
      },
      [TabRoutes.DMs]: 'dms/:spaceHandle?'
    }
  },
  [AppRoutes.CoinDetail_Business]: {
    path: 'b/:business/coins/:coinId'
  },
  [AppRoutes.profileUser]: {
    path: '/me'
  },
  [AppRoutes.editProfile]: {
    path: '/editProfile'
  },
  [AppRoutes.manageAccounts]: {
    path: '/manageAccounts'
  },
  [AppRoutes.updateProfile]: {
    path: '/update-profile'
  },
  [AppRoutes.settingsMetamask]: '/settings/metamask',
  [AppRoutes.Acquire]: '/acquire/:id',
  [AppRoutes.Acquire_Business]: '/b/:businessId/acquire/:id',
  // [AppRoutes.LogoutCallback]: {
  //   path: '/logout-callback'
  // },
  [AppRoutes.LoginCallback]: {
    path: '/callback'
  },
  [AppRoutes.Claim]: {
    path: '/claim/:claimId'
  },
  [AppRoutes.NFTDetail]: {
    path: '/token/:tokenId'
  },
  [AppRoutes.NFTDetail_Business]: {
    path: '/b/:business/token/:tokenId'
  },
  // NFT Option Actions
  [AppRoutes.SendNFT]: {
    path: '/token/:tokenId/send'
  },
  [AppRoutes.SendNFT_Business]: {
    path: '/b/:business/token/:tokenId/send'
  },
  [AppRoutes.SendNFTShare]: {
    path: '/token/:tokenId/share'
  },
  [AppRoutes.SendNFTShare_Business]: {
    path: '/b/:business/token/:tokenId/share'
  },
  [AppRoutes.DropNFT]: {
    path: '/token/:tokenId/drop'
  },
  [AppRoutes.DropNFT_Business]: {
    path: '/b/:business/token/:tokenId/drop'
  },
  [AppRoutes.SellNFT]: {
    path: '/token/:tokenId/sell'
  },
  [AppRoutes.SellNFT_Business]: {
    path: '/b/:business/token/:tokenId/sell'
  },
  [AppRoutes.MintNFT]: {
    path: '/token/:tokenId/mint'
  },
  [AppRoutes.MintNFT_Business]: {
    path: '/b/:business/token/:tokenId/mint'
  },

  [AppRoutes.AcquirePubVariation1]: {
    path: '/acquire-pub-variation/:tokenId'
  },
  [AppRoutes.AcquirePubVariation2]: {
    path: '/AcquirePubVariation/:tokenId'
  },
  [AppRoutes.AcquirePubVariation]: {
    path: '/acquirepubvariation/:tokenId'
  },
  [AppRoutes.MapAR]: {
    path: '/ar'
  },
  [AppRoutes.TokenAR]: {
    path: '/token-ar'
  },
  [AppRoutes.Redirect]: {
    path: '/redirect'
  }
} as const

const roomParse = {
  roomId: (id: string) => decodeURIComponent(id),
  roomTab: (tab: string) => tab,
  spaceId: (id: string) => decodeURIComponent(id)
}

const communitiesScreens = (withCommunities = true) => {
  const path = withCommunities
    ? 'b/:business/c/:community/space/:spaceId'
    : 'b/:business/space/:spaceId'
  const keyName = withCommunities ? 'Communities' : ''

  return {
    [AppRoutes[`${keyName}Room`]]: {
      path: `${path}`,
      parse: roomParse
    },
    [AppRoutes[`${keyName}RoomMembers`]]: {
      path: `${path}/members`,
      parse: roomParse
    },
    [AppRoutes[`${keyName}RoomThread`]]: {
      path: `${path}/thread/:messageId`,
      parse: roomParse
    },
    [AppRoutes[`${keyName}RoomNewMessage`]]: {
      path: `${path}/new-message`,
      parse: roomParse
    },
    [AppRoutes[`${keyName}RoomReply`]]: {
      path: `${path}/reply/:messageId`,
      parse: roomParse
    },
    [AppRoutes[`${keyName}RoomQuestionList`]]: {
      path: `${path}/question-list`,
      parse: roomParse
    },
    [AppRoutes[`${keyName}RoomNewQuestion`]]: {
      path: `${path}/new-question`,
      parse: roomParse
    },
    [AppRoutes[`${keyName}RoomSketchList`]]: {
      path: `${path}/sketch-list`,
      parse: roomParse
    },
    [AppRoutes[`${keyName}RoomNewSketch`]]: {
      path: `${path}/new-sketch`,
      parse: roomParse
    }
  }
}

export const PrivateScreensConfig = {
  [AppRoutes.home]: {
    path: '',
    initialRouteName: TabRoutes.Wallet,
    screens: {
      [TabRoutes.Wallet]: {
        initialRouteName: '',
        screens: {
          [WalletRoutes.NFTs]: '',
          [WalletRoutes.Coins]: 'coins'
        }
      },
      [TabRoutes.Map]: 'map',
      [TabRoutes.Connect]: {
        screens: {
          [SpacesRoutes.Spaces]: 'spaces',
          [SpacesRoutes.People]: 'people'
        }
      },
      [TabRoutes.DMs]: 'dms/:spaceHandle?'
    }
  },

  [AppRoutes.BusinessProxy]: {
    path: '/b/:business',
    initialRouteName: TabRoutes.Wallet,
    screens: {
      [TabRoutes.Home]: '/',
      [TabRoutes.Wallet]: {
        path: 'wallet'
      },
      [TabRoutes.Map]: 'map',
      [TabRoutes.MapAr]: 'ar',
      [TabRoutes.Connect]: {
        screens: {
          [CommunitiesRoutes.Communities]: 'communities',
          [CommunitiesRoutes.CommunitiesHome]: {
            path: 'c/:community',
            parse: {
              community: (id: string) => decodeURIComponent(id)
            }
          }
        }
      }
    }
  },
  [AppRoutes.FindToken]: {
    path: '/find-token'
  },
  [AppRoutes.FindToken_Business]: {
    path: '/b/:business/find-token'
  },

  ...communitiesScreens(true),
  ...communitiesScreens(false),

  [AppRoutes.profileOtherUser_Business]: {
    path: '/b/:business/user/:userId',
    parse: {
      userId: (id: string) => decodeURIComponent(id)
    }
  },
  [AppRoutes.CoinDetail_Business]: {
    path: '/b/:business/coins/:coinId'
  },
  [AppRoutes.profileUser]: {
    path: '/me'
  },
  [AppRoutes.editProfile]: {
    path: '/editProfile'
  },
  [AppRoutes.manageAccounts]: {
    path: '/manageAccounts'
  },
  [AppRoutes.updateProfile]: {
    path: '/update-profile'
  },
  [AppRoutes.settingsMetamask]: '/settings/metamask',
  [AppRoutes.Acquire]: '/acquire/:id',
  [AppRoutes.Acquire_Business]: '/b/:businessId/acquire/:id',
  [AppRoutes.LogoutCallback]: {
    path: '/logout-callback'
  },
  [AppRoutes.Claim]: {
    path: '/claim/:claimId'
  },
  [AppRoutes.NFTDetail]: {
    path: '/token/:tokenId'
  },
  [AppRoutes.NFTDetail_Business]: {
    path: '/b/:business/token/:tokenId'
  },
  [AppRoutes.SendNFT]: {
    path: '/token/:tokenId/send'
  },
  [AppRoutes.SendNFT_Business]: {
    path: '/b/:business/token/:tokenId/send'
  },
  [AppRoutes.SendNFTShare]: {
    path: '/token/:tokenId/share'
  },
  [AppRoutes.SendNFTShare_Business]: {
    path: '/b/:business/token/:tokenId/share'
  },
  [AppRoutes.DropNFT]: {
    path: '/token/:tokenId/drop'
  },
  [AppRoutes.DropNFT_Business]: {
    path: '/b/:business/token/:tokenId/drop'
  },
  [AppRoutes.SellNFT]: {
    path: '/token/:tokenId/sell'
  },
  [AppRoutes.SellNFT_Business]: {
    path: '/b/:business/token/:tokenId/sell'
  },
  [AppRoutes.MintNFT]: {
    path: '/token/:tokenId/mint'
  },
  [AppRoutes.MintNFT_Business]: {
    path: '/b/:business/token/:tokenId/mint'
  },

  [AppRoutes.AcquirePubVariation1]: {
    path: '/acquire-pub-variation/:tokenId'
  },
  [AppRoutes.AcquirePubVariation2]: {
    path: '/AcquirePubVariation/:tokenId'
  },
  [AppRoutes.AcquirePubVariation]: {
    path: '/acquirepubvariation/:tokenId'
  },
  [AppRoutes.MapAR]: {
    path: '/ar'
  },
  [AppRoutes.TokenAR]: {
    path: '/token-ar'
  },
  [AppRoutes.invitePeople]: {
    path: '/people/invite'
  },
  [AppRoutes.profileOtherUser]: {
    path: '/user/:userId',
    parse: {
      userId: (userId: string) => (userId.startsWith('@') ? userId.slice(1, userId.length) : userId)
    }
  },
  [AppRoutes.followList]: {
    path: '/user/:userId/list/:type',
    parse: {
      userId: (userId: string) => (userId.startsWith('@') ? userId.slice(1, userId.length) : userId)
    }
  },
  [AppRoutes.Metaverse]: {
    path: '/space/:spaceId/:type?'
  },
  [AppRoutes.DmScreen]: {
    path: '/dm/:roomId'
  },
  [AppRoutes.BackupToCloud]: {
    path: '/backup-to-cloud'
  },
  [AppRoutes.RestoreFromCloud]: {
    path: '/restore-from-cloud/restore'
  },
  [AppRoutes.DeleteFromCloud]: {
    path: '/delete-from-cloud'
  },
  [AppRoutes.CloudBackupList]: {
    path: '/restore-from-cloud'
  },
  [AppRoutes.NewWalletStart]: {
    path: '/new-wallet'
  },
  [AppRoutes.NewWalletBackup]: {
    path: '/new-wallet/backup'
  },
  [AppRoutes.NewWalletConfirm]: {
    path: '/new-wallet/confirm'
  },
  [AppRoutes.ImportWalletStart]: {
    path: '/import-wallet'
  },
  [AppRoutes.ImportWalletRecover]: {
    path: '/import-wallet/recover'
  },
  [AppRoutes.WalletAddressManagement]: {
    path: '/wallet-address-management/:address'
  },
  [AppRoutes.Settings]: {
    path: '/settings'
  },
  [AppRoutes.BlockChainWallets]: {
    path: '/blockchain-wallets'
  },
  [AppRoutes.BlockChainNetwork]: {
    path: '/blockchain-network'
  },
  [AppRoutes.ShowPrivateKey]: {
    path: '/private-key'
  },
  [AppRoutes.EditAddressName]: {
    path: '/address/:address/edit'
  },
  [AppRoutes.FungibleTokenDetail]: {
    path: '/fungible/detail'
  },
  [AppRoutes.FungibleTokenTransfer]: {
    path: '/fungible/transfer'
  },
  [AppRoutes.FungibleTokenRecipient]: {
    path: '/fungible/recipient'
  },
  [AppRoutes.FungibleTokenConfirmation]: {
    path: '/funngible/confirmation'
  },
  [AppRoutes.sendNFTConfirmation]: {
    path: '/send-nft-confirmation'
  },
  [AppRoutes.SignMessage]: {
    path: '/sign-message'
  },
  [AppRoutes.AddressAndNetworkSelector]: {
    path: '/address-network-selector'
  },
  [AppRoutes.WalletRequestPermission]: {
    path: '/wallet-request-permission'
  },
  [AppRoutes.WalletSwitchEthereumChain]: {
    path: '/wallet-switch-chain'
  },
  [AppRoutes.WalletSendTransaction]: {
    path: '/wallet-send-transaction'
  }
} as const

export const screenConfigNonWeb = {
  [AppRoutes.Metaverse]: {
    path: '/:spaceHandle'
  }
}
