import AsyncStorage from '@react-native-async-storage/async-storage'
import type { BusinessModel } from '@vatom/models'
import { getConfig } from '@vatom/sdk/react'
import axios from 'axios'

import { vatomAxiosInstance } from '../vatomAxiosInstance'

async function fetchBusinesses({
  name,
  context,
  join = false
}: {
  name: string
  context: string | null
  join: boolean
}) {
  try {
    const config = getConfig()
    const redirectContext = await AsyncStorage.getItem('REDIRECT_CONTEXT')

    let extra = ''
    if (context || redirectContext) {
      extra = `&context=${context || redirectContext}`
    } else if (join) {
      extra = `&join=${join}`
      AsyncStorage.removeItem('REDIRECT_CONTEXT')
    }

    const { data } = await axios.get<BusinessModel[]>(`${config.api.studio}/b?name=${name}${extra}`)
    return data
  } catch (error) {
    console.error('BusinessService.fetchBusinesses:', error)
    throw error
  }
}

async function fetchBusiness({
  businessIdOrName,
  publicOnly = true
}: {
  businessIdOrName: string
  publicOnly?: boolean
}) {
  try {
    const config = getConfig()
    const { data } = await axios.get<BusinessModel>(
      `${config.api.studio}/b/${businessIdOrName}?publicOnly=${publicOnly}`
    )
    return data
  } catch (error) {
    console.error('BusinessService.fetchBusiness:', error)
    throw error
  }
}

async function fetchBusinessesByIds(ids: string[]) {
  try {
    const config = getConfig()
    const response = await axios.post(`${config.api.studio}/b/search`, { ids })
    return response?.data
  } catch (error) {
    console.error('BusinessService.fetchBusinessesByIds:', error)
    throw error
  }
}

async function fetchBusinessesUser() {
  try {
    const config = getConfig()
    const { data } = await vatomAxiosInstance.get(`${config.api.vatoms}/me/businesses`)
    return data
  } catch (error) {
    console.error('BusinessService.fetchBusinessesUser:', error)
    throw error
  }
}

async function decideBusinessForms(values: any, campaignId?: string, businessId?: string) {
  try {
    const config = getConfig()
    const body = JSON.stringify({
      businessId: businessId,
      campaignId: campaignId,
      data: values,
      flow: 'popup'
    })
    const { data } = await vatomAxiosInstance.post(`${config.api.oidc}/api/me/info/form`, body)
    return data
  } catch (error) {
    console.error('BusinessService.decideBusinessForms:', error)
    throw error
  }
}

async function decideBusinessTerms(termsName: string, accepted: boolean) {
  try {
    //
    const config = getConfig()
    const { data } = await vatomAxiosInstance.patch(
      `${config.api.oidc}/api/me/terms/${termsName}`,
      {
        accepted
      }
    )
    return data
  } catch (error) {
    console.error('BusinessService.decideBusinessTerms:', error)
    throw error
  }
}

export const BusinessService = {
  fetchBusinesses,
  fetchBusiness,
  fetchBusinessesByIds,
  fetchBusinessesUser,
  decideBusinessForms,
  decideBusinessTerms
}
