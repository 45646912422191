import type { inferQueryKeys } from '@lukemorales/query-key-factory'
import { createQueryKeys } from '@lukemorales/query-key-factory'
import { UserService } from '@vatom/sdk/services'

import type { RelationshipContact } from './types'

export const relationshipsKeys = createQueryKeys('user-relationship', {
  contacts: (contacts: RelationshipContact[]) => ({
    queryKey: [{ contacts }],
    queryFn: async () => UserService.fetchContacts(contacts)
  }),
  following: {
    queryKey: null,
    queryFn: async () => UserService.fetchFollowing()
  },
  userFollowing: (userId: string) => ({
    queryKey: [{ userId }],
    queryFn: async () => UserService.fetchUserFollowing(userId)
  }),
  userFollowers: (userId: string) => ({
    queryKey: [{ userId }],
    queryFn: async () => UserService.fetchUserFollowers(userId)
  })
})
export type RelationshipsKeys = inferQueryKeys<typeof relationshipsKeys>
