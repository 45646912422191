import type { SafeParseSuccess, z, ZodError, ZodTypeAny } from 'zod'

export function ArraySafeParseFromSchema<T extends ZodTypeAny>(Schema: T, array: unknown[]) {
  const errors: ZodError[] = []
  const data: SafeParseSuccess<z.infer<typeof Schema>>['data'][] = []

  if (array.length === 0) {
    console.warn('ArraySafeParseFromSchema: given array is empty')
    return { errors, data }
  }
  for (let index = 0; index < array.length; index++) {
    const item = Schema.safeParse(array[index])
    if (item.success) {
      data.push(item.data)
    } else {
      errors.push(item.error)
    }
  }
  return { errors, data }
}

export async function ArraySafeParseAsyncFromSchema<T extends ZodTypeAny>(
  Schema: T,
  array: unknown[]
) {
  const errors: ZodError[] = []
  const data: SafeParseSuccess<z.infer<typeof Schema>>['data'][] = []

  if (array.length === 0) {
    console.warn('ArraySafeParseAsyncFromSchema: given array is empty')
    return { errors, data }
  }
  for (let index = 0; index < array.length; index++) {
    const item = await Schema.safeParseAsync(array[index])
    if (item.success) {
      data.push(item.data)
    } else {
      errors.push(item.error)
    }
  }
  return { errors, data }
}
