import { useCallback } from 'react'
import type { VatomToken } from '@vatom/models'
import type { BlockVToken } from '@vatom/models/blockV'

import { isAlchemyNft, isBlockVNft, isVatomNft } from '../store'

import { useConfig } from './useConfig'

type TokenType = VatomToken | BlockVToken

export const useFilterBlackList = () => {
  const config = useConfig()

  return useCallback(
    (tokens: TokenType[]) => {
      const tkns = tokens
        .filter(t => {
          if (isAlchemyNft(t)) {
            return false
          }
          if (isBlockVNft(t)) {
            return !config?.features?.inventory?.tv_blacklist.includes(
              t.properties.template_variation
            )
          }
          return true
        })
        .filter(t => {
          if (isVatomNft(t) || isBlockVNft(t)) {
            return !config?.features?.inventory?.object_definition_blacklist.includes(
              t.studioInfo?.objectDefinitionId || ''
            )
          }
          return false
        })
      return tkns
    },
    [
      config?.features?.inventory?.tv_blacklist,
      config?.features?.inventory?.object_definition_blacklist
    ]
  )
}

// TODO: remove
/**
 * @deprecated
 */
export const useInventory = () => {
  return
}
