import { AppConfigurationSchema, FeaturesSchema, screensConfigSchema } from '@vatom/models'
import type { VatomWalletSdkStore } from '@vatom/wallet-sdk'
import { useVatomWalletSdkStore } from '@vatom/wallet-sdk'

import type { AppConfig, AppConfigQueryOptions } from '../store'
import { getConfig, useAppConfig } from '../store'

export const useConfig = <T = AppConfig>(opts?: AppConfigQueryOptions<T>): T => {
  const config = useAppConfig(opts)

  if (!config.data && !opts?.select) {
    console.warn('Config not loaded')
  }

  const isEmbedded = useVatomWalletSdkStore(state => state.isEmbedded)

  const walletSDKConfig = useVatomWalletSdkStore(state => {
    const stateUpdated = migrateConfig(state)

    if (opts?.select) {
      return opts.select(stateUpdated.walletConfig)
    }
    // We need to cast it here because T is not directly tied to isEmbedded
    return stateUpdated.walletConfig as T
  })

  if (isEmbedded && walletSDKConfig) {
    return walletSDKConfig
  }

  const getDefaultResponse = (): T => {
    const defaultConfig = AppConfigurationSchema.parse({})
    if (opts?.select) {
      return opts.select(defaultConfig)
    }
    return defaultConfig as T
  }

  return config.data ?? getDefaultResponse()
}

export const useConfigState = () => {
  const config = useAppConfig()
  if (!config.data) {
    console.warn('Config not loaded')
  }

  const walletSdkStore = useVatomWalletSdkStore()
  if (walletSdkStore.isEmbedded && walletSdkStore.walletConfig) {
    return {
      isLoading: false,
      isError: false,
      data: walletSdkStore.walletConfig
    }
  }

  return {
    isLoading: config.isLoading,
    isError: config.isError,
    // hack for useAuth hook
    data: config.data ?? AppConfigurationSchema.parse({})
  }
}

export const getConfigWithSdkOverride = <T = AppConfig>(opts?: AppConfigQueryOptions<T>): T => {
  const config = getConfig()

  if (!config && !opts?.select) {
    console.warn('Config not loaded')
  }

  const isEmbedded = useVatomWalletSdkStore.getState().isEmbedded

  const walletSDKConfig = useVatomWalletSdkStore.getState()

  const target = isEmbedded ? migrateConfig(walletSDKConfig).walletConfig : config

  const result = opts?.select ? opts.select(target) : (target as T)

  if (isEmbedded && walletSDKConfig) {
    return result
  }

  const getDefaultResponse = (): T => {
    const defaultConfig = AppConfigurationSchema.parse({})
    if (opts?.select) {
      return opts.select(defaultConfig)
    }
    return defaultConfig as T
  }

  return (result as T) ?? getDefaultResponse()
}

export const getConfigState = () => {
  const config = getConfigWithSdkOverride()
  if (!config) {
    console.warn('Config not loaded')
  }

  const walletSdkStore = useVatomWalletSdkStore.getState()

  if (walletSdkStore.isEmbedded && walletSdkStore.walletConfig) {
    return walletSdkStore.walletConfig
  }

  return config
}

const migrateConfig = (config: VatomWalletSdkStore) => {
  const { config: oldConfig, walletConfig } = config
  const {
    hideDrawer,
    hideNavigation,
    hideTokenActions,
    disableArPickup,
    disableNewTokenToast,
    language,
    systemThemeOverride,
    visibleTabs,
    mapStyle,
    emptyStateImage,
    emptyStateTitle,
    emptyStateMessage,
    screensConfig,
    scanner
  } = oldConfig

  const { customActionBtn, customActions, inventoryFilter, hideCloseButtonOnNft } =
    walletConfig?.features ?? {}

  if (!config?.walletConfig) {
    config.walletConfig = AppConfigurationSchema.parse({})
  }

  if (!config?.walletConfig?.features) {
    config.walletConfig.features = FeaturesSchema.parse({})
  }

  const { features } = config.walletConfig

  if (!features.screensConfig) {
    features.screensConfig = screensConfigSchema.parse({})
  }

  const { screensConfig: screenFeatures } = features

  features.hideDrawer = hideDrawer || features.hideDrawer
  features.hideNavigation = hideNavigation || features.hideNavigation
  features.disableNewTokenToast = disableNewTokenToast || features.disableNewTokenToast
  features.language = (language as 'en' | 'es') || features.language
  features.systemThemeOverride = systemThemeOverride || features.systemThemeOverride
  features.visibleTabs = visibleTabs || features.visibleTabs

  if (hideTokenActions) {
    screenFeatures.NFTDetail.hideTokenActions = hideTokenActions
  }

  if (disableArPickup) {
    screenFeatures.Map.disableArPickup = disableArPickup
  }

  if (mapStyle) {
    screenFeatures.Map.mapStyle = mapStyle
  }

  if (emptyStateImage) {
    screenFeatures.Wallet.emptyStateImage = emptyStateImage
  }

  if (emptyStateTitle) {
    screenFeatures.Wallet.emptyStateTitle = emptyStateTitle
  }

  if (emptyStateMessage) {
    screenFeatures.Wallet.emptyStateMessage = emptyStateMessage
  }

  if (screensConfig) {
    const { CommunitiesRoom, Room } = screensConfig

    if (CommunitiesRoom) {
      screenFeatures.CommunitiesRoom.hideBackButton = CommunitiesRoom.hideBackButton as boolean
    }

    if (Room) {
      screenFeatures.Room.hideBackButton = Room.hideBackButton as boolean
    }
  }

  if (scanner) {
    screenFeatures.Wallet.scanner = scanner.enabled
  }

  if (customActionBtn) {
    screenFeatures.NFTDetail.customActionBtn = customActionBtn
  }

  if (customActions) {
    screenFeatures.NFTDetail.actionsheet = {
      customActions: customActions,
      generalInfo: []
    }
  }

  if (inventoryFilter) {
    screenFeatures.Wallet.inventoryFilter = inventoryFilter
  }

  if (hideCloseButtonOnNft) {
    screenFeatures.NFTDetail.hideCloseButton = hideCloseButtonOnNft
  }

  config.walletConfig.googleMapsKey = config.walletConfig.googleMapsKey
    ? config.walletConfig.googleMapsKey
    : 'AIzaSyCZsQ5Q3-ZYbcilFxIvjEE9x1S-9o-Fyak'
  return config
}
