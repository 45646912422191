import type { AssetTransfersWithMetadataParams, GetNftMetadataOptions, Network } from 'alchemy-sdk'

export const alchemyQueryKeys = {
  balances: [{ scope: 'balances', provider: 'alchemy' }] as const,
  balance: [{ scope: 'balance', provider: 'alchemy' }] as const,
  tokenMetadata: [{ scope: 'contract-metadata', provider: 'alchemy' }] as const,
  gasEstimates: [{ scope: 'gas-estimates', provider: 'alchemy' }] as const,
  transactionGasEstimates: [{ scope: 'transaction-gas-estimates', provider: 'alchemy' }] as const,
  transactionData: [{ scope: 'transaction-data', provider: 'alchemy' }] as const,
  NFTMetadata: [{ scope: 'nft-metadata', provider: 'alchemy' }],
  assetTransfers: [{ scope: 'core-asset-transfers', provider: 'alchemy' }],
  nfts: [{ scope: 'nfts', provider: 'alchemy' }] as const,
  allNfts: [{ scope: 'all-nfts', provider: 'alchemy' }] as const,

  getAllTokenBalances: (addresses: string[], networks: Network[]) =>
    [
      {
        ...alchemyQueryKeys.balances[0],
        addresses,
        networks
      }
    ] as const,

  getTokenBalances: (address: string, network: Network) =>
    [{ ...alchemyQueryKeys.balances[0], address, network }] as const,
  getBalance: (address: string, network: Network) =>
    [{ ...alchemyQueryKeys.balance[0], address, network }] as const,
  getTokenMetadata: ({ contractAddress, network }: { contractAddress: string; network: Network }) =>
    [{ ...alchemyQueryKeys.tokenMetadata[0], contractAddress, network }] as const,
  getGasEstimates: ({
    toAddress,
    amount,
    network
  }: {
    toAddress: string
    amount: number
    network: Network
  }) => [{ ...alchemyQueryKeys.tokenMetadata[0], toAddress, amount, network }] as const,
  getTransactionGasEstimates: ({
    data,
    contractAddress,
    fromAddress,
    network
  }: {
    data: string
    contractAddress: string
    fromAddress: string
    network: Network
  }) =>
    [
      { ...alchemyQueryKeys.tokenMetadata[0], data, contractAddress, network, fromAddress }
    ] as const,
  getTransactionData: ({
    network,
    contractType,
    contractAddress,
    fromAddress,
    toAddress,
    tokenId,
    amount
  }: {
    network: Network
    contractType: string
    contractAddress: string
    fromAddress: string
    toAddress: string
    tokenId: string
    amount: number
  }) =>
    [
      {
        ...alchemyQueryKeys.tokenMetadata[0],
        network,
        contractType,
        contractAddress,
        fromAddress,
        toAddress,
        tokenId,
        amount
      }
    ] as const,
  getNFTMetadata: ({
    contractAddress,
    tokenId,
    network,
    options
  }: {
    contractAddress: string
    tokenId: string
    network: Network
    options?: GetNftMetadataOptions
  }) =>
    [{ ...alchemyQueryKeys.NFTMetadata[0], contractAddress, tokenId, network, options }] as const,
  getAssetTransfers: ({
    network,
    options
  }: {
    network: Network
    options: AssetTransfersWithMetadataParams
  }) =>
    [
      {
        ...alchemyQueryKeys.assetTransfers[0],
        network,
        options
      }
    ] as const,

  getNfts: ({ network, ownerAddress }: { network: Network; ownerAddress: string }) =>
    [
      {
        ...alchemyQueryKeys.nfts[0],
        ownerAddress,
        network
      }
    ] as const,

  getAllNfts: ({ networks, ownerAddresses }: { networks: Network[]; ownerAddresses: string[] }) =>
    [
      {
        ...alchemyQueryKeys.allNfts[0],
        networks,
        ownerAddresses
      }
    ] as const
} as const
